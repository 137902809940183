module.exports = {
  enjoy_your_free_trial: 'A sua primeira transcrição é por nossa conta!',
  dialog_title: 'Adicionar Créditos',
  subscription_title: 'Obter Subscrição',
  pay: 'Pagar',
  error_unauthenticate: 'Não foi possível autorizar o seu método de pagamento, tente com um cartão diferente.',
  error_payment_creation: 'Não foi possível concluir o seu pagamento, por favor, contacte o suporte.',
  error_general: 'Não foi possível estabelecer a ligação com o servidor, atualize e tente novamente.',
  error_timeout: 'Tempo limite da ligação esgotado, atualize e tente novamente.',

  expired_card:'O cartão expirou, por favor, use um cartão de crédito diferente.',
  do_not_honor:'O cartão foi recusado, por favor, contacte o emissor para obter mais informações.',
  insufficient_funds:'O cartão não tem fundos suficientes para concluir esta transação.',
  invalid_info: 'As informações fornecidas do cartão são inválidas, verifique e tente novamente.',

  success: 'Sucesso',
  success_description: 'A sua conta foi creditada com <br/> <b class="number-of-minutes">{0} minutos</b>',
  success_description_bonus: 'Minutos de bónus: <b class ="extra-minutes"> {1} minutos</b> <br/>A sua conta foi creditada com <br/> <b class="number-of-minutes">{0} minutos</b>',
  success_subscription_description: 'A sua subscrição premium foi ativada',
  success_corp_subscription_description: 'A sua subscrição Bulk foi ativada',
  cancel_subscription_confirmation: 'Tem a certeza de que pretende cancelar a subscrição?',
  success_subscription_bonus: 'Minutos de bónus: <b class ="extra-minutes"> {0} minutos</b>',

  cancel_subscription_desc:' minutos irão expirar imediatamente após o cancelamento.<br>Apenas as primeiras <span style="color:rgb(246, 102, 101)!important">1</span> etiquetas podem ser usadas após o cancelamento.',
  cancel_subscription_confirm:'Confirmo por este meio que vou desistir dos minutos mencionados acima.',
  reactivate_subscription_confirmation: 'Tem a certeza de que pretende reativar a subscrição?',
  cacnel_subscription_description: 'Terá acesso Premium até ao final do período.',
  cacnel_subscription: 'A sua Subscrição Premium foi cancelada.',
  cancel_corp_subscription: 'A sua Subscrição Bulk foi cancelada.',
  reactive_subscription: 'A sua subscrição foi reativada!',
  active_until:'Ativo até',
  success_translation_description: 'A sua tradução está pronta!',
  yes: 'Sim',
  no: 'Não',
  continue: 'Continuar',
  save: 'Guardar',
  cancel: 'Cancelar',
  processing_payment: 'Processando pagamento',
  error_edit: 'Não foi possível concluir a atualização do cartão, por favor, contacte o suporte.',
  delete_ok: 'Remover',
  delete_cancel: 'Cancelar',
  billing_address: 'Endereço de Faturação',
  how_many_hours: 'Quantas horas?',
  credit_card: 'Cartão de crédito',
  summary: 'Resumo (USD)',
  save_payment: 'Guardar o meu cartão para futuras compras',
  processing_payment_description: 'Isto pode demorar alguns minutos...',
  credit_card_manage:'O seu cartão de crédito é processado com segurança pela Stripe, Inc.',
  credit_card_secured:'As transações são seguras e encriptadas.',
  understand_billing:'Autorizo este pagamento',
  processing_saving:'A atualizar cartão de crédito',
  
  full_name: 'Nome completo',
  name: 'Nome',
  address: 'Endereço',
  city: 'Cidade',
  state: 'Estado',
  zip: 'Código postal',
  
  max_length_field: '{0} deve ter no máximo {1} caracteres',
  required_field: '{0} é obrigatório',
  delete_title: 'Remover este cartão?',

  title_basic: 'Padrão',
  title_pay_as_you_go: 'Pagar conforme usar',
  title_premium: 'Premium',
  title_custom: 'Personalizado',
  title_corp: 'Bulk',
  title_subscription:'Subscrição',

  basic_feature5: 'Crédito <span style="color: #00d1b2;">60 minutos</span>',

  premium_feature1: '{0} <span style="color: #00d1b2;">minutos</span> todos os meses.<br><span class="price-notes">(Minutos acumuláveis, válidos até ao cancelamento da subscrição)</span>',
  premium_feature2: '25% de desconto em compras por hora',
  premium_feature3_tooltip: '2$ por documento',

  buy: 'Comprar minutos',
  activate: 'Ativar',
  subscribe: 'Subscrever',
  contact_us: 'Contacte-nos',

  custom_feature1: 'Parceria de negócios',
  custom_feature2: 'Subscrições de grupo',
  custom_feature3: 'Faturação centralizada',
  custom_feature4: 'Relatório de feedbacks ou erros',

  month: 'mês',
  per_hour: 'por hora',
  per_month: 'por mês',
  per_user_per_month:'{0} por utilizador/mês',

  how_get_free:'Como posso obter minutos grátis?',
  payment_questions:'Tem dúvidas de faturação?',

  translate_remaining:'Tem {0} restantes.',
  translate_deduct:'Vamos deduzir {0} se traduzir esta transcrição.',
  transcribe_deduct:'Vamos deduzir {0} se transcrever esta transcrição.',
  transcribe_warning:'Certifique-se de ter minutos suficientes para transcrever, caso contrário, o processo irá falhar.',
  mp4_deduct:'Vamos deduzir {0} se exportar esta transcrição como MP4.',
  cvc:'O que é o CVC?',
  confirm_translate:'Quero prosseguir com a tradução',
  recurring_description:'• Pagamento mensal recorrente',
  recurring_description2:'• Cancele a qualquer momento na sua Conta',
  coupon_header:'Código Promocional',
  coupon_title:'Insira o seu código promocional:',
  coupon_submit:'Aplicar',
  processing_coupon: 'Aplicando código promocional',
  coupon_empty: 'Por favor, insira o seu código promocional',
  coupon_expire: 'O cupão expirou',
  coupon_notexist: 'O cupão não é válido',
  coupon_firsttimeonly: 'O cupão só é válido para novos utilizadores',
  member_slot_desc:'Total de lugares de membros para esta subscrição para convidar membros com diferentes permissões para adicionar, carregar, editar e/ou exportar transcrições desta conta.',
  credit_edit_title:'Informações do cartão de crédito',

  payment_confirm_title:'Confirmação de pagamento',
  payment_confirm_desc:'Este pagamento é cobrado em <span style="color:#2fc59f;font-weight:bold;">dólares americanos</span> com o valor de {0}.',
  upgrade_contact_us:'Para soluções empresariais, programas de afiliados ou dúvidas de negócios, por favor, contacte-nos em <span style="color:#2fc59f">help@taption.com</span>'
}
