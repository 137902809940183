module.exports = {
  'en': 'Englisch',
  'zh-TW': 'Chinesisch (Traditionell)',
  'zh': 'Chinesisch (Vereinfacht)',
  'yue-Hant-HK': 'Chinesisch (Kantonesisch)',
  'yue-Hant-HK-TW': 'Chinesisch (Kantonesisch)',
  'ja': 'Japanisch',
  'ko': 'Koreanisch',
  'es': 'Spanisch',
  'de': 'Deutsch',
  'fr': 'Französisch',
  'af': 'Afrikaans',
  'sq': 'Albanisch',
  'am': 'Amharisch',
  'ar': 'Arabisch',
  'hy': 'Armenisch',
  'az': 'Aserbaidschanisch',
  'eu': 'Baskisch',
  'be': 'Weißrussisch',
  'bn': 'Bengalisch',
  'bs': 'Bosnisch',
  'bg': 'Bulgarisch',
  'ca': 'Katalanisch',
  'co': 'Korsisch',
  'hr': 'Kroatisch',
  'cs': 'Tschechisch',
  'da': 'Dänisch',
  'nl': 'Niederländisch',
  'fi': 'Finnisch',
  'el': 'Griechisch',
  'gu': 'Gujarati',
  'he': 'Hebräisch',
  'hi': 'Hindi',
  'hu': 'Ungarisch',
  'id': 'Indonesisch',
  'ga': 'Irisch',
  'it': 'Italienisch',
  'la': 'Latein',
  'lv': 'Lettisch',
  'lt': 'Litauisch',
  'ms': 'Malaiisch',
  'no': 'Norwegisch',
  'pl': 'Polnisch',
  'pt': 'Portugiesisch (Portugal, Brasilien)',
  'ro': 'Rumänisch',
  'ru': 'Russisch',
  'sr': 'Serbisch',
  'sk': 'Slowakisch',
  'sl': 'Slowenisch',
  'sv': 'Schwedisch',
  'te': 'Telugu',
  'th': 'Thailändisch',
  'tr': 'Türkisch',
  'uk': 'Ukrainisch',
  'uz': 'Usbekisch',
  'vi': 'Vietnamesisch',
  'yi': 'Jiddisch',
  'zu': 'Zulu'
}
