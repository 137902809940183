module.exports = {
    convert_to_text_title:"Convertir un audio en vidéo",
    convert_to_text_desc:"Transformez votre fichier audio et une image en une vidéo MP4 avec sous-titres",
    start_your_trial:"Commencez votre essai gratuit !",
    hero_img:"./05_English.webp",

    step_one:"Convertir un audio en texte",
    step_one_img:"./customized_step1_en.webp",
    step_one_desc:"Téléchargez votre audio et choisissez de segmenter chaque section en \"Sous-titres\"",
    
    step_two:"Télécharger une image personnalisée",
    step_two_img:"./customized_step2_en.webp",
    step_two_desc:"Dans la plateforme d'édition, téléchargez votre image sous Exporter → Fichier Vidéo MP4",

    step_three:"Exporter la vidéo",
    step_three_img:"./customized_step3_en.webp",
    step_three_desc:"Exportez votre vidéo personnalisée !",

    feature_tutorial_title:'Comment transformer votre audio en vidéo',
    feature_tutorial_desc:'Vous avez un fichier audio et vous souhaitez le convertir en vidéo. Cela peut sembler simple, mais c’est bien plus que ça ! C’est une démarche intelligente qui peut élargir considérablement l’audience de votre contenu. Notre plateforme d’édition facile à utiliser rend le processus simple et pratique.<br><br>Devinez quoi ? Nous avons un tutoriel détaillé prêt à vous guider, n’hésitez donc pas à le consulter pour mieux comprendre comment démarrer.',
    feature_tutorial_poster_url:'./audio_to_video_tutorial_poster_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/efDzKWNMgxw?autoplay=1',

    feature_extra_title:"Pourquoi convertir votre fichier audio en vidéo MP4 avec du texte à l'écran ?",
    feature_extra_desc:"Avez-vous déjà pensé à faire passer vos fichiers audio au niveau supérieur en les convertissant en vidéos MP4 avec sous-titres ? Voici pourquoi c’est une idée judicieuse : <ul><li><strong>Réutilisation du contenu :</strong> L’un des avantages majeurs est la possibilité de réutiliser votre contenu sur différentes plateformes de médias sociaux, ce qui étend votre portée et maximise l’utilisation de ce que vous avez déjà créé.</li><li><strong>Interaction unique :</strong> L’ajout de sous-titres est une manière novatrice et inclusive de connecter avec l’audience de votre contenu. </li><li><strong>Audience mondiale :</strong> En convertissant un audio en vidéo, vous ne changez pas simplement de format, vous rendez votre contenu accessible à un public mondial. Avec les transcriptions et les sous-titres, votre message n’a pas de frontières.</li></ul>En profitant de ces avantages, vous améliorez globalement votre contenu. Ne pensez-vous pas que ce sont de bonnes raisons pour convertir votre audio en vidéo ?"
}
