module.exports = {
    convert_to_text_title:"Colaborar com equipas",
    convert_to_text_desc:"Conta de equipa baseada na nuvem com transcrições e rótulos compartilhados",
    start_your_trial:"Comece o seu teste grátis!",
    hero_img:"./06_English.webp",

    step_one:"Acesso multi-utilizador",
    step_one_img:"./collaborate_step1_en.webp",
    step_one_desc:"Adicione utilizadores à sua conta e gerencie-os",
    
    step_two:"Compartilhar e editar transcrições",
    step_two_img:"./collaborate_step2_en.webp",
    step_two_desc:"Adicione utilizadores à sua conta para compartilhar transcrições e minutos",

    step_three:"Gestão de permissões de utilizador",
    step_three_img:"./collaborate_step3_en.webp",
    step_three_desc:"Gerencie as permissões de visualização e edição das contas",

    feature_tutorial_title:'Tutorial de gestão de utilizadores: Aprimore a colaboração da equipa com acesso multi-utilizador',
    feature_tutorial_custom_desc:'Além das 6 permissões pré-definidas, pode personalizar o acesso a cada funcionalidade para cada utilizador na conta.',
    feature_tutorial_custom_url:'./custom_dialog_en.webp',
    feature_tutorial_desc:'Quer simplificar o seu fluxo de trabalho enquanto torna o seu faturamento mais direto? Então o acesso multi-utilizador do Taption pode ser a solução que procura. Com nossa plataforma baseada na nuvem, pode esperar operações mais eficientes em termos de custos, além de uma excelente gestão de utilizadores.<br><br>Parece promissor? Não se esqueça de assistir ao nosso tutorial detalhado para conhecer todas as informações.',
    feature_tutorial_poster_url:'./permission_tutorial_poster_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/z1v2YdADBW8?autoplay=1',

    feature_extra_title:"Por que gerenciar permissões de utilizadores faz sentido estrategicamente",
    feature_extra_desc:"Gerenciar quem pode fazer o quê no Taption não é apenas uma tarefa administrativa chata. É uma escolha inteligente que pode reformular a interação da sua equipa com a nossa plataforma. Se está interessado, aqui estão algumas razões sólidas para agir: <ul><li><strong>Integração Suave:</strong> Adicionar novos membros à equipa é realmente fácil e ajuda todos a se ajustarem sem problemas. </li><li><strong>Proteja os seus Dados:</strong> O acesso baseado em funções garante que dados sensíveis permaneçam protegidos, para que transcrições importantes fiquem nas mãos certas. </li><li><strong>Foco na Especialização:</strong> Restringir certas funcionalidades da plataforma a membros específicos da equipa ajuda a desenvolver especialização e eleva o padrão dos seus projetos. </li><li><strong>Mantenha Tudo Sob Controle:</strong> Com total controlo de gestão, pode supervisionar o compartilhamento de transcrições e a ativação de funcionalidades, garantindo um espaço operacional seguro e harmonioso.</li></ul>Ao adotar estas táticas de gestão de utilizadores, você traz o melhor da sua equipa e do Taption. Que tal dar esse passo hoje mesmo?"
}
