module.exports = {
    convert_to_text_title_meta: "Konversi Teks ke Subtitle SRT Otomatis dengan Stempel Waktu",
    convert_to_text_desc_meta: "Unggah transkrip Anda yang ada dan gunakan generator subtitle online kami untuk menambahkan stempel waktu secara otomatis. Ubah TXT ke SRT dengan cepat. Coba gratis, daftar sekarang untuk memulai!",
    convert_to_text_title: "Konversi otomatis TXT ke subtitle SRT",
    convert_to_text_desc: "Unggah transkrip Anda yang ada, dan gunakan generator subtitle kami untuk secara otomatis menambahkan stempel waktu dan memformatnya. Ubah TXT ke SRT dengan cepat.",
    start_your_trial: "Coba Sekarang!",
    hero_img:"./09_English.webp",

    step_one: "Pilih file audio/video untuk transkrip Anda",
    step_one_img: "./select_source_en.webp",
    step_one_desc: "Pertama, pilih file audio/video yang sesuai dengan transkrip Anda. Bahasa transkrip dan video harus sama.",

    step_two: "Impor transkrip Anda",
    step_two_img: "./transcripts_subtitles_step02_en.webp",
    step_two_desc: "Di jendela <strong>Metode Transkripsi</strong>, pilih <span style=\"color:#3ccebd\">Impor File Teks</span>. Untuk <strong>Pilih format output Anda</strong>, pilih <span style=\"color:#3ccebd\">Teks/Subtitel</span>.",

    step_three: "Edit dan ekspor",
    step_three_img: "./export_srt_step03_en.webp",
    step_three_desc: "Masuk ke platform pengeditan untuk mengedit subtitle dan ekspor.",

    feature_tutorial_title: 'Ubah teks Anda menjadi subtitle',
    feature_tutorial_desc: 'Apakah Anda memiliki transkrip, artikel, atau konten teks lainnya? Cukup unggah ke platform Taption kami untuk dengan cepat menggunakan generator subtitle kami. Ini secara otomatis mengonversi teks Anda menjadi subtitle yang kompatibel dengan video, menyelesaikan konversi txt ke srt. Dengan generator subtitle kami, Anda dapat menghindari input subtitle manual dan penyesuaian garis waktu yang memakan waktu. Alat kami menawarkan akurasi dan konsistensi yang tinggi. Selain itu, Anda dapat dengan mudah menerjemahkan subtitle ke lebih dari 40 bahasa, memenuhi kebutuhan audiens global yang beragam.',
    feature_tutorial_poster_url: './transcripts_to_subtitles_thumbnail_en.webp',
    feature_tutorial_youtube: 'https://www.youtube.com/embed/cMc71uumga4?si=Asfp-N2yjNahp_M5&autoplay=1',

    feature_extra_title: "Aplikasi terintegrasi dari konversi transkrip ke subtitle",
    feature_extra_desc: "<ul><li><strong>Pembuatan Subtitle Video:</strong> Ketika Anda memiliki konten teks untuk video Anda, seperti skrip atau dialog, Anda dapat dengan cepat mengonversi teks tersebut menjadi subtitle video.</li><li><strong>Materi Pendidikan dan Pelatihan:</strong> Bagi pendidik atau pelatih, Anda dapat mengonversi konten pengajaran menjadi subtitle untuk memudahkan pemahaman dan pencatatan oleh siswa. Ini sangat cocok untuk kursus online atau program pelatihan.</li><li><strong>Tingkatkan SEO:</strong> Mengonversi konten video menjadi teks subtitle membantu mesin pencari mengindeks konten Anda, meningkatkan visibilitas situs web atau video Anda.</li></ul>",

    feature_what_title: "Pertanyaan yang sering diajukan",
    feature_what_desc: "<ul><li><h2>Bisakah file TXT yang diunggah secara otomatis diformat dan diberi stempel waktu untuk memenuhi berbagai kebutuhan?</h2><br>Ya, bisa. Kami menawarkan tiga metode pemformatan:<ul><li>Segmentasi subtitle otomatis: Menggunakan fitur segmentasi generator subtitle untuk menentukan titik segmentasi terbaik berdasarkan konten video.</li><li>Segmentasi berdasarkan diarization pembicara: Cocok untuk catatan rapat atau podcast, segmentasi berdasarkan pembicara yang berbeda.</li><li>Segmentasi berdasarkan format asli file TXT: Segmentasi sesuai dengan format yang ada dalam file teks yang disediakan.</li></ul><br><br></li>   <li><h2>Saya tidak memiliki file TXT. Bisakah sistem secara otomatis menghasilkan transkrip atau subtitle untuk saya?</h2><br>Ya, bisa. Saat mengunggah file video Anda, pilih opsi <strong>Transkripsi Otomatis</strong> di bawah <strong>Metode Transkripsi</strong>. Generator subtitle AI kami akan secara otomatis mengonversi konten video Anda menjadi subtitle. Untuk informasi lebih lanjut, kunjungi <a href=\"https://www.taption.com/add-subtitles\">AI Subtitle Generator</a> atau <a href=\"https://www.taption.com/convert-to-text\">Convert video to text</a>.<br><br></li>  <li><h2>Setelah menambahkan stempel waktu ke file TXT, bisakah itu diterjemahkan ke bahasa lain?</h2><br>Ya, bisa. Setelah menambahkan stempel waktu ke file TXT yang disediakan, Anda dapat memilih untuk menerjemahkan ke lebih dari 40 bahasa dari kanan atas platform pengeditan. Ini termasuk Inggris ke Jepang, Inggris ke Vietnam, Inggris ke Tionghoa, dan lainnya. Untuk informasi lebih lanjut tentang terjemahan, silakan lihat <a href=\"https://www.taption.com/translate\">Terjemahan Video Otomatis</a>.</li></ul>"
}
