module.exports = {
    convert_to_text_title:"Audio in Video konvertieren",
    convert_to_text_desc:"Verwandeln Sie Ihre Audiodatei und ein Bild in ein MP4-Video mit Untertiteln",
    start_your_trial:"Starten Sie Ihre kostenlose Testversion!",
    hero_img:"./05_English.webp",

    step_one:"Audio in Text konvertieren",
    step_one_img:"./customized_step1_en.webp",
    step_one_desc:"Laden Sie Ihr Audio hoch und wählen Sie Segmentierung jeder Abschnitt für „Untertitel“",
    
    step_two:"Benutzerdefiniertes Bild hochladen",
    step_two_img:"./customized_step2_en.webp",
    step_two_desc:"Laden Sie auf der Bearbeitungsplattform Ihr Bild unter Export → MP4 Videodatei hoch",

    step_three:"Video exportieren",
    step_three_img:"./customized_step3_en.webp",
    step_three_desc:"Exportieren Sie Ihr angepasstes Video!",

    feature_tutorial_title:'Wie Sie Ihr Audio in ein Video umwandeln',
    feature_tutorial_desc:'Sie haben also eine Audiodatei und denken darüber nach, sie in ein Video zu konvertieren. Das mag wie eine einfache Aufgabe erscheinen – aber es ist viel mehr als das! Es ist ein kluger Schritt, der Ihr Publikum erheblich erweitern kann. Unsere benutzerfreundliche Bearbeitungsplattform macht den gesamten Prozess so einfach und komfortabel wie möglich. <br><br>Übrigens haben wir ein ausführliches Tutorial, das Ihnen den Einstieg erleichtert, also schauen Sie es sich gerne an.',
    feature_tutorial_poster_url:'./audio_to_video_tutorial_poster_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/efDzKWNMgxw?autoplay=1',

    feature_extra_title:"Warum sollten Sie Ihre Audiodatei in ein MP4-Video mit On-Screen-Text umwandeln?",
    feature_extra_desc:"Haben Sie schon einmal darüber nachgedacht, Ihre Audiodateien auf die nächste Stufe zu heben, indem Sie sie in MP4-Videos mit Untertiteln verwandeln? Hier sind die Vorteile: <ul><li><strong>Inhaltliche Wiederverwendung:</strong> Ein großer Vorteil ist die Möglichkeit, Ihren Inhalt auf verschiedenen sozialen Medienplattformen zu nutzen. Das erweitert Ihre Reichweite und maximiert die Verwendung des bereits erstellten Inhalts.</li><li><strong>Einzigartige Interaktion:</strong> Die Ergänzung von Untertiteln ist eine innovative und integrative Möglichkeit, Ihr Publikum zu erreichen. </li><li><strong>Erreichen Sie die Welt:</strong> Wenn Sie Audio in Video umwandeln, ändern Sie nicht nur das Format – Sie machen Ihren Inhalt einem weltweiten Publikum zugänglich. Mit Transkriptionen und Untertiteln kennt Ihre Botschaft keine Grenzen.</li></ul>Mit diesen Vorteilen verbessern Sie Ihr gesamtes Content-Spiel. Das sind doch ziemlich gute Gründe, Ihr Audio in ein Video zu konvertieren, oder?"
}
