module.exports = {
    convert_to_text_title:"Transcriptions vers Intervenants",
    convert_to_text_desc:"Ajoutez automatiquement des étiquettes d’intervenants à vos transcriptions existantes",
    start_your_trial:"Essayez maintenant!",
    hero_img:"./10_English.webp",

    step_one:"Téléchargez votre fichier média",
    step_one_img:"./select_source_en.webp",
    step_one_desc:"Sélectionnez le fichier audio ou vidéo correspondant à la transcription que vous possédez",
    
    step_two:"Téléchargez votre fichier de transcription",
    step_two_img:"./transcripts_subtitles_step02_en.webp",
    step_two_desc:"Sélectionnez <span style=\"color:#3ccebd\"><strong>Importer un fichier texte</strong></span> pour la <strong>Méthode de transcription</strong> et <span style=\"color:#3ccebd\"><strong>Étiquetage des intervenants</strong></span> pour <strong>Sélectionner votre format de sortie</strong>",

    step_three:"Modifier et exporter",
    step_three_img:"./export_srt_step03_en.webp",
    step_three_desc:"Une fois terminé, rendez-vous sur la plateforme d'édition pour mettre à jour les noms des intervenants ou traduire. Exportez-le dès que vous êtes prêt!",

    feature_tutorial_title:'Étiquetage automatisé des intervenants pour une meilleure clarté',
    feature_tutorial_desc:'Fini la confusion sur qui parle dans votre contenu audio. Avec Taption, identifier les voix dans les podcasts, interviews ou discussions de groupe n’a jamais été aussi simple – tout cela grâce à notre logiciel de reconnaissance des intervenants. Téléchargez simplement votre transcription et laissez notre technologie de détection IA faire le travail.<br><br>Que fait précisément notre technologie? Elle identifie et tague les interventions de chaque intervenant avec précision. En utilisant notre identification automatisée des intervenants, vous garantissez une parfaite clarté dans toutes vos vidéos pour que votre public ne soit jamais perdu ou ait du mal à suivre la conversation.',
    feature_tutorial_poster_url:'./transcripts_to_subtitles_thumbnail_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/3DNDiW8jiDU?si=ZWqZnGBTdjUmVU0_&autoplay=1',

    feature_extra_title:"Les avantages d’un étiquetage clair des intervenants",
    feature_extra_desc:"Dans les discussions à plusieurs voix, savoir qui parle est essentiel - cela façonne l’expérience du public. Avec Taption, vous obtenez:<br><ul><li><strong>Dialogue transparent:</strong> Les auditeurs peuvent facilement suivre la conversation puisque chaque voix est clairement marquée.</li><li><strong>Transcriptions améliorées:</strong> Votre contenu laisse une impression plus professionnelle, améliorant l’image de votre entreprise.</li><li><strong>Gain de temps:</strong> Imaginez les heures que vous pouvez récupérer avec un outil d’étiquetage audio qui identifie correctement les intervenants du premier coup.</li></ul><br>Découvrez les avantages de l’identification professionnelle des intervenants avec les outils avancés de Taption. Grâce à notre solution d’étiquetage des transcriptions multi-intervenants et d’attribution de voix, vous pouvez être sûr que vos services de transcription de réunions et d’étiquetage de transcriptions d’entrevues répondent aux normes les plus élevées de précision et de détail."
}
