module.exports = {
    convert_to_text_title:"Mit Teams zusammenarbeiten",
    convert_to_text_desc:"Cloudbasierter Team-Account mit geteilten Transkripten und Labels",
    start_your_trial:"Starten Sie Ihre kostenlose Testversion!",
    hero_img:"./06_English.webp",

    step_one:"Multi-User-Zugang",
    step_one_img:"./collaborate_step1_en.webp",
    step_one_desc:"Fügen Sie Nutzer zu Ihrem Account hinzu und verwalten Sie sie",
    
    step_two:"Transkript teilen und bearbeiten",
    step_two_img:"./collaborate_step2_en.webp",
    step_two_desc:"Fügen Sie Nutzer zu Ihrem Account hinzu, um Transkripte und Minuten zu teilen",

    step_three:"Verwaltung der Nutzerberechtigungen",
    step_three_img:"./collaborate_step3_en.webp",
    step_three_desc:"Verwalten Sie die Ansichts- und Bearbeitungsberechtigungen der Konten",

    feature_tutorial_title:'Nutzermanagement-Tutorial: Erweitern Sie die Teamarbeit mit Multi-User-Zugang',
    feature_tutorial_custom_desc:'Neben den 6 voreingestellten Berechtigungen können Sie auch den Zugang zu einzelnen Funktionen für jeden Nutzer im Account individuell anpassen.',
    feature_tutorial_custom_url:'./custom_dialog_en.webp',
    feature_tutorial_desc:'Interessiert daran, Ihren Arbeitsablauf zu optimieren und gleichzeitig Ihre Abrechnung zu vereinfachen? Dann könnte der Multi-User-Zugang von Taption die Lösung sein, nach der Sie suchen. Mit unserer cloudbasierten Plattform im Zentrum können Sie effizientere Betriebsabläufe erwarten, gepaart mit hervorragendem Nutzermanagement.<br><br>Klingt das vielversprechend? Dann vergessen Sie nicht, sich unser detailliertes Tutorial anzusehen, um alle Einblicke zu erhalten.',
    feature_tutorial_poster_url:'./permission_tutorial_poster_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/z1v2YdADBW8?autoplay=1',

    feature_extra_title:"Warum die Verwaltung von Nutzerberechtigungen strategisch sinnvoll ist",
    feature_extra_desc:"Die Verwaltung, wer in Taption was tun kann, ist nicht nur eine lästige Verwaltungsaufgabe. Es ist eine kluge Entscheidung, die die Interaktion Ihres Teams mit unserer Plattform neu gestalten kann. Wenn Sie jetzt neugierig geworden sind, hier einige Gründe, warum Sie aktiv werden sollten: <ul><li><strong>Nahtlose Integration:</strong> Neue Teammitglieder hinzuzufügen ist wirklich einfach und hilft allen, sich reibungslos anzupassen. </li><li><strong>Sichern Sie Ihre Daten:</strong> Rollenbasierter Zugang stellt sicher, dass sensible Daten geschützt bleiben, sodass wichtige Transkripte in den richtigen Händen bleiben. </li><li><strong>Fokussierte Expertise:</strong> Das Einschränken bestimmter Plattformfunktionen auf bestimmte Teammitglieder fördert Spezialisierung und steigert die Qualität Ihrer Projekte. </li><li><strong>Alles unter Kontrolle behalten:</strong> Mit voller Managementkontrolle können Sie die Nutzung geteilte Transkripte und die Aktivierung von Funktionen überwachen und so einen sicheren und harmonischen Arbeitsraum garantieren.</li></ul>Mit diesen Nutzermanagement-Taktiken holen Sie das Beste aus Ihrem Team und Taption heraus. Wie wäre es damit, heute den ersten Schritt zu machen?"
}
