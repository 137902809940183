module.exports = {
    convert_mp3_text:'Converta seu MP3 para texto',
    convert_mp3_desc:'Somos o melhor conversor de áudio para texto online. Nossa tecnologia de IA de ponta entrega a transcrição de forma rápida e precisa, mantendo os custos baixos.',
    convert_mp3_now:'Converta seu MP3 para texto agora',
    common_questions_title: 'Perguntas frequentes',
    convert_button_desc1:'15 minutos grátis',
    convert_button_desc2:'Não é necessário cartão de crédito',
    question1:'Como transcrever o meu vídeo?',
    question2:'Como adicionar legendas ao meu vídeo?',
    question2_desc:'Legendas integradas ou incorporadas',
    question3:'Como traduzir o meu vídeo?',
    question4:'Como aumentar a precisão usando o Dicionário de IA?',
    question5:'Como editar meu vídeo editando o texto?',
    home:'Início',
    transcribe_now:'Transcrever agora',
    taption:'Taption - Gere automaticamente transcrições, traduções e legendas',
    title:'Gere automaticamente transcrições, traduções e legendas | Taption',
    content_title:'Gere automaticamente transcrições, traduções e legendas | Taption',
    content_description:'Plataforma completa que oferece transcrições e legendas de alta qualidade para vídeos',
    cover_image_url:'https://www.taption.com/taption-cover-en.jpg',
    site_url:'https://www.taption.com',
    site_name:'Taption'
}
