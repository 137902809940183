module.exports = {
    convert_to_text_title:"Berkolaborasi dengan tim",
    convert_to_text_desc:"Akun tim berbasis cloud dengan transkrip dan label yang dibagikan",
    start_your_trial:"Mulai uji coba gratis Anda!",
    hero_img:"./06_English.webp",

    step_one:"Akses multi-pengguna",
    step_one_img:"./collaborate_step1_en.webp",
    step_one_desc:"Tambahkan pengguna ke akun Anda dan kelola",
    
    step_two:"Bagikan dan edit transkrip",
    step_two_img:"./collaborate_step2_en.webp",
    step_two_desc:"Tambahkan pengguna ke akun Anda untuk berbagi transkrip dan menit",

    step_three:"Manajemen izin pengguna",
    step_three_img:"./collaborate_step3_en.webp",
    step_three_desc:"Kelola izin melihat dan mengedit akun",

    feature_tutorial_title:'Tutorial manajemen pengguna: Tingkatkan kolaborasi tim dengan akses multi-pengguna',
    feature_tutorial_custom_desc:'Selain 6 izin yang telah ditetapkan, Anda juga dapat menyesuaikan akses ke setiap fitur untuk setiap pengguna di bawah akun.',
    feature_tutorial_custom_url:'./custom_dialog_en.webp',
    feature_tutorial_desc:'Apakah Anda tertarik untuk memperlancar alur kerja Anda sekaligus membuat penagihan Anda lebih mudah? Maka akses multi-pengguna Taption mungkin menjadi solusi yang Anda cari. Dengan platform berbasis cloud kami di pusatnya, Anda dapat mengharapkan operasi yang lebih efisien serta manajemen pengguna yang sangat baik.<br><br>Apakah ini terdengar menjanjikan? Jangan lupa untuk melihat tutorial terperinci kami untuk mendapatkan semua wawasan.',
    feature_tutorial_poster_url:'./permission_tutorial_poster_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/z1v2YdADBW8?autoplay=1',

    feature_extra_title:"Mengapa mengelola izin pengguna adalah keputusan yang cerdas secara strategis",
    feature_extra_desc:"Mengelola siapa yang dapat melakukan apa di Taption bukan hanya tugas admin yang mengganggu. Ini adalah pilihan cerdas yang dapat merancang ulang interaksi tim Anda dengan platform kami. Jika Anda tertarik sekarang, berikut adalah beberapa alasan kuat mengapa Anda harus mengambil tindakan: <ul><li><strong>Integrasi Tanpa Hambatan:</strong> Menambahkan anggota baru ke tim sangat mudah dan membantu semua orang beradaptasi tanpa masalah.</li><li><strong>Amankan Data Anda:</strong> Akses berbasis peran memastikan bahwa data sensitif tetap terlindungi, sehingga transkrip penting tetap utuh dan di tangan yang tepat.</li><li><strong>Keahlian Terfokus:</strong> Membatasi fitur platform tertentu untuk anggota tim tertentu membantu membangun spesialisasi dan meningkatkan kualitas proyek Anda.</li><li><strong>Jaga Semuanya Tetap Terkendali:</strong> Dengan kontrol manajemen penuh, Anda dapat mengawasi penggunaan transkrip bersama dan aktivasi fitur, menjamin ruang kerja yang aman dan harmonis.</li></ul>Dengan menerapkan taktik manajemen pengguna ini, Anda menghadirkan yang terbaik dari tim dan Taption Anda. Bagaimana jika Anda mengambil langkah pertama hari ini?"
}
