module.exports = {
    convert_to_text_title:"Colaborar con equipos",
    convert_to_text_desc:"Cuenta de equipo basada en la nube con transcripciones y etiquetas compartidas",
    start_your_trial:"¡Comienza tu prueba gratuita!",
    hero_img:"./06_English.webp",

    step_one:"Acceso multiusuario",
    step_one_img:"./collaborate_step1_en.webp",
    step_one_desc:"Añade usuarios a tu cuenta y gestiona",
    
    step_two:"Comparte y edita transcripción",
    step_two_img:"./collaborate_step2_en.webp",
    step_two_desc:"Añade usuarios a tu cuenta para compartir transcripciones y minutos",

    step_three:"Gestión de permisos de usuarios",
    step_three_img:"./collaborate_step3_en.webp",
    step_three_desc:"Gestiona permisos de visualización y edición de cuentas",

    feature_tutorial_title:'Tutorial de gestión de usuarios: Mejora la colaboración en equipo con acceso multiusuario',
    feature_tutorial_custom_desc:'Además de los 6 permisos preestablecidos, también puedes personalizar el acceso a cada función para cada usuario bajo la cuenta.',
    feature_tutorial_custom_url:'./custom_dialog_en.webp',
    feature_tutorial_desc:'¿Te interesa mejorar tu flujo de trabajo y, al mismo tiempo, simplificar tu facturación? Entonces el acceso multiusuario de Taption podría ser la solución que estabas buscando. Con nuestra plataforma basada en la nube como el núcleo de todo, puedes esperar operaciones más rentables junto con una excelente gestión de usuarios.<br><br>¿Suena prometedor? No olvides ver nuestro tutorial detallado para obtener todos los detalles.',
    feature_tutorial_poster_url:'./permission_tutorial_poster_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/z1v2YdADBW8?autoplay=1',

    feature_extra_title:"Por qué gestionar permisos de usuarios es una decisión estratégica",
    feature_extra_desc:"Gestionar quién puede hacer qué en Taption no es solo una tarea administrativa molesta. Es una elección inteligente que puede redefinir la interacción de tu equipo con nuestra plataforma. Si te intriga, aquí hay algunas razones sólidas para tomar medidas: <ul><li><strong>Integración fluida:</strong> Añadir nuevos miembros al equipo es realmente fácil y ayuda a que todos se adapten sin problemas. </li><li><strong>Asegura tus datos:</strong> El acceso basado en roles garantiza que los datos sensibles se mantengan protegidos, para que las transcripciones clave permanezcan intactas y en las manos correctas. </li><li><strong>Especialización enfocada:</strong> Limitar ciertas características de la plataforma a miembros específicos del equipo ayuda a desarrollar especialización y eleva el nivel de tus proyectos. </li><li><strong>Mantén el control:</strong> Con el control de gestión total, puedes supervisar el uso compartido de transcripciones y la activación de características, lo que garantiza un espacio operativo seguro y armonioso.</li></ul>Adoptando estas tácticas de gestión de usuarios, estás sacando lo mejor de tu equipo y de Taption. ¿Qué tal dar ese paso hoy mismo?"
}
