module.exports = {
    convert_mp3_text:'Convertissez votre MP3 en texte',
    convert_mp3_desc:'Nous sommes le meilleur convertisseur de discours en texte en ligne. Notre technologie IA de pointe vous offre des transcriptions rapides et précises à un coût réduit.',
    convert_mp3_now:'Convertissez votre MP3 en texte maintenant',
    common_questions_title: 'Questions fréquemment posées',
    convert_button_desc1:'15 minutes gratuites',
    convert_button_desc2:'Pas de carte de crédit requise',
    question1:'Comment transcrire ma vidéo ?',
    question2:'Comment ajouter des sous-titres à ma vidéo ?',
    question2_desc:'Sous-titres intégrés ou codés en dur',
    question3:'Comment traduire ma vidéo ?',
    question4:'Comment augmenter la précision avec le Dictionnaire IA ?',
    question5:'Comment éditer ma vidéo en modifiant le texte ?',
    home:'Accueil',
    transcribe_now:'Transcrivez maintenant',
    taption:'Taption - Génère automatiquement transcription, traduction et sous-titres',
    title:'Générez automatiquement transcription, traduction et sous-titres | Taption',
    content_title:'Générez automatiquement transcription, traduction et sous-titres | Taption',
    content_description:'Une plateforme complète qui vous offre des transcriptions et des sous-titres de haute qualité pour vos vidéos',
    cover_image_url:'https://www.taption.com/taption-cover-en.jpg',
    site_url:'https://www.taption.com',
    site_name:'Taption'
}
