module.exports = {
    convert_to_text_title:"Transkripte zu Sprechern",
    convert_to_text_desc:"Fügen Sie Ihren vorhandenen Transkripten automatisch eine Sprecherbeschriftung hinzu",
    start_your_trial:"Jetzt ausprobieren!",
    hero_img:"./10_English.webp",

    step_one:"Laden Sie Ihre Mediendatei hoch",
    step_one_img:"./select_source_en.webp",
    step_one_desc:"Wählen Sie die Audio- oder Videodatei aus, die Ihrem vorhandenen Transkript entspricht.",
    
    step_two:"Laden Sie Ihr Transkript hoch",
    step_two_img:"./transcripts_subtitles_step02_en.webp",
    step_two_desc:"Wählen Sie <span style=\"color:#3ccebd\"><strong>Textdatei importieren</strong></span> für die <strong>Transkriptionsmethode</strong> und <span style=\"color:#3ccebd\"><strong>Sprecherbeschriftung</strong></span> für <strong>Ausgabeformat wählen</strong>.",

    step_three:"Bearbeiten und exportieren",
    step_three_img:"./export_srt_step03_en.webp",
    step_three_desc:"Sobald die Bearbeitung abgeschlossen ist, gehen Sie zur Bearbeitungsplattform, um die Sprechernamen zu aktualisieren oder zu übersetzen. Exportieren Sie das Ergebnis, wenn Sie fertig sind!",

    feature_tutorial_title:'Automatisierte Sprecherbeschriftung für mehr Klarheit',
    feature_tutorial_desc:'Die Zeiten der Verwirrung darüber, wer in Ihren Audiodateien gesprochen hat, sind vorbei. Mit Taption war die Identifizierung von Stimmen in Podcasts, Interviews oder Gruppendiskussionen noch nie so einfach – dank unserer Sprechererkennungssoftware. Laden Sie einfach Ihr Transkript hoch, und unsere KI-Sprechererkennung übernimmt den Rest.<br><br>Was genau macht unsere Technologie? Sie erkennt und markiert die Beiträge jedes Sprechers mühelos. Mit unserer automatisierten Sprechererkennung sorgen Sie für perfekte Klarheit in all Ihren Videos, damit Ihr Publikum nie verwirrt ist, wer spricht, oder Schwierigkeiten hat, der Unterhaltung zu folgen.',
    feature_tutorial_poster_url:'./transcripts_to_subtitles_thumbnail_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/3DNDiW8jiDU?si=ZWqZnGBTdjUmVU0_&autoplay=1',

    feature_extra_title:"Die Vorteile klarer Sprecherbeschriftung",
    feature_extra_desc:"In Diskussionen mit mehreren Stimmen ist es wichtig zu wissen, wer spricht – das prägt das Hörerlebnis. Mit Taption erhalten Sie:<br><ul><li><strong>Transparenter Dialog:</strong> Zuhörer können der Unterhaltung leicht folgen, da jede Stimme deutlich markiert ist.</li><li><strong>Verbesserte Transkripte:</strong> Ihr Inhalt hinterlässt einen professionelleren Eindruck und verbessert das Image Ihres Unternehmens.</li><li><strong>Zeitersparnis:</strong> Stellen Sie sich die Stunden vor, die Sie mit einem Audiobeschriftungstool zurückgewinnen können, das die Sprecheridentifizierung beim ersten Versuch meistert.</li></ul><br>Erleben Sie die Vorteile einer professionellen Sprechererkennung mit den fortschrittlichen Tools von Taption. Dank unserer Multi-Sprecher-Transkriptionstagging- und Stimmerkennungs-Lösungen können Sie sicher sein, dass Ihre Besprechungstranskriptionsdienste und Interviewtranskript-Beschriftungen den höchsten Standards an Genauigkeit und Detailgenauigkeit entsprechen."
}
