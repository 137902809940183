module.exports = {
    convert_mp3_text:'Convierte tu MP3 a texto',
    convert_mp3_desc:'Somos el mejor convertidor de audio a texto en línea. Nuestra tecnología de IA líder proporciona transcripciones rápidas y precisas a un coste reducido.',
    convert_mp3_now:'Convierte tu MP3 a texto ahora',
    common_questions_title: 'Preguntas frecuentes',
    convert_button_desc1:'15 minutos gratis',
    convert_button_desc2:'No se requiere tarjeta de crédito',
    question1:'¿Cómo transcribir mi vídeo?',
    question2:'¿Cómo añadir subtítulos a mi vídeo?',
    question2_desc:'Subtítulos incrustados o con código fijo',
    question3:'¿Cómo traducir mi vídeo?',
    question4:'¿Cómo aumentar la precisión usando el Diccionario de IA?',
    question5:'¿Cómo editar mi vídeo editando el texto?',
    home:'Inicio',
    transcribe_now:'Transcribir ahora',
    taption:'Taption - Genera automáticamente transcripciones, traducciones y subtítulos',
    title:'Genera automáticamente transcripciones, traducciones y subtítulos | Taption',
    content_title:'Genera automáticamente transcripciones, traducciones y subtítulos | Taption',
    content_description:'Plataforma completa que te ofrece transcripciones y subtítulos de alta calidad para tus vídeos',
    cover_image_url:'https://www.taption.com/taption-cover-en.jpg',
    site_url:'https://www.taption.com',
    site_name:'Taption'
}