module.exports = {
    convert_to_text_title:"Rotulagem de oradores para vídeos",
    convert_to_text_desc:"Rotulagem automática para os seus vídeos e áudios.",
    start_your_trial:"Comece o seu teste grátis!",
    hero_img:"./04_English.webp",

    step_one:"Carregar o seu ficheiro",
    step_one_img:"./select_source_en.webp",
    step_one_desc:"Carregue o seu ficheiro a partir de uma das seguintes fontes: Dispositivo, YouTube, Google Drive ou Zoom.",
    
    step_two:"Configurar para rotulagem de oradores",
    step_two_img:"./speaker_step2_en.webp",
    step_two_desc:"Certifique-se de escolher o idioma correspondente ao vídeo ao carregar e escolha segmentar cada seção para \"orador\".",

    step_three:"Completo",
    step_three_img:"./speaker_step3_en.webp",
    step_three_desc:"Depois que a transcrição estiver completa, poderá fazer ajustes aos oradores na plataforma de edição.",

    feature_tutorial_title:'Adicione rotulagem fácil de oradores aos seus vídeos',
    feature_tutorial_desc:'Está pronto para trazer clareza aos seus ficheiros de áudio ou vídeo? A nossa funcionalidade de rotulagem automática de oradores torna simples para os seus visualizadores identificar e rotular diferentes oradores. Isso ajuda a manter o seu conteúdo organizado e fácil de seguir o tempo todo.<br><br>Quer ver como se faz? Temos um tutorial em vídeo prático que pode ajudá-lo a orientar-se em cada etapa.',
    feature_tutorial_poster_url:'./speaker_labeling_tutorial_poster_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/l5xkhJZy5ww?autoplay=1',

    feature_extra_title:"Por que transcrever reuniões e conferências web faz sentido",
    feature_extra_desc:"Pode pensar que a transcrição de reuniões é apenas para manter registos. Pense novamente. Vamos aprofundar as várias formas como as transcrições podem elevar o seu conteúdo: <ul><li><strong>Retenção de Informação:</strong> Não perca um detalhe. Capture todas as informações da sua reunião para garantir que nada fique esquecido. </li><li><strong>Otimização SEO:</strong> Incorpore palavras-chave relevantes nas suas transcrições e veja as suas classificações SEO melhorarem. </li><li><strong>Acessibilidade de Conteúdo:</strong> Com transcrição automática, não está apenas a registar palavras; está a torná-las disponíveis para um público mais amplo – incluindo aqueles com deficiências auditivas.</li><li><strong>ROI:</strong> Aumente o seu Retorno sobre o Investimento, oferecendo aos participantes uma forma alternativa de rever as ideias ou produtos discutidos, potencialmente levando a conversões.</li></ul>Então, o que está a ganhar? Bem, está a aumentar o envolvimento do público e a tornar o seu conteúdo mais inclusivo. É hora de aproveitar estes benefícios e tornar os seus vídeos e reuniões o mais profissionais e úteis possível."
}
