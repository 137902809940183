module.exports = {
    home:'Beranda',
    audiotovideolang:'Ubah audio menjadi video',
    speakerlang:'Penandaan pembicara untuk video',
    translatelang:'Terjemahkan video',
    aianalysislang:'Alat analisis AI',
    addsubtitleslang:'Tambahkan subtitle ke video',
    converttextlang:'Ubah video menjadi teks',
    collaboratelang:'Berkolaborasi dengan tim',
    transcriptstosubtitleslang:'Konversi TXT ke SRT',
    transcriptstospeakerslang:'Tambahkan penandaan pembicara untuk transkrip yang ada',
}
