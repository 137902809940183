module.exports = {
  enjoy_your_free_trial: 'Ihr erstes Transkript ist kostenlos!',
  dialog_title: 'Guthaben hinzufügen',
  subscription_title: 'Abonnement abschließen',
  pay: 'Bezahlen',
  error_unauthenticate: 'Wir können Ihre Zahlungsmethode nicht autorisieren. Bitte versuchen Sie es mit einer anderen Karte.',
  error_payment_creation: 'Ihre Zahlung konnte nicht abgeschlossen werden, bitte kontaktieren Sie den Support',
  error_general: 'Verbindung zum Server konnte nicht hergestellt werden, bitte aktualisieren Sie und versuchen Sie es erneut',
  error_timeout: 'Zeitüberschreitung der Verbindung, bitte aktualisieren Sie und versuchen Sie es erneut',

  expired_card:'Die Karte ist abgelaufen. Bitte verwenden Sie eine andere Kreditkarte.',
  do_not_honor:'Die Karte wurde abgelehnt. Bitte wenden Sie sich an Ihren Kartenaussteller für weitere Informationen.',
  insufficient_funds:'Die Karte verfügt nicht über ausreichende Mittel für diese Transaktion.',
  invalid_info: 'Die angegebenen Karteninformationen sind ungültig. Bitte überprüfen und erneut versuchen.',

  success: 'Erfolg',
  success_description: 'Ihr Konto wurde mit <br/> <b class="number-of-minutes">{0} Minuten</b> aufgeladen',
  success_description_bonus: 'Bonusminuten: <b class ="extra-minutes"> {1} Minuten</b> <br/>Ihr Konto wurde mit <br/> <b class="number-of-minutes">{0} Minuten</b> aufgeladen',
  success_subscription_description: 'Ihr Premium-Abonnement wurde aktiviert',
  success_corp_subscription_description: 'Ihr Bulk-Abonnement wurde aktiviert',
  cancel_subscription_confirmation: 'Möchten Sie das Abonnement wirklich kündigen?',
  success_subscription_bonus: 'Bonusminuten: <b class ="extra-minutes"> {0} Minuten</b>',

  cancel_subscription_desc:' Minuten verfallen sofort bei Stornierung<br>Nur die ersten <span style="color:rgb(246, 102, 101)!important">1</span> Labels können nach Stornierung verwendet werden',
  cancel_subscription_confirm:'Hiermit bestätige ich, auf die oben genannten Minuten zu verzichten',
  reactivate_subscription_confirmation: 'Sind Sie sicher, dass Sie das Abonnement wieder aktivieren möchten?',
  cacnel_subscription_description: 'Sie haben weiterhin Premium-Zugriff bis zum Ende des Zeitraums.',
  cacnel_subscription: 'Ihr Premium-Abonnement wurde gekündigt',
  cancel_corp_subscription: 'Ihr Bulk-Abonnement wurde gekündigt',
  reactive_subscription: 'Ihr Abonnement wurde reaktiviert!',
  active_until:'Aktiv bis',
  success_translation_description: 'Ihre Übersetzung ist fertig!',
  yes: 'Ja',
  no: 'Nein',
  continue: 'Weiter',
  save: 'Speichern',
  cancel: 'Abbrechen',
  processing_payment: 'Zahlung wird bearbeitet',
  error_edit: 'Kartenaktualisierung konnte nicht abgeschlossen werden, bitte kontaktieren Sie den Support',
  delete_ok: 'Entfernen',
  delete_cancel: 'Abbrechen',
  billing_address: 'Rechnungsadresse',
  how_many_hours: 'Wie viele Stunden?',
  credit_card: 'Kreditkarte',
  summary: 'Zusammenfassung (USD)',
  save_payment: 'Meine Karte für zukünftige Käufe speichern',
  processing_payment_description: 'Dies kann einige Minuten dauern...',
  credit_card_manage:'Ihre Kreditkarte wird sicher von Stripe, Inc verarbeitet',
  credit_card_secured:'Transaktionen sind sicher und verschlüsselt.',
  understand_billing:'Ich autorisiere diese Zahlung',
  processing_saving:'Kreditkarte wird aktualisiert',
  
  full_name: 'Vollständiger Name',
  name: 'Name',
  address: 'Adresse',
  city: 'Stadt',
  state: 'Bundesland',
  zip: 'PLZ',

  max_length_field: '{0} darf maximal {1} Zeichen lang sein',
  required_field: '{0} ist erforderlich',
  delete_title: 'Diese Karte entfernen?',

  title_basic: 'Standard',
  title_pay_as_you_go: 'Pay-as-you-go',
  title_premium: 'Premium',
  title_custom: 'Individuell',
  title_corp: 'Bulk',
  title_subscription:'Abonnement',

  basic_feature5: 'Guthaben <span style="color: #00d1b2;">60 Minuten</span>',

  premium_feature1: '{0} <span style="color: #00d1b2;">Minuten</span> jeden Monat.<br><span class="price-notes">(Übertragbare Minuten, gültig bis Abonnement gekündigt)</span>',
  premium_feature2: '25% Rabatt auf stundenweise Käufe',
  premium_feature3_tooltip: '2 $ pro Dokument',

  buy: 'Minuten kaufen',
  activate: 'Aktivieren',
  subscribe: 'Abonnieren',
  contact_us: 'Kontaktieren Sie uns',

  custom_feature1: 'Geschäftspartnerschaften',
  custom_feature2: 'Gruppenabonnements',
  custom_feature3: 'Zentralisierte Abrechnung',
  custom_feature4: 'Feedback oder Fehler melden',

  month: 'Monat',
  per_hour: 'pro Stunde',
  per_month: 'pro Monat',
  per_user_per_month:'{0} pro Benutzer/Monat',

  how_get_free:'Wie kann ich kostenlose Minuten erhalten?',
  payment_questions:'Haben Sie Fragen zur Abrechnung?',

  translate_remaining:'Sie haben noch {0} übrig.',
  translate_deduct:'Wir ziehen {0} ab, wenn Sie dieses Transkript übersetzen.',
  transcribe_deduct:'Wir ziehen {0} ab, wenn Sie dieses Transkript transkribieren.',
  transcribe_warning:'Bitte stellen Sie sicher, dass Sie über genügend Minuten verfügen, um zu transkribieren, da der Vorgang sonst fehlschlägt.',
  mp4_deduct:'Wir ziehen {0} ab, wenn Sie dieses Transkript als MP4 exportieren.',
  cvc:'Was ist CVC?',
  confirm_translate:'Ich möchte mit der Übersetzung fortfahren',
  recurring_description:'• Wiederkehrende monatliche Zahlung',
  recurring_description2:'• Jederzeit unter Konto kündbar',
  coupon_header:'Aktionscode',
  coupon_title:'Geben Sie Ihren Aktionscode ein:',
  coupon_submit:'Anwenden',
  processing_coupon: 'Aktionscode wird angewendet',
  coupon_empty: 'Bitte geben Sie Ihren Aktionscode ein',
  coupon_expire: 'Der Aktionscode ist abgelaufen',
  coupon_notexist: 'Der Aktionscode ist ungültig',
  coupon_firsttimeonly: 'Der Aktionscode ist nur für neue Benutzer gültig',
  member_slot_desc:'Gesamtanzahl der Abonnementplätze, um Mitglieder mit unterschiedlichen Berechtigungen einzuladen, um Transkripte aus diesem Konto hinzuzufügen, hochzuladen, zu bearbeiten und/oder zu exportieren.',
  credit_edit_title:'Kreditkarteninformationen',

  payment_confirm_title:'Zahlungsbestätigung',
  payment_confirm_desc:'Diese Zahlung wird in <span style="color:#2fc59f;font-weight:bold;">US-Dollar</span> in Höhe von {0} abgerechnet.',
  upgrade_contact_us:'Für Unternehmenslösungen, Affiliate-Programme oder Geschäftsanfragen kontaktieren Sie uns bitte unter <span style="color:#2fc59f">help@taption.com</span>'
}
