module.exports = {
    enjoy_your_free_trial: '您的第一個上傳免費!',
    dialog_title: '取得更多分鐘',
    subscription_title: '獲取訂閱',
    pay: '確認',
    error_unauthenticate: '我們無法驗證您的付款方式，請換一張信用卡',
    error_payment_creation: '無法完成付款，或與我們客服人員聯繫',
    error_general: '無法完成與服務器的連接，請刷新並重試',
    error_timeout: '連接超時，請刷新並重試',
    expired_card:'信用卡已過期，請換一張卡後再嘗試',
    do_not_honor:'信用卡被拒絕，請聯絡您的銀行或換一張卡後再嘗試', 
    insufficient_funds:'此卡片餘額不足以支付此款項，請確認剩餘金額或換一張卡後再嘗試',
    invalid_info: '信用卡資料輸入有誤，請核對後再嘗試付款',
    success: '成功',
    success_description: '您的帳戶已充值<br/> <b class ="number-of-minutes"> {0}分鐘</b>',
    success_description_bonus: '額外贈送 <b class ="extra-minutes"> {1}分鐘</b> <br/>您的帳戶已充值<br/> <b class ="number-of-minutes"> {0}分鐘</b>',
    success_subscription_description: '您已成為我們高級訂閱用戶',
    success_corp_subscription_description: '您已成為我們大量訂閱用戶',
    success_subscription_bonus: '額外贈送 <b class ="extra-minutes"> {0}分鐘</b>',
    cancel_subscription_confirmation: '確定要取消訂閱嗎？',
    cancel_subscription_desc:' 訂閱分鐘數將會立即失效<br>前 <span style="color:rgb(246, 102, 101)">1</span> 個以外的標籤將無法使用',
    cancel_subscription_confirm:'我確認要取消訂閱並放棄所有訂閱分鐘',
    reactivate_subscription_confirmation: '確定要重啟訂閱嗎？',
    cacnel_subscription_description: '在該期間結束之前，您仍然為高級用戶',
    cacnel_subscription: '您的高級訂閱已被取消',
    cancel_corp_subscription: '您的大量訂閱已被取消',
    reactive_subscription: '您的高級訂閱已被重啟!',
    active_until:'有效至',
    success_translation_description: '您的翻譯已經完成！',
    yes: '是',
    no: '不',
    continue: '繼續',
    save: '保存',
    cancel: '取消',
    processing_payment: '處理付款中',
    error_edit: '無法完成卡更新，請聯繫支持人員',
    delete_ok: '刪除',
    delete_cancel: '取消',
    billing_address: '信用卡註冊地址',
    how_many_hours: '多少小時？',
    credit_card: '信用卡',
    summary: '總共金額(美金)',
    save_payment: '保存此卡片',
    processing_payment_description: '這可能需要幾分鐘...不要刷新',
   // credit_card_manage:'您可以在帳戶頁面中管理您的信用卡。',
    credit_card_manage:'我們使用Stripe來處理信用卡相關作業, 您的個資將得到最高保障',
    credit_card_secured:'確保您的付款安全,此交易過程加密',
    understand_billing:'我允許此付款',
    processing_saving:'更新信用卡中',
    full_name: '姓名',
    name: '名稱',
    address: '地址',
    city: '城市',
    state: '州/省',
    zip: '郵編',
    max_length_field: '{0}的長度最多為{1}個字符',
    required_field: '{0}是必需的',
    delete_title: '要移除此信用卡嗎',
    title_basic: '標準',
    title_pay_as_you_go: '影片長度計價',
    title_premium: '高級訂閱',
    title_custom: '客製化',
    title_corp: '大量訂閱',
    title_subscription:'訂閱制',
    basic_feature5: '充值<span style="color: #00d1b2;">60分鐘</span>',
    basic_feature4_tooltip: '',
    premium_feature1: '每個月贈送{0}<span style="color: #00d1b2;">分鐘</span><br><span class="price-notes">(可跨月累積，有效至訂閱取消為止)</span>',
    premium_feature2: '如需更多分鐘, 可以優惠價格購買',
    premium_feature3_tooltip: '$2每個文本翻譯',
    buy: '購買分鐘',
    activate: '啟用',
    subscribe: '訂閱',
    contact_us: '與我們聯繫',
    custom_feature1: '商業合作',
    custom_feature2: '群體訂閱',
    custom_feature3: '意見回饋',
    custom_feature4: '錯誤回報',
    month: '月',
    per_hour: '每小時',
    per_month: '每個月',
    per_user_per_month:'{0}每位用戶/月',
    how_get_free:'如何獲得免費分鐘?',
    payment_questions:'其他付費相關問題?',
    translate_remaining:'您目前剩餘的影片分鐘: {0}',
    translate_deduct:'翻譯此影片將會扣除的分鐘數: {0}',
    transcribe_deduct:'此影片轉成文字將會扣除的分鐘數: {0}',
    transcribe_warning:'請確認此影片總長度小於您目前的剩餘的影片分鐘，否則將無法順利轉檔。',
    mp4_deduct:'字幕崁入影片將會扣除的分鐘數: {0}',
    cvc:'CVC是什麼?',
    confirm_translate:'我確認要翻譯',
    recurring_description:'＊ 每月自動扣款',
    recurring_description2:'＊ 可隨時到『我的帳號』取消訂閱。',
    coupon_header:'優惠碼',
    coupon_title:'請輸入您的優惠碼:',
    coupon_submit:'確認',
    processing_coupon: '啟用優惠碼中...',
    coupon_empty: '請輸入優惠碼',
    coupon_expire: '優惠碼已過期',
    coupon_notexist: '優惠碼不存在',
    coupon_firsttimeonly: '優惠碼僅限於新用戶',
    member_slot_desc:'可加入其他Taption用戶對於此訂閱帳號的影片<br>來共享分鐘，編輯，上傳，閱讀，導出。<br>並且可針對每個用戶給予不同的權限。',
    credit_edit_title:'更改信用卡資料',
    payment_confirm_title:'金額確認',
    payment_confirm_desc:'款項金額{0}是以<span style="color:#2fc59f;font-weight:bold;">美金</span>計算。',
    upgrade_contact_us:'商業合作，聯盟行銷，客製化方案或其他問題，請寄信到： <span style="color:#2fc59f">help@taption.com</span>'
  }
  