module.exports = {
    title_0: '¿Cómo transcribir mi vídeo?',
    desc_0:'Ver vídeo tutorial',
    video_0:"https://www.youtube.com/embed/FZOHfAFIqog",
    
    title_1: '¿Cómo añadir subtítulos a mi vídeo?',
    desc_1:'Ver vídeo tutorial',
    video_1:"https://www.youtube.com/embed/WEFq3Mcl8dk",
    
    title_2: '¿Cómo traducir mi vídeo?',
    desc_2:'Ver vídeo tutorial',
    video_2:"https://www.youtube.com/embed/mU4uadAK_w0",
    
    title_3: '¿Cómo mejorar la precisión usando el Diccionario de IA?',
    desc_3:'Ver vídeo tutorial',
    video_3:"https://www.youtube.com/embed/7gEBb_moIuI",

    title_4: '¿Cómo editar mi vídeo editando el texto?',
    desc_4:'Ver vídeo tutorial',
    video_4:"https://www.youtube.com/embed/W2yJEU1vMrg",
    
    title_5: '¿Dónde puedo ver mis recibos y facturas?',
    desc_5:'Una vez suscrito, podrás ver y descargarlos en <span style="color:rgb(0, 209, 178);">Cuenta → Facturas → Ver Todo</span>',
    video_5:"",

    title_6: 'Me he suscrito a un plan, ¿qué puedo hacer si necesito más minutos de los que se dan mensualmente?',
    desc_6:'Al suscribirte a la <span style="color:rgb(0, 209, 178);">Suscripción Premium</span>, puedes comprar minutos adicionales a un precio con descuento de <span style="color:rgb(0, 209, 178);">$6/hora</span>. Al suscribirte a la <span style="color:rgb(0, 209, 178);">Suscripción Corporativa</span>, puedes comprar minutos adicionales a un precio con descuento de <span style="color:rgb(0, 209, 178);">$3/hora</span>',
    video_6:"",

    title_7: 'La transcripción no se está traduciendo, ¿cómo puedo solucionarlo?',
    desc_7:'Asegúrate de haber seleccionado el idioma correcto al subir el vídeo. Debes seleccionar el idioma que coincide con el idioma hablado en el vídeo.',
    video_7:"",

    title_8: 'He subido un archivo de menos de 5GB, ¿por qué recibo el mensaje <span style="color:rgb(230, 118, 117);">Tamaño de vídeo demasiado grande</span>?',
    desc_8:'El límite de tamaño de 5GB se basa en el formato MP4. Al subir un vídeo en un formato diferente a MP4, la conversión a MP4 puede resultar en un tamaño mayor a 5GB. Sube un vídeo en formato MP4 de menos de 5GB para evitar este mensaje.',
    video_8:"",

    title_9: '¿Qué significan los errores y cómo puedo solucionarlos?',
    desc_9:'<span style="color:rgb(230, 118, 117);">Exceso de uso de prueba</span> : Compra minutos o suscríbete para continuar utilizando el servicio.<br><span style="color:rgb(230, 118, 117);">No hay suficientes minutos</span> : Compra más minutos o suscríbete y luego inténtalo de nuevo.<br><span style="color:rgb(230, 118, 117);">El enlace de YouTube ha fallado, por favor inténtalo de nuevo</span> : La razón más común es que el vídeo de YouTube proporcionado fue subido recientemente, espera al menos 24 horas antes de intentar transcribirlo de nuevo.',
    video_9:"",

    title_10: '¿Cómo integrar Taption con Zoom?',
    desc_10:'Consulta el <a href="https://www.taption.com/blog/en/zoom-tutorial-en" rel="noreferrer" target="_blank">tutorial paso a paso</a>',
    video_10:"",

    title_11: 'Tengo más preguntas, ¿qué debo hacer?',
    desc_11:'Por favor, contáctanos en <span style="color:rgb(0, 209, 178);">help@taption.com</span>',
    video_11:"",

    zoom_title:'Tutorial paso a paso para integrar Taption con Zoom',
    zoom_install_desc:'Ahora puedes conectar tu cuenta de Zoom con tu cuenta de Taption. Después de este tutorial, podrás transcribir grabaciones de Zoom directamente desde Taption.',
    zoom_step_1:'Haz clic en "Subir" después de iniciar sesión en tu cuenta',
    zoom_step_2:'Elige "Zoom" en el cuadro de diálogo emergente',
    zoom_step_3:'Inicia sesión con tu cuenta de Zoom y autoriza',
    zoom_step_4:'Elige tu grabación de la tabla',
    zoom_step_5:'Configura tus ajustes y estarás listo para subir y transcribir',
    zoom_uninstall_title:'Desinstalar la integración de Taption y Zoom',
    zoom_uninstall_desc:'Para desinstalar Zoom de Taption, puedes hacerlo en <strong>Cuenta</strong> → <strong>Apps</strong> → <strong>Desinstalar</strong><br>Si tienes más preguntas, envíanos un correo a <span style="color:rgb(0, 209, 178);">help@taption.com</span>',

    webex_title:'Tutorial paso a paso para integrar Taption con Webex',
    webex_install_desc:'Ahora puedes conectar tu cuenta de Webex con tu cuenta de Taption. Después de este tutorial, podrás transcribir grabaciones de Webex directamente desde Taption.',
    webex_step_1:'Haz clic en "Subir" después de iniciar sesión en tu cuenta',
    webex_step_2:'Elige "Webex" en el cuadro de diálogo emergente',
    webex_step_3:'Inicia sesión con tu cuenta de Webex y autoriza',
    webex_step_4:'Elige tu grabación de la tabla',
    webex_step_5:'Configura tus ajustes y estarás listo para subir y transcribir',
    webex_uninstall_title:'Desinstalar la integración de Taption y Webex',
    webex_uninstall_desc:'Para desinstalar Webex de Taption, puedes hacerlo en <strong>Cuenta</strong> → <strong>Apps</strong> → <strong>Desinstalar</strong><br>Si tienes más preguntas, envíanos un correo a <span style="color:rgb(0, 209, 178);">help@taption.com</span>',
}
