module.exports = {
  'en': 'Inglés',
  'zh-TW': 'Chino (Tradicional)',
  'zh': 'Chino (Simplificado)',
  'yue-Hant-HK': 'Chino (Cantonés)',
  'yue-Hant-HK-TW': 'Chino (Cantonés)',
  'ja': 'Japonés',
  'ko': 'Coreano',
  'es': 'Español',
  'de': 'Alemán',
  'fr': 'Francés',
  'af': 'Afrikáans',
  'sq': 'Albanés',
  'am': 'Amárico',
  'ar': 'Árabe',
  'hy': 'Armenio',
  'az': 'Azerí',
  'eu': 'Vasco',
  'be': 'Bielorruso',
  'bn': 'Bengalí',
  'bs': 'Bosnio',
  'bg': 'Búlgaro',
  'ca': 'Catalán',
  'co': 'Corso',
  'hr': 'Croata',
  'cs': 'Checo',
  'da': 'Danés',
  'nl': 'Holandés',
  'fi': 'Finlandés',
  'el': 'Griego',
  'gu': 'Guyaratí',
  'he': 'Hebreo',
  'hi': 'Hindi',
  'hu': 'Húngaro',
  'id': 'Indonesio',
  'ga': 'Irlandés',
  'it': 'Italiano',
  'la': 'Latín',
  'lv': 'Letón',
  'lt': 'Lituano',
  'ms': 'Malayo',
  'no': 'Noruego',
  'pl': 'Polaco',
  'pt': 'Portugués (Portugal, Brasil)',
  'ro': 'Rumano',
  'ru': 'Ruso',
  'sr': 'Serbio',
  'sk': 'Eslovaco',
  'sl': 'Esloveno',
  'sv': 'Sueco',
  'te': 'Telugu',
  'th': 'Tailandés',
  'tr': 'Turco',
  'uk': 'Ucraniano',
  'uz': 'Uzbeko',
  'vi': 'Vietnamita',
  'yi': 'Yidis',
  'zu': 'Zulú'
}
