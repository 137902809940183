module.exports = {
    convert_to_text_title_meta: "Automatic Text to SRT Subtitle Conversion with Timestamps",
    convert_to_text_desc_meta: "Upload your existing transcript and use our online subtitle generator to add timestamps automatically. Convert TXT to SRT quickly. Try it for free, register now to start!",
    convert_to_text_title: "Automatic TXT to SRT subtitle conversion",
    convert_to_text_desc: "Upload your existing transcript, and use our subtitle generator to automatically add timestamps and format it. Quickly convert TXT to SRT.",
    start_your_trial: "Try Now!",
    hero_img:"./09_English.webp",

    step_one: "Select the audio/video file for your transcript",
    step_one_img: "./select_source_en.webp",
    step_one_desc: "First, choose the audio/video file that matches your transcript. The transcript and video languages must be the same.",

    step_two: "Import your transcript",
    step_two_img: "./transcripts_subtitles_step02_en.webp",
    step_two_desc: "In the <strong>Transcribing Method</strong> window, select <span style=\"color:#3ccebd\">Import Text File</span>. For <strong>Select your output format</strong>, choose <span style=\"color:#3ccebd\">Captions/Subtitles</span>.",

    step_three: "Edit and export",
    step_three_img: "./export_srt_step03_en.webp",
    step_three_desc: "Enter the editing platform to edit subtitles and export.",

    feature_tutorial_title: 'Convert your text to subtitles',
    feature_tutorial_desc: 'Whether you have a transcript, article, or other text content, simply upload it to Taption platform to quickly use our subtitle generator. It automatically converts your text into video-compatible subtitles, completing txt to srt conversion. With our subtitle generator, you can avoid manual subtitle input and time-consuming timeline alignment. Our tool offers high accuracy and consistency. Additionally, you can easily translate subtitles into over 40 languages, meeting the diverse needs of global audiences.',
    feature_tutorial_poster_url: './transcripts_to_subtitles_thumbnail_en.webp',
    feature_tutorial_youtube: 'https://www.youtube.com/embed/cMc71uumga4?si=Asfp-N2yjNahp_M5&autoplay=1',

    feature_extra_title: "Integrated applications of transcript to subtitle conversion",
    feature_extra_desc: "<ul><li><strong>Video Subtitle Creation:</strong> When you have text content for your video, such as scripts or dialogues, you can quickly convert these texts into video subtitles.</li><li><strong>Educational and Training Materials:</strong> For educators or trainers, you can convert teaching content into subtitles for easier understanding and note-taking by students. This is particularly suitable for online courses or training programs.</li><li><strong>Improve SEO:</strong> Converting video content into text subtitles helps search engines index your content, increasing the visibility of your website or video.</li></ul>",

    feature_what_title: "Frequently asked questions",
    feature_what_desc: "<ul><li><h2>Can the uploaded TXT file be automatically formatted and have timestamps added to meet various needs?</h2><br>Yes, it can. We offer three formatting methods:<ul><li>Automatic subtitle segmentation: Uses the subtitle generator's segmentation feature to determine the best segmentation points based on video content.</li><li>Segmentation by speaker diarization: Suitable for meeting records or podcasts, segmenting based on different speakers.</li><li>Segmentation based on original TXT file format: Segments according to existing format in the provided text file.</li></ul><br><br></li>   <li><h2>I don't have a TXT file. Can the system automatically generate a transcript or subtitles for me?</h2><br>Yes, it can. When uploading your video file, choose the <strong>Automated Transcription</strong> option under <strong>Transcribing Method</strong>. Our AI subtitle generator will automatically convert your video content into subtitles. For more information, visit <a href=\"https://www.taption.com/add-subtitles\">AI Subtitle Generator</a> or <a href=\"https://www.taption.com/convert-to-text\">Convert video to text</a>.<br><br></li>  <li><h2>After adding timestamps to the TXT file, can it be translated into other languages?</h2><br>Yes, it can. After adding timestamps to the provided TXT file, you can choose to translate into over 40 languages from the top right of the editing platform. This includes English to Japanese, English to Vietnamese, English to Chinese, and more. For more translation information, please refer to <a href=\"https://www.taption.com/translate\">Automatic Video Translation</a>.</li></ul>"
}
// module.exports = {
//     convert_to_text_title:"Transcripts to Subtitles",
//     convert_to_text_desc:"Convert your existing transcripts into subtitles",
//     start_your_trial:"Try it now!",
//     hero_img:"./09_English.webp",

//     step_one:"Upload your media file",
//     step_one_img:"./select_source_en.webp",
//     step_one_desc:"Select the audio or video file that matches the transcript you have",
    
//     step_two:"Upload your transcript file",
//     step_two_img:"./transcripts_subtitles_step02_en.webp",
//     step_two_desc:"Select <span style=\"color:#3ccebd\"><strong>Import text file</strong></span> for <strong>Transcribing method</strong> and <span style=\"color:#3ccebd\"><strong>Captions/Subtitles</strong></span> for <strong>Select your output format</strong>",

//     step_three:"Edit and export",
//     step_three_img:"./export_srt_step03_en.webp",
//     step_three_desc:"Once complete head into the editing platform to edit or translate. Export it when ready! ",

//     feature_tutorial_title:'Subtitling made simple from your transcripts',
//     feature_tutorial_desc:'Already have an accurate transcription? All you have to do is upload your document to our online subtitling platform. It’s as easy as that! You will see it transform into precise subtitles that match your video’s pace - no fuss involved. <br><br>This service is the ideal solution for giving a new lease of life to your recorded materials. The subtitles improve inclusivity, and your videos are able to reach more viewers. Our process is easy and streamlined: just bring your transcript, and we’ll handle the transcript subtitle synchronization.',
//     feature_tutorial_poster_url:'./transcripts_to_subtitles_thumbnail_en.webp',
//     feature_tutorial_youtube:'https://www.youtube.com/embed/3DNDiW8jiDU?si=ZWqZnGBTdjUmVU0_&autoplay=1',

//     feature_extra_title:"Why subtitles give you an advantage",
//     feature_extra_desc:"Subtitles don’t just make your videos easy to follow – that is a great benefit, but they can do more than that. In fact, they create a bridge to your audience. When you add subtitles, you:<br><ul><li>Keep your audience tuned in, even in the quietest library or the busiest subway station</li><li>Make sure that every word is caught by your viewers, thanks to our accurate subtitle generator</li><li>Boost your presence in search results through video SEO improvement</li><li>Welcome viewers from around the globe with our multilingual subtitle service</li><li>Step up your game with our accurate, no-sweat subtitle service</li></ul>"
// }