module.exports = {
    home:'Início',
    audiotovideolang:'Converter áudio em vídeo',
    speakerlang:'Rótulos de oradores para vídeos',
    translatelang:'Traduzir vídeo',
    aianalysislang:'Ferramenta de análise por IA',
    addsubtitleslang:'Adicionar legendas ao vídeo',
    converttextlang:'Converter vídeo em texto',
    collaboratelang:'Colaborar com equipas',
    transcriptstosubtitleslang:'Converter TXT em SRT',
    transcriptstospeakerslang:'Adicionar rótulos de oradores a transcrições existentes',
}
