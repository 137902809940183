module.exports = {
    home:'Startseite',
    audiotovideolang:'Audio in Video konvertieren',
    speakerlang:'Sprecherbeschriftung für Videos',
    translatelang:'Video übersetzen',
    aianalysislang:'KI-Analyse-Tool',
    addsubtitleslang:'Untertitel zu Video hinzufügen',
    converttextlang:'Video in Text konvertieren',
    collaboratelang:'Mit Teams zusammenarbeiten',
    transcriptstosubtitleslang:'TXT zu SRT konvertieren',
    transcriptstospeakerslang:'Sprecherbeschriftung zu vorhandenen Transkripten hinzufügen',
}
