module.exports = {
  'en-US': 'Inggris (Amerika Serikat)',
  'en-GB': 'Inggris (Britania Raya)',
  'en-AU': 'Inggris (Australia)',
  'zh-TW': 'Tionghoa (Taiwan)',
  'zh': 'Tionghoa (China)',
  'yue-Hant-HK-TW-zh': 'Kanton (Output Tionghoa Tradisional)',
  'yue-Hant-HK-zh': 'Kanton (Output Tionghoa Sederhana)',
  'yue-Hant-HK-TW': 'Kanton (Output Kanton Tradisional)',
  'yue-Hant-HK': 'Kanton (Output Kanton Sederhana)',
  'es-ES': 'Spanyol (Spanyol)',
  'fr-FR': 'Prancis (Prancis)',
  'de-DE': 'Jerman (Jerman)',
  'it-IT': 'Italia (Italia)',
  'pt-PT_pt-BR': 'Portugis (Portugal, Brasil)',
  'nl-BE': 'Belanda (Belgia)',
  'hi-IN': 'Hindi (India)',
  'ja-JP': 'Jepang (Jepang)',
  'ko-KR': 'Korea (Korea Selatan)',
  'vi-VN': 'Vietnam (Vietnam)',

  'ar-EG': 'Arab (Mesir)',
  'hy-AM': 'Armenia (Armenia)',
  'bs-BA': 'Bosnia (BiH)',
  'bg-BG': 'Bulgaria (Bulgaria)',
  'my-MM': 'Burma (Myanmar)',
  'ca-ES': 'Katalan (Spanyol)',
  'hr-HR': 'Kroasia (Kroasia)',
  'cs-CZ': 'Ceko (Republik Ceko)',
  'da-DK': 'Denmark (Denmark)',
  'tl-PH': 'Filipino (Filipina)',
  'fi-FI': 'Finlandia (Finlandia)',
  'gl-ES': 'Galicia (Spanyol)',
  'el-GR': 'Yunani (Yunani)',
  'gu-IN': 'Gujarati (India)',
  'iw-IL': 'Ibrani (Israel)',
  'hu-HU': 'Hungaria (Hungaria)',
  'id-ID': 'Indonesia (Indonesia)',
  'lv-LV': 'Latvia (Latvia)',
  'lt-LT': 'Lituania (Lituania)',
  'mk-MK': 'Makedonia (Makedonia Utara)',
  'ms-MY': 'Melayu (Malaysia)',
  'no-NO': 'Norwegia (Norwegia)',
  'pl-PL': 'Polandia (Polandia)',
  'ro-RO': 'Rumania (Rumania)',
  'ru-RU': 'Rusia (Rusia)',
  'sk-SK': 'Slovakia (Slovakia)',
  'sl-SI': 'Slovenia (Slovenia)',
  'sv-SE': 'Swedia (Swedia)',
  'ta-IN': 'Tamil (India)',
  'te-IN': 'Telugu (India)',
  'th-TH': 'Thai (Thailand)',
  'tr-TR': 'Turki (Turki)',
  'uk-UA': 'Ukraina (Ukraina)',
  'uz-UZ': 'Uzbek (Uzbekistan)',
  //'vi-VN': 'Vietnam (Vietnam)',
}
