module.exports = {
    title_0: 'Wie kann ich mein Video transkribieren?',
    desc_0:'Siehe Tutorial-Video',
    video_0:"https://www.youtube.com/embed/FZOHfAFIqog",
    
    title_1: 'Wie kann ich Untertitel zu meinem Video hinzufügen?',
    desc_1:'Siehe Tutorial-Video',
    video_1:"https://www.youtube.com/embed/WEFq3Mcl8dk",
    
    title_2: 'Wie kann ich mein Video übersetzen?',
    desc_2:'Siehe Tutorial-Video',
    video_2:"https://www.youtube.com/embed/mU4uadAK_w0",
    
    title_3: 'Wie kann ich die Genauigkeit mit dem KI-Wörterbuch erhöhen?',
    desc_3:'Siehe Tutorial-Video',
    video_3:"https://www.youtube.com/embed/7gEBb_moIuI",

    title_4: 'Wie kann ich mein Video bearbeiten, indem ich den Text bearbeite?',
    desc_4:'Siehe Tutorial-Video',
    video_4:"https://www.youtube.com/embed/W2yJEU1vMrg",
    
    title_5: 'Wo kann ich meine Rechnungen und Belege einsehen?',
    desc_5:'Nach Abschluss eines Abonnements können Sie sie unter <span style="color:rgb(0, 209, 178);">Konto → Rechnungen → Alle anzeigen</span> einsehen und herunterladen',
    video_5:"",

    title_6: 'Ich habe ein Abonnement abgeschlossen, was kann ich tun, wenn ich mehr als die monatlich gegebenen Minuten benötige?',
    desc_6:'Mit dem <span style="color:rgb(0, 209, 178);">Premium-Abonnement</span> können Sie zusätzliche Minuten zu einem vergünstigten Preis von <span style="color:rgb(0, 209, 178);">6 $/Stunde</span> erwerben. Mit dem <span style="color:rgb(0, 209, 178);">Bulk-Abonnement</span> können Sie zusätzliche Minuten zu einem vergünstigten Preis von <span style="color:rgb(0, 209, 178);">3 $/Stunde</span> erwerben',
    video_6:"",

    title_7: 'Die Transkription wird nicht übersetzt, wie kann ich das beheben?',
    desc_7:'Bitte stellen Sie sicher, dass Sie beim Hochladen des Videos die richtige Sprache ausgewählt haben. Sie sollten die Sprache auswählen, die mit der im Video gesprochenen Sprache übereinstimmt.',
    video_7:"",

    title_8: 'Ich habe eine Datei hochgeladen, die kleiner als 5 GB ist. Warum erhalte ich die Meldung <span style="color:rgb(230, 118, 117);">Video zu groß</span>?',
    desc_8:'Das Größenlimit von 5 GB basiert auf dem MP4-Format. Wenn Sie ein Video in einem anderen Format hochladen, kann die Konvertierung in MP4 zu einer Größe von über 5 GB führen. Bitte laden Sie ein Video im MP4-Format mit weniger als 5 GB hoch, um diese Meldung zu vermeiden.',
    video_8:"",

    title_9: 'Was bedeuten die Fehlermeldungen und wie kann ich sie beheben?',
    desc_9:'<span style="color:rgb(230, 118, 117);">Testnutzung überschritten</span>: Bitte kaufen Sie Minuten oder abonnieren Sie, um den Dienst weiterhin zu nutzen.<br><span style="color:rgb(230, 118, 117);">Nicht genügend Minuten</span>: Kaufen Sie mehr Minuten oder abonnieren Sie und versuchen Sie es erneut.<br><span style="color:rgb(230, 118, 117);">YouTube-Link fehlgeschlagen, bitte versuchen Sie es erneut</span>: Der häufigste Grund ist, dass das bereitgestellte YouTube-Video kürzlich hochgeladen wurde. Bitte warten Sie mindestens 24 Stunden, bevor Sie erneut versuchen, zu transkribieren.',
    video_9:"",

    title_10: 'Wie integriere ich Taption mit Zoom?',
    desc_10:'Bitte sehen Sie sich das <a href="https://www.taption.com/blog/en/zoom-tutorial-en" rel="noreferrer" target="_blank">Schritt-für-Schritt-Tutorial</a> an',
    video_10:"",

    title_11: 'Ich habe weitere Fragen, was soll ich tun?',
    desc_11:'Bitte kontaktieren Sie uns unter <span style="color:rgb(0, 209, 178);">help@taption.com</span>',
    video_11:"",

    zoom_title:'Schritt-für-Schritt Taption + Zoom Integration',
    zoom_install_desc:'Sie können jetzt Ihr Zoom-Konto mit Ihrem Taption-Konto verbinden. Nach diesem Tutorial können Sie Zoom-Aufnahmen direkt von Taption transkribieren.',
    zoom_step_1:'Klicken Sie nach dem Anmelden auf „Hochladen“',
    zoom_step_2:'Wählen Sie „Zoom“ im Popup-Dialog',
    zoom_step_3:'Melden Sie sich mit Ihrem Zoom-Konto an und autorisieren Sie den Zugriff',
    zoom_step_4:'Wählen Sie Ihre Aufnahme aus der Tabelle aus',
    zoom_step_5:'Konfigurieren Sie Ihre Einstellungen, dann können Sie hochladen und transkribieren',
    zoom_uninstall_title:'Taption + Zoom Integration deinstallieren',
    zoom_uninstall_desc:'Um Zoom von Taption zu deinstallieren, gehen Sie zu <strong>Konto</strong> → <strong>Apps</strong> → <strong>Deinstallieren</strong><br>Wenn Sie weitere Fragen haben, senden Sie uns bitte eine E-Mail an <span style="color:rgb(0, 209, 178);">help@taption.com</span>',

    webex_title:'Schritt-für-Schritt Taption + Webex Integration',
    webex_install_desc:'Sie können jetzt Ihr Webex-Konto mit Ihrem Taption-Konto verbinden. Nach diesem Tutorial können Sie Webex-Aufnahmen direkt von Taption transkribieren.',
    webex_step_1:'Klicken Sie nach dem Anmelden auf „Hochladen“',
    webex_step_2:'Wählen Sie „Webex“ im Popup-Dialog',
    webex_step_3:'Melden Sie sich mit Ihrem Webex-Konto an und autorisieren Sie den Zugriff',
    webex_step_4:'Wählen Sie Ihre Aufnahme aus der Tabelle aus',
    webex_step_5:'Konfigurieren Sie Ihre Einstellungen, dann können Sie hochladen und transkribieren',
    webex_uninstall_title:'Taption + Webex Integration deinstallieren',
    webex_uninstall_desc:'Um Webex von Taption zu deinstallieren, gehen Sie zu <strong>Konto</strong> → <strong>Apps</strong> → <strong>Deinstallieren</strong><br>Wenn Sie weitere Fragen haben, senden Sie uns bitte eine E-Mail an <span style="color:rgb(0, 209, 178);">help@taption.com</span>',
}
