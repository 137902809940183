module.exports = {
    convert_to_text_title:"Penandaan Pembicara untuk Video",
    convert_to_text_desc:"Secara otomatis memberi label pembicara untuk video dan audio Anda.",
    start_your_trial:"Mulai uji coba gratis Anda!",
    hero_img:"./04_English.webp",

    step_one:"Unggah file Anda",
    step_one_img:"./select_source_en.webp",
    step_one_desc:"Unggah file Anda dari salah satu sumber berikut: Perangkat, YouTube, Google Drive, atau Zoom.",
    
    step_two:"Konfigurasi untuk penandaan pembicara",
    step_two_img:"./speaker_step2_en.webp",
    step_two_desc:"Pastikan untuk memilih bahasa yang sesuai dengan video saat mengunggah dan pilih segmen setiap bagian untuk \"pembicara\".",

    step_three:"Selesai",
    step_three_img:"./speaker_step3_en.webp",
    step_three_desc:"Setelah transkripsi selesai, Anda dapat melakukan penyesuaian pada pembicara di platform pengeditan.",

    feature_tutorial_title:'Tambahkan penandaan pembicara dengan mudah ke video Anda',
    feature_tutorial_desc:'Apakah Anda siap untuk memberikan kejelasan pada file audio atau video Anda? Fitur penandaan pembicara otomatis kami membuat penonton Anda dengan mudah mengenali dan memberi label pembicara yang berbeda. Ini membantu menjaga konten Anda tetap rapi dan mudah diikuti setiap saat.<br><br>Ingin tahu caranya? Kami memiliki video tutorial yang membantu membimbing Anda melalui setiap tahap.',
    feature_tutorial_poster_url:'./speaker_labeling_tutorial_poster_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/l5xkhJZy5ww?autoplay=1',

    feature_extra_title:"Mengapa mentranskripsikan rapat dan konferensi web masuk akal",
    feature_extra_desc:"Anda mungkin berpikir transkripsi rapat hanya untuk pencatatan. Pikirkan lagi. Mari kita telusuri berbagai cara transkripsi dapat meningkatkan konten Anda: <ul><li><strong>Retensi Informasi:</strong> Jangan lewatkan satu detail pun. Tangkap semua detail rapat Anda untuk memastikan tidak ada yang terlewat.</li><li><strong>Optimisasi SEO:</strong> Sertakan kata kunci yang relevan ke dalam transkripsi Anda dan saksikan peringkat SEO Anda meningkat.</li><li><strong>Aksesibilitas Konten:</strong> Dengan transkripsi otomatis, Anda tidak hanya mencatat kata-kata; Anda membuatnya dapat diakses oleh audiens yang lebih luas - termasuk mereka yang memiliki gangguan pendengaran.</li><li><strong>ROI:</strong> Tingkatkan Pengembalian Investasi Anda dengan menyediakan peserta alternatif cara untuk meninjau ide atau produk yang dibahas, yang berpotensi mengarah pada konversi.</li></ul>Jadi, apa yang Anda dapatkan? Anda meningkatkan keterlibatan audiens dan membuat konten Anda lebih inklusif. Sudah waktunya memanfaatkan manfaat ini dan membuat video serta rapat Anda menjadi profesional dan bermanfaat."
}
