module.exports = {
    convert_to_text_title_meta:"Ubah Video Menjadi Teks - Software Transkripsi AI Online",
    convert_to_text_desc_meta:"Secara otomatis mengonversi video MP4, video YouTube, dan audio MP3 menjadi teks online. Mendukung lebih dari 40 bahasa, menyediakan subtitle multibahasa, dan fitur penandaan pembicara otomatis. Daftar untuk uji coba gratis hari ini!",
    convert_to_text_title:"Ubah video menjadi teks",
    convert_to_text_desc:"Secara otomatis mengonversi video MP4 atau audio MP3 Anda menjadi teks. Mendukung subtitle multibahasa, format otomatis, dan penandaan pembicara.",
    start_your_trial:"Mulai uji coba gratis Anda!",
    hero_img:"./01_English.webp",

    three_step_title:"Langkah-langkah Transkripsi",
    step_one:"Unggah file",
    step_one_img:"./select_source_en.webp",
    step_one_desc:"Pilih dari perangkat, Youtube, Google Drive, atau Zoom untuk diubah menjadi teks.",
    
    step_two:"Pilih metode",
    step_two_img:"./select_split_en.webp",
    step_two_desc:"Pilih metode transkripsi Anda dari berikut: 1) Transkripsi Otomatis 2) Impor file teks 3) Manual, kemudian pilih Segmen setiap bagian untuk \"artikel\".",

    step_three:"Ekspor",
    step_three_img:"./select_export_en.webp",
    step_three_desc:"Setelah diedit di platform, ekspor (srt, txt, pdf, mp4) dengan mengklik tombol ekspor di kanan atas.",

    feature_tutorial_title:'Konversi video-ke-teks dengan mudah',
    feature_tutorial_desc:'Taption menyediakan empat metode berbeda untuk menambahkan subtitle ke video Anda: <span class="bullet-point">•</span>Transkripsi Otomatis <span class="bullet-point">•</span>Impor SRT <span class="bullet-point">•</span>Impor TXT <span class="bullet-point">•</span>Entri Manual<br><br>Platform pengeditan kami berfungsi sebagai alat intuitif untuk konversi audio-ke-teks, pidato-ke-teks, dan video-ke-teks, membantu menyederhanakan tugas transkripsi Anda. Bagi Anda yang menjangkau audiens global, kami juga menawarkan dukungan untuk subtitle multibahasa. Pastikan untuk menonton tutorial kami, di mana kami menjelaskan langkah-langkah transkripsi ini dengan cara yang sederhana, meningkatkan pemahaman Anda tentang cara mengelola kebutuhan video-ke-teks Anda dengan efisien.',
    feature_tutorial_poster_url:'./add_subtitles_tutorial_poster_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/KvyaH-QU9is?autoplay=1',

    feature_extra_title:"Banyak manfaat dari konversi video-ke-teks",
    feature_extra_desc:"Konversi video-ke-teks, yang mencakup proses audio-ke-teks dan pidato-ke-teks, melampaui fungsi dasar pembuatan subtitle. Layanan ini menyediakan rangkaian manfaat komprehensif yang dapat meningkatkan strategi konten Anda secara signifikan melalui transkripsi. Pertimbangkan keuntungan utama berikut dari memanfaatkan konversi video-ke-teks, audio-ke-teks, dan pidato-ke-teks dalam operasi Anda: <ul><li><strong>Media Dapat Dicari:</strong> File media Anda akan sepenuhnya dapat dicari, dan ini akan meningkatkan visibilitas konten Anda. </li><li><strong>Peninjauan dan Modifikasi Konten yang Efisien:</strong> Anda mempercepat proses peninjauan dan pengeditan tanpa mengorbankan kualitas. </li><li><strong>Terjemahan Mudah:</strong> Anda dapat menangani banyak bahasa dengan mudah dan memperluas jangkauan global Anda dengan cara ini. </li><li><strong>Optimisasi SEO:</strong> Anda membuat media Anda dapat dicari, yang juga berarti Anda meningkatkan performa SEO situs Anda. </li><li><strong>Kepatuhan ADA:</strong> Konten Anda akan dapat diakses oleh semua orang dan memenuhi standar kepatuhan ADA. </li></ul>",

    feature_why_image: "./translate_step1_en.webp",
    feature_what_title:'5 Langkah untuk mengonversi audio ke teks',
    feature_what_desc: "<ul>\n  <li>Daftar akun baru atau masuk dengan Gmail: Buka <a href=\"https://app.taption.com/login\">Halaman login Taption</a>.</li>\n  <li>Klik tombol \"Unggah\" dan pilih sumber media, seperti mengunggah dari komputer, YouTube, Google Drive, Zoom, atau menempelkan tautan media.</li>\n  <li>Pilih \"Bahasa\" dan \"Metode Segmentasi\" yang diperlukan untuk transkripsi.</li>\n  <li>Setelah diunggah, file akan secara otomatis diubah menjadi teks, dan Anda dapat melanjutkan ke platform pengeditan untuk pengeditan lebih lanjut.</li>\n  <li>Di platform pengeditan, Anda dapat menerjemahkan, menggunakan analisis AI, dan akhirnya mengekspor dokumen atau menyematkan subtitle ke dalam file video.</li>\n</ul>"
}
