module.exports = {
    convert_to_text_title:"Transcripción a etiquetado de oradores",
    convert_to_text_desc:"Añade automáticamente el etiquetado de oradores a tus transcripciones existentes",
    start_your_trial:"¡Pruébalo ahora!",
    hero_img:"./10_English.webp",

    step_one:"Sube tu archivo multimedia",
    step_one_img:"./select_source_en.webp",
    step_one_desc:"Selecciona el archivo de audio o vídeo que coincide con la transcripción que tienes",
    
    step_two:"Sube tu archivo de transcripción",
    step_two_img:"./transcripts_subtitles_step02_en.webp",
    step_two_desc:"Selecciona <span style=\"color:#3ccebd\"><strong>Importar archivo de texto</strong></span> para <strong>Método de Transcripción</strong> y <span style=\"color:#3ccebd\"><strong>Etiquetado de oradores</strong></span> para <strong>Selecciona tu formato de salida</strong>",

    step_three:"Edita y exporta",
    step_three_img:"./export_srt_step03_en.webp",
    step_three_desc:"Una vez completo, accede a la plataforma de edición para actualizar los nombres de los oradores o traducir. ¡Exporta cuando esté listo!",

    feature_tutorial_title:'Etiquetado de oradores automatizado para mayor claridad',
    feature_tutorial_desc:'Se acabaron los días de confusión sobre quién dijo qué en tu contenido de audio. Con Taption, identificar voces en pódcast, entrevistas o discusiones grupales no podría ser más fácil, gracias a nuestro software de reconocimiento de oradores. Simplemente sube tu transcripción y deja que nuestra tecnología de detección de oradores con IA haga el trabajo pesado. <br><br>¿Qué hace exactamente nuestra tecnología? Identifica y etiqueta con facilidad las contribuciones de cada orador. Al usar nuestra identificación automatizada de oradores, garantizas una claridad perfecta en todos tus vídeos, de modo que tu audiencia nunca se confunda sobre quién está hablando o tenga dificultades para seguir la conversación.',
    feature_tutorial_poster_url:'./transcripts_to_subtitles_thumbnail_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/3DNDiW8jiDU?si=ZWqZnGBTdjUmVU0_&autoplay=1',

    feature_extra_title:"Las ventajas del etiquetado claro de oradores",
    feature_extra_desc:"En discusiones con múltiples voces, saber quién está hablando es clave: da forma a la experiencia del oyente. Con Taption, obtienes:<br><ul><li><strong>Diálogo transparente:</strong> Los oyentes pueden seguir fácilmente la conversación, ya que cada voz está claramente etiquetada.</li><li><strong>Transcripciones mejoradas:</strong> Tu contenido deja una impresión más profesional que mejora la imagen de tu empresa.</li><li><strong>Ahorro de tiempo:</strong> Imagina las horas que puedes recuperar con una herramienta de etiquetado de audio que identifica a los oradores correctamente a la primera.</li></ul><br>Experimenta los beneficios de la identificación profesional de oradores con las avanzadas herramientas de Taption. Gracias a nuestro etiquetado de transcripciones multiorador y la solución de atribución de voz, puedes estar seguro de que tus servicios de transcripción de reuniones y etiquetado de transcripciones de entrevistas cumplen con los más altos estándares de precisión y detalle."
}
