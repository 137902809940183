module.exports = {
    monthly_free_minutes:'每月赠送分钟(仅在订阅状态下有效)',
    monthly_free_minutes_tooltip: '此分钟没用完可以持续累积，有效到取消订阅为止。<br>相较于另外购买的分钟数，会优先使用此分钟。',
    additional_seat: '帐号共享用户',
    additional_seat_tooltip: '邀请使用者共享帐户，并授予他们对会分钟数、文字档、影片和功能的存取权，权限可自订。 ',
    minutes_price:'影片分钟购买价格',
    minutes_price_tooltip: '另购买分钟不会因取消订阅而失效。',
    monthly_free_ai_credits:'AI 点数<a href="https://www.taption.com/ai-analysis/zh-CN" target="__blank" style="color:#2FC59F;padding:0px 5px;font-size:12px;">详细资讯</a>',
    monthly_free_ai_credits_tooltip: '订阅后每月将自动补满AI点数，可随时加购更多点数。 <br>「高级订阅」每月最多补到 {0} 点<br>「大量订阅」每月最多补到 {1} 点',
    monthly_free_ai_credits_desc:'每月补至{0}点',
    max_file_length:'上传影片最大长度',
    max_file_size:'上传档案大小限制',
    max_label_number:'影片标签分类上限',
    automated_transcription: '自动将影音档转成文字',
    editing_platform_unlimited: '编辑平台使用无时间限制',
    language_supported: '支援40多种语言',
    highlight_text: '精准时间对照编辑器',
    translate: '50多種語言翻译',
    translate_basic_tooltip: '翻译会扣除分钟',
    translate_premium_tooltip: '翻译会扣除分钟',
    translate_corp_tooltip: '翻译会扣除分钟',
    personal_dictionary: '个人AI辞典',
    allow_commercial: '可供商业相关之用途',
    mp4_multi_language_export_tooltip:'支援50+语言的双语字幕，您可以进入编辑页面后从右上角的『翻译』选单翻译此影片来增加更多双语字幕的选项。',
    mp4_multi_language_export: '双语字幕崁入影片导出',
    mp4_multi_language_export_basic_tooltip:'未订阅导出影片会扣除分钟',
    mp4_audio_export: '上传录音导出客制化背景的字幕影片',
    mp4_audio_export_tooltip: '将录音档以影片形式导出，可客制化影片背景及将（双语）字幕嵌入影片。 ',
    mp4_audio_export_basic_tooltip: '影片背景客制化只开放给订阅用户，未订阅用户会以黑色背景导出。 ',
    api_integration:'API客制化串连',
    api_integration_tooltip: '请与我们联系help@taption.com来进一步了解详情',
    api_integration_basic_tooltip: '请与我们联系help@taption.com来进一步了解详情',
    api_integration_premium_tooltip: '请与我们联系help@taption.com来进一步了解详情',
    api_integration_corp_tooltip: '请与我们联系help@taption.com来进一步了解详情',
    custom_payments: '客制化付款、开立纸本发票',
    custom_payments_tooltip: '弹性的付款周期和付款方式，包括转帐付款，开立纸本统编发票(付款周期必须为年缴且使用转帐付款）。请与我们联系help@taption.com来进一步了解详情。 ',
    advanced_training: '进阶使用者培训',
    advanced_training_tooltip: '需要协助让所有人更了解平台吗？我们可以提供帮助。 ',
    high_definition:'原画质影片',
    high_definition_tooltip: '以原画质影片编辑和分享',
    mp4_export: '字幕崁入影片导出',
    mp4_export_basic_tooltip: '未订阅导出影片会扣除分钟',
    editable_transcript_sharing: '分享给其他用户编辑',
    dark_mode: '夜间模式',
    video_editing: '用字幕剪辑影片片段',
    video_cutting:'影片剪辑',
    multi_upload:'影片批量上传',
    invoice_company:'发票显示公司名称',
    invoice_taxid:'发票显示统一编号',
    share_minutes:'用户共享分钟',
    share_minutes_tooltip:'加入用户来共享此帐号的分钟以及影片',
    permission_users:'针对每个加入用户提供7种权限管理<a href="https://www.taption.com/collaborate/zh-CN" target="__blank" style="color:#2FC59F;padding:0px 5px;font-size:12px;">详细资讯</a>',
    permission_users_tooltip:'对每个用户的翻译，上传，删除，导出，编辑等权限做限制管理',
    max_char:'每段最大字符',
    max_char_tooltip:'转译后的文字依据此数值来分段，<br>并且每段不会超过此字符。',
    direct_link:'使用多种影音档网址连接直接转译(免上传)',
    direct_link_tooltip:'支援以下：<br>• Facebook影片连结<br>• Instagram影片连结<br>• X影片连结<br>• Tiktok影片连结<br>• 云端影音URL连结<br>• Dropbox分享连结<br>• One Drive分享连结<br>• Google Drive分享连结<br><br>',
    auto_extent_subtitle:'自动延长每段字幕秒数',
    auto_extent_subtitle_tooltip:'会将每段延长提供的秒数，<br/>这可以避免间隔较短的字幕转换时的「闪烁」，<br/>让字幕与字幕间的转换更为流畅',
    clipped_video:'撷取影音片段',
    clipped_video_tooltip:'从影音档中撷取片段来辨识文字，更有效地利用分钟数<br>支援Youtube连结,mp3及mp4类型影音档',
    custom_format:'客制化格式导出',
    custom_format_tooltip:'当选择以TXT或PDF形式下载存档时，可依照个人需求来编排每一段时间，文字，人名及备注的输出格式。',
    editing_font_size:'调整编辑平台字体大小',

    low_cost:'价格亲民',
      title_basic:'影片长度计价',
      basic_cost_usd:'8',
      basic_cost_ntd:'(50 RMB)',
      unit:'美金',
      hour:'小时',
      basic_feature5:'充值<span style="color: #00d1b2;">60分钟</span>',
      basic_feature1:'自动将影音档转成文字',
      basic_feature2:'支援40多种语言',
      basic_feature3:'精准时间对照编辑器',
      basic_feature4:'50多种语言翻译',
      basic_feature7:'个人AI辞典',
      basic_feature6:'可供商业相关之用途',
      basic_feature8:'双语字幕崁入影片导出',


      title_premium:'高级订阅',
      premium_cost_usd:'$12',
      premium_cost_ntd:'(80 RMB)',
      premium_basic_cost_usd:'$6',
      month:'月',
      
      premium_before:'<span class="landing-arrow-left">&#10229;</span>所有『影片长度计价』功能',
      premium_feature1:'每个月赠送<span style="color: #00d1b2;">120分钟</span><br><span style="color:#797676;font-size:14px;font-weight: 100;">(可跨月累积，有效至订阅取消为止)</span>',
      premium_feature1_tooltip:'此分钟没用完可以持续累积，有效直到取消订阅为止。 <br>相较于另外购买的分钟数，会优先使用此分钟。 <br>另购买分钟不会因取消订阅而失效。 ',
      premium_feature2:'如需更多分钟, 以7.5折优惠价格购买',
      premium_feature3:'字幕崁入影片不扣分钟，无限制导出',
      premium_feature4:'分享给其他用户编辑',
      premium_feature5:'录音档转为有字幕的影片档',
      premium_feature6:'影片剪辑 (字幕自动调整)',

      title_corp:'大量订阅',
      bulk_cost_usd:'$69',
      bulk_cost_ntd:'(320 RMB)',
      bulk_basic_cost_usd:'$3',
      bulk_before:'<span class="landing-arrow-left">&#10229;</span>所有『影片长度计价』和『高级订阅』功能',
       bulk_feature2:'如需更多分钟, 以3.7折优惠价格购买',
      premium_feature1_bulk:'每个月赠送<span style="color: #00d1b2;">1000分钟</span><br><span style="color:#797676;font-size:14px;font-weight: 100;">(可跨月累积，有效至订阅取消为止)</span>',
      bulk_feature1:'提供公司名称以及统一编号的发票下载',
       bulk_feature3:'加入用户共享分钟',
       bulk_feature4:'针对加入用户提供6种权限管理',

      go_to_price:'了解更多',
      
      choose_plan:'选择方案',
      annually:'年缴制',
      monthly:'月缴制',
      annually_save:'年缴省下{0}%的费用',
      annually_deduct:'每年自动扣款${0}'
}