module.exports = {
    convert_to_text_title_meta: "Conversión automática de texto a subtítulos SRT con marcas de tiempo",
    convert_to_text_desc_meta: "Sube tu transcripción existente y utiliza nuestro generador de subtítulos en línea para añadir marcas de tiempo automáticamente. Convierte TXT a SRT rápidamente. ¡Pruébalo gratis, regístrate ahora para empezar!",
    convert_to_text_title: "Conversión automática de TXT a subtítulos SRT",
    convert_to_text_desc: "Sube tu transcripción existente y utiliza nuestro generador de subtítulos para añadir marcas de tiempo y dar formato automáticamente. Convierte TXT a SRT rápidamente.",
    start_your_trial: "¡Pruébalo ahora!",
    hero_img:"./09_English.webp",

    step_one: "Selecciona el archivo de audio/vídeo para tu transcripción",
    step_one_img: "./select_source_en.webp",
    step_one_desc: "Primero, elige el archivo de audio/vídeo que coincide con tu transcripción. La transcripción y el idioma del vídeo deben ser los mismos.",

    step_two: "Importa tu transcripción",
    step_two_img: "./transcripts_subtitles_step02_en.webp",
    step_two_desc: "En la ventana de <strong>Método de Transcripción</strong>, selecciona <span style=\"color:#3ccebd\">Importar archivo de texto</span>. Para <strong>Selecciona tu formato de salida</strong>, elige <span style=\"color:#3ccebd\">Captions/Subtítulos</span>.",

    step_three: "Edita y exporta",
    step_three_img: "./export_srt_step03_en.webp",
    step_three_desc: "Ingresa a la plataforma de edición para editar los subtítulos y exportar.",

    feature_tutorial_title: 'Convierte tu texto a subtítulos',
    feature_tutorial_desc: 'Ya sea que tengas una transcripción, un artículo u otro contenido de texto, simplemente súbelo a la plataforma de Taption para utilizar rápidamente nuestro generador de subtítulos. Convierte automáticamente tu texto en subtítulos compatibles con el vídeo, completando la conversión de TXT a SRT. Con nuestro generador de subtítulos, puedes evitar la entrada manual de subtítulos y el ajuste de línea de tiempo que consume tiempo. Nuestra herramienta ofrece alta precisión y consistencia. Además, puedes traducir fácilmente los subtítulos a más de 40 idiomas, satisfaciendo las diversas necesidades de las audiencias globales.',
    feature_tutorial_poster_url: './transcripts_to_subtitles_thumbnail_en.webp',
    feature_tutorial_youtube: 'https://www.youtube.com/embed/cMc71uumga4?si=Asfp-N2yjNahp_M5&autoplay=1',

    feature_extra_title: "Aplicaciones integradas de la conversión de transcripción a subtítulos",
    feature_extra_desc: "<ul><li><strong>Creación de subtítulos de vídeo:</strong> Cuando tienes contenido de texto para tu vídeo, como guiones o diálogos, puedes convertir rápidamente estos textos en subtítulos de vídeo.</li><li><strong>Materiales educativos y de capacitación:</strong> Para educadores o formadores, puedes convertir el contenido educativo en subtítulos para facilitar la comprensión y toma de notas de los estudiantes. Esto es especialmente útil para cursos en línea o programas de capacitación.</li><li><strong>Mejorar SEO:</strong> Convertir contenido de vídeo en subtítulos de texto ayuda a que los motores de búsqueda indexen tu contenido, aumentando la visibilidad de tu sitio web o vídeo.</li></ul>",

    feature_what_title: "Preguntas frecuentes",
    feature_what_desc: "<ul><li><h2>¿Puede el archivo TXT subido ser formateado automáticamente y tener marcas de tiempo añadidas para cumplir con diversas necesidades?</h2><br>Sí, puede. Ofrecemos tres métodos de formato:<ul><li>Segmentación automática de subtítulos: Utiliza la función de segmentación del generador de subtítulos para determinar los mejores puntos de segmentación según el contenido del vídeo.</li><li>Segmentación por identificación de oradores: Adecuado para registros de reuniones o pódcast, segmentando según diferentes oradores.</li><li>Segmentación basada en el formato original del archivo TXT: Segmenta de acuerdo con el formato existente en el archivo de texto proporcionado.</li></ul><br><br></li><li><h2>No tengo un archivo TXT. ¿El sistema puede generar automáticamente una transcripción o subtítulos para mí?</h2><br>Sí, puede. Al subir tu archivo de vídeo, elige la opción <strong>Transcripción automática</strong> en <strong>Método de Transcripción</strong>. Nuestro generador de subtítulos con IA convertirá automáticamente el contenido de tu vídeo en subtítulos. Para más información, visita <a href=\"https://www.taption.com/add-subtitles\">Generador de Subtítulos con IA</a> o <a href=\"https://www.taption.com/convert-to-text\">Convertir vídeo a texto</a>.<br><br></li><li><h2>Después de añadir marcas de tiempo al archivo TXT, ¿puede traducirse a otros idiomas?</h2><br>Sí, puede. Después de añadir marcas de tiempo al archivo TXT proporcionado, puedes elegir traducir a más de 40 idiomas desde la parte superior derecha de la plataforma de edición. Esto incluye inglés a japonés, inglés a vietnamita, inglés a chino, y más. Para más información sobre traducción, por favor consulta <a href=\"https://www.taption.com/translate\">Traducción automática de vídeo</a>.</li></ul>"
}
