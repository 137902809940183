module.exports = {
    monthly_free_minutes:'Monatliche kostenlose Minuten (Gültig nur bei Abonnement)',
    monthly_free_minutes_tooltip: 'Wenn die monatlichen Minuten nicht verbraucht werden, werden sie auf den nächsten Monat übertragen und sind bis zur Kündigung des Abonnements gültig.<br>Diese monatlichen Minuten werden vor den zusätzlich gekauften Minuten verwendet.',
    additional_seat:'Zusätzliche Plätze',
    additional_seat_tooltip:'Aktivieren Sie das Teilen des Kontos, indem Sie Benutzer einladen und ihnen Zugriff auf Minuten, Videos und Funktionen mit anpassbaren Berechtigungen gewähren.',
    monthly_free_ai_credits:'KI-Guthaben <a href="https://www.taption.com/ai-analysis" target="__blank" style="color:#2FC59F;padding:0px 5px;font-size:12px;">mehr erfahren</a>',
    monthly_free_ai_credits_tooltip: 'KI-Guthaben füllen sich bis zu {0} monatlich für Premium-Abonnements auf.<br>KI-Guthaben füllen sich bis zu {1} monatlich für Bulk-Abonnements auf.<br>Zusätzliche KI-Guthaben können jederzeit gekauft werden.',
    monthly_free_ai_credits_desc:'Bis zu {0} Guthaben monatlich',
    minutes_price:'Preis für zusätzliche Minuten',
    minutes_price_tooltip: 'Die zusätzlich gekauften Minuten verfallen nicht nach Kündigung des Abonnements.',
    max_file_length:'Maximale Videolänge',
    max_file_size:'Maximale Upload-Dateigröße',
    max_label_number:'Maximale Anzahl von Labels',
    automated_transcription: 'Automatische Transkription',
    editing_platform_unlimited: 'Keine Zeitbegrenzung für die Nutzung der Bearbeitungsplattform',
    language_supported: 'Über 40 unterstützte Sprachen',
    highlight_text: 'Hervorgehobener Texteditor',
    translate: 'In über 50 Sprachen übersetzen',
    translate_basic_tooltip: 'Die Übersetzung zieht zusätzliche Minuten ab',
    translate_premium_tooltip: 'Die Übersetzung zieht zusätzliche Minuten ab',
    translate_corp_tooltip: 'Die Übersetzung zieht zusätzliche Minuten ab',
    personal_dictionary: 'Persönliches KI-Wörterbuch',
    allow_commercial: 'Für kommerzielle Nutzung zugelassen',

    mp4_multi_language_export_tooltip:'Unterstützt beliebige 2 Sprachuntertitel aus den über 50 verfügbaren Übersetzungen. Sie können weitere Übersetzungen aus dem Dropdown-Menü „Übersetzen“ oben rechts hinzufügen, sobald Sie auf die Transkriptionsseite gehen.',
    mp4_multi_language_export: 'Unterstützt eingebrannte mehrsprachige Untertitel',
    mp4_multi_language_export_basic_tooltip:'Das Exportieren des Videos zieht zusätzliche Minuten für Nicht-Abonnementbenutzer ab.',

    mp4_audio_export: 'Audio in Video mit Untertiteln und benutzerdefiniertem Hintergrund konvertieren',
    mp4_audio_export_tooltip: 'Sie können Ihre Audiodatei in ein Video mit eingebrannten Untertiteln und benutzerdefiniertem Hintergrund konvertieren und exportieren.',
    mp4_audio_export_basic_tooltip: 'Benutzer des Standardplans können nur Videos mit schwarzem Hintergrund exportieren.',

    api_integration:'Benutzerdefinierte API-Integration',
    api_integration_tooltip: 'Bitte kontaktieren Sie uns unter help@taption.com für weitere Informationen.',
    api_integration_basic_tooltip: 'Bitte kontaktieren Sie uns unter help@taption.com für weitere Informationen.',
    api_integration_premium_tooltip: 'Bitte kontaktieren Sie uns unter help@taption.com für weitere Informationen.',
    api_integration_corp_tooltip: 'Bitte kontaktieren Sie uns unter help@taption.com für weitere Informationen.',

    custom_payments: 'Benutzerdefinierte Rechnungsoptionen',
    custom_payments_tooltip: 'Flexible Zahlungsmöglichkeiten, einschließlich Überweisung/Scheck. Bitte kontaktieren Sie uns unter help@taption.com für weitere Informationen.',

    advanced_training: 'Erweitertes Training für Benutzer',
    advanced_training_tooltip: 'Brauchen Sie Hilfe bei der Einarbeitung? Wir können helfen.',

    high_definition:'Originale Videoqualität',
    high_definition_tooltip: 'Bearbeiten und teilen Sie Ihr Video in seiner originalen Qualität.',
    
    mp4_export: 'Unbegrenzter Export von eingebrannten Untertiteln',
    mp4_export_basic_tooltip: 'Das Exportieren des Videos zieht zusätzliche Minuten für Nicht-Abonnementbenutzer ab.',
    editable_transcript_sharing: 'Teilen von bearbeitbaren Transkripten',
    dark_mode: 'Dark Mode',
    video_editing: 'Video durch Textbearbeitung bearbeiten',
    video_cutting:'Video schneiden',
    multi_upload:'Mehrere Dateien gleichzeitig transkribieren',
    invoice_company:'Rechnung mit benutzerdefiniertem Titel',
    invoice_taxid:'Rechnung mit Steuernummer',
    share_minutes:'Multi-User-Zugriff',
    share_minutes_tooltip:'Fügen Sie Benutzer zum Konto hinzu, um Minuten und Transkripte zu teilen.',
    permission_users:'Benutzerberechtigungsmanagement <a href="https://www.taption.com/collaborate" target="__blank" style="color:#2FC59F;padding:0px 5px;font-size:12px;">mehr erfahren</a>',
    permission_users_tooltip:'Beschränken Sie hinzugefügte Konten auf das Übersetzen, Hochladen, Löschen, Exportieren und Bearbeiten von Transkripten.',
    max_char:'Voreingestellte maximale Zeichenanzahl für Untertitel',
    max_char_tooltip:'Die maximale Anzahl an Zeichen, die gleichzeitig in den Untertiteln angezeigt werden.<br>Das Transkript wird gemäß diesem Schwellenwert in Abschnitte unterteilt.',
    direct_link:'Links als Quellen beim Transkribieren akzeptieren',
    direct_link_tooltip:'Unterstützt folgende:<br>• Facebook-Video-Link<br>• Instagram-Video-Link<br>• X (ehemals Twitter) Video-Link<br>• TikTok-Video-Link<br>• Medien-Datei-URL-Link<br>• Dropbox-Freigabe-Link<br>• OneDrive-Freigabe-Link<br>• Google Drive-Freigabe-Link<br><br>',
    auto_extent_subtitle:'Dauer für jeden Untertitel automatisch verlängern',
    auto_extent_subtitle_tooltip:'Verlängern Sie die Dauer aller Untertitel um eine bestimmte Zeit. <br/>So kann das „Flackern“ der Untertitel zwischen kurzen Pausen vermieden werden, was den Übergang glättet.',
    clipped_video:'Innerhalb des Zeitbereichs transkribieren',
    clipped_video_tooltip:'Extrahieren und transkribieren Sie innerhalb des ausgewählten Zeitbereichs der Mediendatei.<br>Unterstützt YouTube-, mp3- und mp4-Medientypen.',
    custom_format:'Benutzerdefiniertes Download-Format',
    custom_format_tooltip:'Beim Exportieren Ihres Transkripts als txt oder pdf ermöglicht Ihnen unser flexibler Vorlageneditor den Download im gewünschten Format.',
    editing_font_size:'Schriftgröße auf der Bearbeitungsplattform anpassen',

    low_cost:'Günstige Preise.',
    title_basic:'Pay as you go',
    basic_cost_usd:'$8',
    basic_cost_ntd:'(240 NTD)',
    hour:'Stunde',
    basic_feature5:'Guthaben <span style="color: #00d1b2">60 Minuten</span>',
    basic_feature1:'Automatische Transkription',
    basic_feature2:'Über 40 Sprachen unterstützt',
    basic_feature3:'Hervorgehobener Texteditor',
    basic_feature4:'In über 50 Sprachen übersetzen',
    basic_feature7:'Persönliches KI-Wörterbuch',
    basic_feature6:'Für kommerzielle Nutzung zugelassen',
    basic_feature8:'Unterstützt eingebrannte mehrsprachige Untertitel',
    title_premium:'Premium-Abonnement',
    premium_cost_usd:'$12',
    premium_cost_ntd:'(360 NTD)',
    premium_basic_cost_usd:'$6',
    month:'Monat',
    premium_before:'<span class="landing-arrow-left">&#10229;</span>Alle Funktionen in「Pay as you go」',
    premium_feature1:'<span style="color: #00d1b2">120 Minuten</span> Guthaben jeden Monat.<br><span style="color:#797676;font-size:14px;font-weight: 100">(Übertragbare Minuten, gültig bis Abonnement gekündigt)</span>',
    premium_feature1_tooltip:'Wenn die monatlichen Minuten nicht verbraucht werden, werden sie auf den nächsten Monat übertragen und sind bis zur Kündigung des Abonnements gültig. <br>Diese monatlichen Minuten werden vor den zusätzlich gekauften Minuten verwendet.<br>Die zusätzlich gekauften Minuten verfallen nicht nach Kündigung des Abonnements.',
    premium_feature2:'25% Rabatt auf stundenweise Käufe',
    premium_feature3:'Unbegrenzter kostenloser Export von eingebrannten Untertiteln',
    premium_feature4:'Teilen von bearbeitbaren Transkripten',
    premium_feature5:'Exportieren Sie Ihre Audioaufnahmen als Videos mit Untertiteln',
    premium_feature6:'Videoschnitt (Exportierte Transkripte/Untertitel werden automatisch angepasst)',
    title_corp:'Bulk-Abonnement',
    bulk_cost_usd:'$69',
    bulk_cost_ntd:'(2070 NTD)',
    bulk_basic_cost_usd:'$4',
    bulk_before:'<span class="landing-arrow-left">&#10229;</span>Alle Funktionen in「Pay as you go」und「Premium-Abonnement」',
    bulk_feature2:'63% Rabatt auf stundenweise Käufe',
    premium_feature1_bulk:'<span style="color: #00d1b2">1000 Minuten</span> Guthaben jeden Monat.<br><span style="color:#797676;font-size:14px;font-weight: 100">(Übertragbare Minuten, gültig bis Abonnement gekündigt)</span>',
    bulk_feature1:'Rechnungsdownload mit Ihrem Institutionsnamen und Ihrer Steuernummer',
    bulk_feature3:'Teilen Sie Minuten mit Benutzern',
    bulk_feature4:'6 verschiedene konfigurierbare Berechtigungen',
    go_to_price:'Weitere Details',

    choose_plan:'Wählen Sie einen Plan',
    annually:'Jährlich',
    monthly:'Monatlich',
    annually_save:'Sparen Sie {0}% bei jährlicher Abrechnung',
    annually_deduct: '${0} jährlich abgerechnet'
}
