module.exports = {
    convert_to_text_title_meta:"Traducteur de vidéos en ligne - Traduction de sous-titres et de vidéos en plusieurs langues",
    convert_to_text_desc_meta:"Prend en charge plus de 40 langues, dont l'anglais vers le japonais, le russe vers l'anglais, et le portugais vers l'anglais pour créer et exporter des sous-titres bilingues en ligne. Notre service de transcription et de traduction propulsé par IA répond parfaitement aux besoins de traduction multilingue de vidéos. Commencez un essai gratuit dès aujourd'hui!",
    convert_to_text_title:"Traduire une vidéo",
    convert_to_text_desc:"Traduisez automatiquement votre vidéo en plus de 40 langues, telles que l'anglais vers le portugais, l'anglais vers l'espagnol, et l'anglais vers l'italien, ou vice versa.",
    start_your_trial:"Commencez votre essai gratuit!",
    hero_img:"./03_English.webp",

    step_one:"Convertissez votre discours en texte",
    step_one_img:"./translate_step1_en.webp",
    step_one_desc:"Convertissez votre vidéo en texte à l'aide de notre moteur IA de reconnaissance vocale. Assurez-vous de choisir la langue correspondant à la vidéo lors du téléchargement.",
    
    step_two:"Sélectionnez la langue de traduction",
    step_two_img:"./translate_step2_en.webp",
    step_two_desc:"Modifier votre transcription originale avant la traduction peut améliorer la précision. Notre système prend en charge divers couples de langues, tels que l'anglais vers le vietnamien, l'allemand vers l'anglais, et l'arabe vers l'anglais. Cliquez sur le bouton en haut à droite pour traduire dans une autre langue.",

    step_three:"Éditez, peaufinez et exportez",
    step_three_img:"./translate_step3_en.webp",
    step_three_desc:"Une vue de comparaison côte à côte sera visible après la traduction vidéo pour vous aider à apporter des ajustements. Une fois terminé, vous êtes prêt à exporter votre traduction!",

    feature_tutorial_title:'Résoudre les défis de traduction de vidéos et d’audios',
    feature_tutorial_desc:"<p>Le traducteur vidéo et le convertisseur audio-texte de Taption sont conçus pour résoudre les problèmes courants dans les processus de traduction de sous-titres traditionnels. Notre technologie propulsée par l’IA surmonte ces défis majeurs:</p><ul><li><strong>Synchronisation des sous-titres:</strong> Ajustements précis pour garantir que les sous-titres sont parfaitement synchronisés avec le timing de la vidéo et de l’audio.</li><li><strong>Flux de contenu:</strong> Améliore la cohérence des sous-titres pour offrir une expérience de visionnage fluide.</li><li><strong>Précision:</strong> Évite les écarts que pourraient causer des traductions littérales, garantissant une transmission précise du message entre les langues.</li></ul><p>Avec la prise en charge de plus de 40 langues, y compris l’anglais vers le mandarin, l’anglais vers le japonais, l’anglais vers le coréen ou l’anglais vers le thaï, Taption vous permet de créer et d’exporter facilement des sous-titres bilingues. Notre plateforme en ligne élimine le besoin de télécharger un logiciel, ce qui facilite le début de l’utilisation de cette fonctionnalité. Que vous soyez un créateur de contenu YouTube, un éducateur transcrivant des cours enregistrés dans Google Drive, ou que vous organisiez des conférences internationales sur Zoom ou Webex, le logiciel de traduction vidéo de Taption s’intègre parfaitement. Il prend en charge l’intégration directe avec des plateformes comme YouTube, Google Drive, Zoom, et Webex, ce qui facilite la traduction et la gestion de votre contenu vidéo dans diverses langues et formats.</p><p>Pour les sous-titres bilingues, référez-vous à ce tutoriel <a class='link' href='https://www.taption.com/add-subtitles'>sur les sous-titres bilingues</a>. Consultez le tutoriel ci-dessous sur notre service de transcription et de traduction automatisé:</p>",
    feature_tutorial_poster_url:'./translate_tutorial_poster.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/GyPnMIYkvAc?autoplay=1',

    feature_extra_title:"Questions fréquemment posées sur la traduction vidéo",
    feature_extra_desc:"<ul><li><strong>Quels types de formats vidéo et audio peuvent être traduits?</strong><p>Taption prend en charge divers formats vidéo et audio, notamment MOV, AVI, WMV, FLV, MPEG, OGG, MP4, WAV, MP3 et M4A. Cette polyvalence permet aux utilisateurs de gérer une large gamme de types de fichiers courants pour répondre à différents besoins de transcription et de traduction.</p></li><li><strong>Combien de temps prend la traduction vidéo ou audio?</strong><p>Notre traducteur vidéo propulsé par l’IA et notre logiciel de conversion de parole en texte fonctionnent rapidement, complétant généralement la transcription et la traduction en 1 minute. Le temps spécifique peut varier en fonction de la longueur et de la complexité du fichier vidéo ou audio.</p></li><li><strong>Quel est le standard de tarification pour la traduction vidéo et audio?</strong><p>Pour les services de traduction vidéo et audio de Taption, y compris les conversions YouTube-texte, nous utilisons une méthode de facturation unique. Nous déduisons la moitié du nombre de minutes de la durée totale du fichier vidéo ou audio. Par exemple, si une vidéo dure 20 minutes, 10 minutes seront déduites du compte de l’utilisateur. Cette méthode de tarification est conçue pour fournir aux utilisateurs des services de transcription et de traduction rentables.</p></li></ul>"
}
