module.exports = {
    convert_to_text_title:"Collaborez avec des équipes",
    convert_to_text_desc:"Compte en équipe basé sur le cloud avec des transcriptions et étiquettes partagées",
    start_your_trial:"Commencez votre essai gratuit !",
    hero_img:"./06_English.webp",

    step_one:"Accès multi-utilisateurs",
    step_one_img:"./collaborate_step1_en.webp",
    step_one_desc:"Ajoutez des utilisateurs à votre compte et gérez-les",
    
    step_two:"Partager et éditer la transcription",
    step_two_img:"./collaborate_step2_en.webp",
    step_two_desc:"Ajoutez des utilisateurs à votre compte pour partager des transcriptions et des minutes",

    step_three:"Gestion des autorisations utilisateur",
    step_three_img:"./collaborate_step3_en.webp",
    step_three_desc:"Gérez les autorisations de visualisation et d’édition des comptes",

    feature_tutorial_title:'Tutoriel de gestion des utilisateurs : Améliorez la collaboration d’équipe avec un accès multi-utilisateurs',
    feature_tutorial_custom_desc:'En plus des 6 autorisations prédéfinies, vous pouvez également personnaliser l’accès à chaque fonctionnalité pour chaque utilisateur sous le compte.',
    feature_tutorial_custom_url:'./custom_dialog_en.webp',
    feature_tutorial_desc:'Vous souhaitez simplifier votre flux de travail tout en rendant votre facturation plus simple ? L’accès multi-utilisateurs de Taption pourrait être la solution que vous recherchez. Avec notre plateforme basée sur le cloud au cœur de tout, vous pouvez vous attendre à des opérations plus rentables ainsi qu’à une excellente gestion des utilisateurs.<br><br>Ça vous intéresse ? N’oubliez pas de consulter notre tutoriel détaillé pour obtenir tous les détails.',
    feature_tutorial_poster_url:'./permission_tutorial_poster_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/z1v2YdADBW8?autoplay=1',

    feature_extra_title:"Pourquoi la gestion des autorisations utilisateur est une décision stratégique",
    feature_extra_desc:"Gérer qui peut faire quoi dans Taption n’est pas qu’une corvée administrative. C’est un choix intelligent qui peut redéfinir la façon dont votre équipe interagit avec notre plateforme. Si vous êtes intrigué, voici quelques raisons solides de passer à l’action : <ul><li><strong>Intégration fluide :</strong> L'ajout de nouveaux membres à l’équipe est vraiment facile et aide tout le monde à s’adapter sans problème. </li><li><strong>Sécurisez vos données :</strong> L’accès basé sur les rôles garantit que les données sensibles restent protégées, afin que les transcriptions clés restent intactes et entre de bonnes mains. </li><li><strong>Expertise ciblée :</strong> Limiter certaines fonctionnalités de la plateforme à des membres spécifiques de l’équipe aide à développer l’expertise et élève le niveau de vos projets. </li><li><strong>Gardez le contrôle :</strong> Avec un contrôle de gestion total, vous pouvez superviser le partage des transcriptions et l’activation des fonctionnalités, garantissant un espace opérationnel sécurisé et harmonieux.</li></ul>En adoptant ces tactiques de gestion des utilisateurs, vous tirez le meilleur parti de votre équipe et de Taption. Que diriez-vous de franchir ce pas dès aujourd'hui ?"
}
