module.exports = {
    convert_to_text_title_meta: "Conversão automática de Texto para Legendas SRT com Carimbos de Tempo",
    convert_to_text_desc_meta: "Carregue a sua transcrição existente e use o nosso gerador de legendas online para adicionar carimbos de tempo automaticamente. Converta rapidamente TXT para SRT. Experimente grátis, registe-se agora para começar!",
    convert_to_text_title: "Conversão automática de TXT para legenda SRT",
    convert_to_text_desc: "Carregue a sua transcrição existente e use o nosso gerador de legendas para adicionar automaticamente carimbos de tempo e formatá-la. Converta rapidamente TXT para SRT.",
    start_your_trial: "Experimente Agora!",
    hero_img:"./09_English.webp",

    step_one: "Selecione o ficheiro de áudio/vídeo para a sua transcrição",
    step_one_img: "./select_source_en.webp",
    step_one_desc: "Primeiro, escolha o ficheiro de áudio/vídeo que corresponda à sua transcrição. O idioma da transcrição e do vídeo devem ser iguais.",

    step_two: "Importe a sua transcrição",
    step_two_img: "./transcripts_subtitles_step02_en.webp",
    step_two_desc: "Na janela <strong>Método de Transcrição</strong>, selecione <span style=\"color:#3ccebd\">Importar Ficheiro de Texto</span>. Para <strong>Selecione o formato de saída</strong>, escolha <span style=\"color:#3ccebd\">Legendas/Subtítulos</span>.",

    step_three: "Edite e exporte",
    step_three_img: "./export_srt_step03_en.webp",
    step_three_desc: "Entre na plataforma de edição para editar as legendas e exportar.",

    feature_tutorial_title: 'Converta o seu texto em legendas',
    feature_tutorial_desc: 'Seja uma transcrição, artigo ou outro conteúdo de texto, simplesmente faça o upload na plataforma Taption para usar rapidamente o nosso gerador de legendas. Ele converte automaticamente o seu texto em legendas compatíveis com vídeo, completando a conversão de TXT para SRT. Com o nosso gerador de legendas, pode evitar a entrada manual de legendas e o alinhamento demorado de timelines. A nossa ferramenta oferece alta precisão e consistência. Além disso, pode facilmente traduzir as legendas para mais de 40 idiomas, atendendo às necessidades diversas de públicos globais.',
    feature_tutorial_poster_url: './transcripts_to_subtitles_thumbnail_en.webp',
    feature_tutorial_youtube: 'https://www.youtube.com/embed/cMc71uumga4?si=Asfp-N2yjNahp_M5&autoplay=1',

    feature_extra_title: "Aplicações integradas de conversão de transcrições para legendas",
    feature_extra_desc: "<ul><li><strong>Criação de Legendas para Vídeo:</strong> Quando tem conteúdo de texto para o seu vídeo, como roteiros ou diálogos, pode converter rapidamente esses textos em legendas para vídeo.</li><li><strong>Materiais Educativos e de Treinamento:</strong> Para educadores ou formadores, pode converter conteúdo de ensino em legendas para facilitar a compreensão e a tomada de notas pelos alunos. Isto é particularmente adequado para cursos online ou programas de treinamento.</li><li><strong>Melhorar SEO:</strong> Converter conteúdo de vídeo em legendas de texto ajuda os motores de busca a indexar o seu conteúdo, aumentando a visibilidade do seu site ou vídeo.</li></ul>",

    feature_what_title: "Perguntas frequentes",
    feature_what_desc: "<ul><li><h2>O ficheiro TXT carregado pode ser automaticamente formatado e ter carimbos de tempo adicionados para atender a várias necessidades?</h2><br>Sim, pode. Oferecemos três métodos de formatação:<ul><li>Segmentação automática de legendas: Usa a funcionalidade de segmentação do gerador de legendas para determinar os melhores pontos de segmentação com base no conteúdo do vídeo.</li><li>Segmentação por diarização de oradores: Adequado para registros de reuniões ou podcasts, segmentando com base nos diferentes oradores.</li><li>Segmentação com base no formato original do ficheiro TXT: Segmenta de acordo com o formato existente no ficheiro de texto fornecido.</li></ul><br><br></li>   <li><h2>Eu não tenho um ficheiro TXT. O sistema pode gerar automaticamente uma transcrição ou legenda para mim?</h2><br>Sim, pode. Ao carregar o seu ficheiro de vídeo, escolha a opção <strong>Transcrição Automática</strong> em <strong>Método de Transcrição</strong>. O nosso gerador de legendas por IA converterá automaticamente o conteúdo do seu vídeo em legendas. Para mais informações, visite <a href=\"https://www.taption.com/add-subtitles\">Gerador de Legendas por IA</a> ou <a href=\"https://www.taption.com/convert-to-text\">Converter vídeo para texto</a>.<br><br></li>  <li><h2>Depois de adicionar carimbos de tempo ao ficheiro TXT, ele pode ser traduzido para outros idiomas?</h2><br>Sim, pode. Após adicionar carimbos de tempo ao ficheiro TXT fornecido, pode optar por traduzir para mais de 40 idiomas a partir do canto superior direito da plataforma de edição. Isso inclui inglês para japonês, inglês para vietnamita, inglês para chinês, entre outros. Para mais informações sobre tradução, consulte <a href=\"https://www.taption.com/translate\">Tradução automática de vídeos</a>.</li></ul>"
}
