module.exports = {
  'en-US': 'Anglais (États-Unis)',
  'en-GB': 'Anglais (Royaume-Uni)',
  'en-AU': 'Anglais (Australie)',
  'zh-TW': 'Chinois (Taïwan)',
  'zh': 'Chinois (Chine)',
  'yue-Hant-HK-TW-zh': 'Cantonais (Sortie en chinois traditionnel)',
  'yue-Hant-HK-zh': 'Cantonais (Sortie en chinois simplifié)',
  'yue-Hant-HK-TW': 'Cantonais (Sortie en cantonais traditionnel)',
  'yue-Hant-HK': 'Cantonais (Sortie en cantonais simplifié)',
  'es-ES': 'Espagnol (Espagne)',
  'fr-FR': 'Français (France)',
  'de-DE': 'Allemand (Allemagne)',
  'it-IT': 'Italien (Italie)',
  'pt-PT_pt-BR': 'Portugais (Portugal, Brésil)',
  'nl-BE': 'Néerlandais (Belgique)',
  'hi-IN': 'Hindi (Inde)',
  'ja-JP': 'Japonais (Japon)',
  'ko-KR': 'Coréen (Corée du Sud)',
  'vi-VN': 'Vietnamien (Vietnam)',

  'ar-EG': 'Arabe (Égypte)',
  'hy-AM': 'Arménien (Arménie)',
  'bs-BA': 'Bosniaque (Bosnie-Herzégovine)',
  'bg-BG': 'Bulgare (Bulgarie)',
  'my-MM': 'Birman (Myanmar)',
  'ca-ES': 'Catalan (Espagne)',
  'hr-HR': 'Croate (Croatie)',
  'cs-CZ': 'Tchèque (République tchèque)',
  'da-DK': 'Danois (Danemark)',
  'tl-PH': 'Filipino (Philippines)',
  'fi-FI': 'Finlandais (Finlande)',
  'gl-ES': 'Galicien (Espagne)',
  'el-GR': 'Grec (Grèce)',
  'gu-IN': 'Gujarati (Inde)',
  'iw-IL': 'Hébreu (Israël)',
  'hu-HU': 'Hongrois (Hongrie)',
  'id-ID': 'Indonésien (Indonésie)',
  'lv-LV': 'Letton (Lettonie)',
  'lt-LT': 'Lituanien (Lituanie)',
  'mk-MK': 'Macédonien (Macédoine du Nord)',
  'ms-MY': 'Malais (Malaisie)',
  'no-NO': 'Norvégien (Norvège)',
  'pl-PL': 'Polonais (Pologne)',
  'ro-RO': 'Roumain (Roumanie)',
  'ru-RU': 'Russe (Russie)',
  'sk-SK': 'Slovaque (Slovaquie)',
  'sl-SI': 'Slovène (Slovénie)',
  'sv-SE': 'Suédois (Suède)',
  'ta-IN': 'Tamoul (Inde)',
  'te-IN': 'Télougou (Inde)',
  'th-TH': 'Thaï (Thaïlande)',
  'tr-TR': 'Turc (Turquie)',
  'uk-UA': 'Ukrainien (Ukraine)',
  'uz-UZ': 'Ouzbek (Ouzbékistan)'
}
