module.exports = {
    convert_to_text_title_meta:"Penerjemah Video Online - Subtitle dan Terjemahan Video dalam Berbagai Bahasa",
    convert_to_text_desc_meta:"Dukung lebih dari 40 bahasa termasuk Inggris ke Jepang, Rusia ke Inggris, dan Portugis ke Inggris untuk membuat dan mengekspor subtitle bilingual secara online. Layanan transkripsi dan terjemahan bertenaga AI kami sepenuhnya memenuhi kebutuhan terjemahan video multibahasa. Mulai dengan uji coba gratis hari ini!",
    convert_to_text_title:"Terjemahkan video",
    convert_to_text_desc:"Secara otomatis terjemahkan video Anda ke lebih dari 40 bahasa, seperti Inggris ke Portugis, Inggris ke Spanyol, dan Inggris ke Italia, atau sebaliknya.",
    start_your_trial:"Mulai uji coba gratis Anda!",
    hero_img:"./03_English.webp",

    step_one:"Ubah pidato Anda menjadi teks",
    step_one_img:"./translate_step1_en.webp",
    step_one_desc:"Ubah video Anda menjadi teks menggunakan mesin AI pidato-ke-teks kami. Pastikan Anda memilih bahasa yang sesuai dengan video saat mengunggah.",
    
    step_two:"Pilih bahasa terjemahan",
    step_two_img:"./translate_step2_en.webp",
    step_two_desc:"Mengedit transkrip asli Anda sebelum menerjemahkan dapat meningkatkan akurasi. Sistem kami mendukung berbagai pasangan bahasa seperti Inggris ke Vietnam, Jerman ke Inggris, dan Arab ke Inggris. Klik tombol di kanan atas untuk menerjemahkan ke bahasa lain.",

    step_three:"Edit, perbaiki, dan ekspor",
    step_three_img:"./translate_step3_en.webp",
    step_three_desc:"Tampilan perbandingan berdampingan akan terlihat setelah terjemahan video untuk membantu Anda melakukan penyesuaian. Setelah selesai, Anda siap untuk mengekspor terjemahan Anda!",

    feature_tutorial_title:'Mengatasi tantangan terjemahan video dan audio',
    feature_tutorial_desc:"<p>Penerjemah video dan konverter audio-ke-teks Taption dirancang untuk mengatasi masalah umum dalam proses terjemahan subtitle tradisional. Teknologi bertenaga AI kami mengatasi tantangan utama ini:</p><ul><li><strong>Sinkronisasi Waktu:</strong> Menyesuaikan dengan tepat untuk memastikan subtitle disinkronkan dengan waktu video dan audio.</li><li><strong>Alur Konten:</strong> Meningkatkan koherensi subtitle untuk memberikan pengalaman menonton yang lancar.</li><li><strong>Akurasi:</strong> Mencegah ketidaksesuaian yang mungkin disebabkan oleh terjemahan harfiah, memastikan penyampaian pesan yang akurat antar bahasa.</li></ul><p>Mendukung lebih dari 40 bahasa, termasuk Inggris ke Mandarin, Inggris ke Jepang, Inggris ke Korea, atau Inggris ke Thai, Taption memungkinkan Anda dengan mudah membuat dan mengekspor subtitle bilingual. Platform online kami menghilangkan kebutuhan untuk mengunduh perangkat lunak, membuatnya mudah untuk mulai menggunakan fitur ini. Apakah Anda pembuat konten YouTube, pendidik yang mentranskripsi kuliah yang disimpan di Google Drive, atau penyelenggara konferensi internasional Zoom atau Webex, perangkat lunak terjemahan video Taption terintegrasi dengan mulus. Ini mendukung integrasi langsung dengan platform seperti YouTube, Google Drive, Zoom, dan Webex, membuatnya mudah untuk menerjemahkan dan mengelola konten video Anda dalam berbagai bahasa dan format.</p><p>Untuk subtitle bilingual, lihat tutorial ini <a class='link' href='https://www.taption.com/add-subtitles'>tentang subtitle bilingual</a>. Lihat tutorial berikut tentang layanan transkripsi dan terjemahan otomatis kami:</p>",
   
    feature_tutorial_poster_url:'./translate_tutorial_poster.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/GyPnMIYkvAc?autoplay=1',

    feature_extra_title:"Pertanyaan yang sering diajukan tentang terjemahan video",
    feature_extra_desc:"<ul><li><strong>Jenis format video dan audio apa yang dapat diterjemahkan?</strong><p>Taption mendukung berbagai format video dan audio, termasuk MOV, AVI, WMV, FLV, MPEG, OGG, MP4, WAV, MP3, dan M4A. Fleksibilitas ini memungkinkan pengguna untuk menangani berbagai jenis file umum untuk memenuhi berbagai kebutuhan transkripsi dan terjemahan.</p></li><li><strong>Berapa lama waktu yang dibutuhkan untuk menerjemahkan video atau audio?</strong><p>Penerjemah video dan perangkat lunak pidato-ke-teks bertenaga AI kami bekerja dengan cepat, biasanya menyelesaikan transkripsi dan terjemahan dalam 1 menit. Waktu spesifik dapat bervariasi berdasarkan panjang dan kompleksitas file video atau audio.</p></li><li><strong>Apa standar harga untuk terjemahan video dan audio?</strong><p>Untuk layanan terjemahan video dan audio Taption, termasuk konversi YouTube ke teks, kami menggunakan metode penagihan unik. Kami mengurangi setengah jumlah menit dari total durasi file video atau audio. Misalnya, jika video berdurasi 20 menit, 10 menit akan dikurangkan dari akun pengguna. Metode penetapan harga ini dirancang untuk memberikan layanan transkripsi dan terjemahan yang hemat biaya bagi pengguna.</p></li></ul>"
}
