module.exports = {
  enjoy_your_free_trial: '¡Tu primera transcripción es gratis!',
  dialog_title: 'Añadir créditos',
  subscription_title: 'Obtener suscripción',
  pay: 'Pagar',
  error_unauthenticate: 'No podemos autorizar tu método de pago, por favor intenta con otra tarjeta.',
  error_payment_creation: 'No se pudo completar tu pago, por favor contacta a soporte',
  error_general: 'No se pudo completar la conexión con el servidor, por favor actualiza la página e inténtalo de nuevo',
  error_timeout: 'Tiempo de conexión agotado, por favor actualiza la página e inténtalo de nuevo',

  expired_card:'La tarjeta ha expirado, por favor usa otra tarjeta de crédito.',
  do_not_honor:'La tarjeta ha sido rechazada, por favor contacta a tu emisor para más información.',
  insufficient_funds:'La tarjeta no tiene fondos suficientes para completar esta transacción.',
  invalid_info: 'La información de la tarjeta proporcionada es inválida, por favor verifica e intenta de nuevo.',

  success: 'Éxito',
  success_description: 'Tu cuenta ha sido acreditada con <br/> <b class="number-of-minutes">{0} minutos</b>',
  success_description_bonus: 'Minutos de bonificación: <b class ="extra-minutes"> {1} minutos</b> <br/>Tu cuenta ha sido acreditada con <br/> <b class="number-of-minutes">{0} minutos</b>',
  success_subscription_description: 'Tu suscripción Premium ha sido activada',
  success_corp_subscription_description: 'Tu suscripción Corporativa ha sido activada',
  cancel_subscription_confirmation: '¿Estás seguro de que deseas cancelar la suscripción?',
  success_subscription_bonus: 'Minutos de bonificación: <b class ="extra-minutes"> {0} minutos</b>',

  cancel_subscription_desc:' los minutos expirarán inmediatamente al cancelar<br>Solo se podrán usar las primeras <span style="color:rgb(246, 102, 101)!important">1</span> etiquetas después de la cancelación',
  cancel_subscription_confirm:'Confirmo renunciar a los minutos indicados anteriormente',
  reactivate_subscription_confirmation: '¿Estás seguro de que deseas reactivar la suscripción?',
  cacnel_subscription_description: 'Aún tendrás acceso Premium hasta el final del periodo.',
  cacnel_subscription: 'Tu suscripción Premium ha sido cancelada',
  cancel_corp_subscription: 'Tu suscripción Corporativa ha sido cancelada',
  reactive_subscription: '¡Tu suscripción ha sido reactivada!',
  active_until:'Activo hasta',
  success_translation_description: '¡Tu traducción está lista!',
  yes: 'Sí',
  no: 'No',
  continue: 'Continuar',
  save: 'Guardar',
  cancel: 'Cancelar',
  processing_payment: 'Procesando el pago',
  error_edit: 'No se pudo completar la actualización de la tarjeta, por favor contacta a soporte',
  delete_ok: 'Eliminar',
  delete_cancel: 'Cancelar',
  billing_address: 'Dirección de facturación',
  how_many_hours: '¿Cuántas horas?',
  credit_card: 'Tarjeta de crédito',
  summary: 'resumen (USD)',
  save_payment: 'Guardar mi tarjeta para futuras compras',
  processing_payment_description: 'Esto puede tomar unos minutos...',
  credit_card_manage:'Tu tarjeta de crédito se procesa de forma segura por Stripe, Inc',
  credit_card_secured:'Las transacciones son seguras y están encriptadas.',
  understand_billing:'Autorizo este pago',
  processing_saving:'Actualizando tarjeta de crédito',
  // field
  full_name: 'Nombre completo',
  name: 'Nombre',
  address: 'Dirección',
  city: 'Ciudad',
  state: 'Estado',
  zip: 'Código postal',
  // validation
  max_length_field: '{0} debe tener como máximo {1} caracteres',
  required_field: '{0} es obligatorio',
  delete_title: '¿Eliminar esta tarjeta?',

  title_basic: 'Estándar',
  title_pay_as_you_go: 'Paga por uso',
  title_premium: 'Premium',
  title_custom: 'Personalizado',
  title_corp: 'Corporativo',
  title_subscription:'Suscripción',

  basic_feature5: 'Crédito <span style="color: #00d1b2;">60 minutos</span>',

  premium_feature1: '{0} <span style="color: #00d1b2;">minutos</span> cada mes.<br><span class="price-notes">(Minutos acumulables, válidos hasta la cancelación de la suscripción)</span>',
  premium_feature2: '25% de descuento en compras por hora',
  premium_feature3_tooltip: '$2 por documento',

  buy: 'Comprar minutos',
  activate: 'Activar',
  subscribe: 'Suscribirse',
  contact_us: 'Contáctanos',

  custom_feature1: 'Alianzas comerciales',
  custom_feature2: 'Suscripciones grupales',
  custom_feature3: 'Facturación centralizada',
  custom_feature4: 'Informes de comentarios o errores',

  month: 'mes',
  per_hour: 'por hora',
  per_month: 'por mes',
  per_user_per_month:'{0} por usuario/mes',

  how_get_free:'¿Cómo puedo obtener minutos gratis?',
  payment_questions:'¿Tienes preguntas sobre facturación?',

  translate_remaining:'Te quedan {0}.',
  translate_deduct:'Deduciremos {0} si traduces esta transcripción.',
  transcribe_deduct:'Deduciremos {0} si transcribes esta transcripción.',
  transcribe_warning:'Por favor, asegúrate de tener suficientes minutos para transcribir, de lo contrario, el proceso fallará.',
  mp4_deduct:'Deduciremos {0} si exportas esta transcripción como MP4.',
  cvc:'¿Qué es CVC?',
  confirm_translate:'Quiero proceder con la traducción',
  recurring_description:'• Pago mensual recurrente',
  recurring_description2:'• Cancela en cualquier momento desde Cuenta',
  coupon_header:'Código de promoción',
  coupon_title:'Ingresa tu código de promoción:',
  coupon_submit:'Aplicar',
  processing_coupon: 'Aplicando el código de promoción',
  coupon_empty: 'Por favor, ingresa tu código de promoción',
  coupon_expire: 'El cupón ha expirado',
  coupon_notexist: 'El cupón no es válido',
  coupon_firsttimeonly: 'El cupón solo es válido para nuevos usuarios',
  member_slot_desc:'Total de espacios para miembros de esta suscripción para invitar a miembros con diferentes permisos a <br>añadir, subir, editar y/o exportar transcripciones desde esta cuenta.',
  credit_edit_title:'Información de la tarjeta de crédito',

  payment_confirm_title:'Confirmación de pago',
  payment_confirm_desc:'Este pago se cargará en <span style="color:#2fc59f;font-weight:bold;">dólares estadounidenses</span> por un monto de {0}.',
  upgrade_contact_us:'Para soluciones empresariales, programas de afiliados o consultas comerciales, contáctanos en <span style="color:#2fc59f">help@taption.com</span>'
}
