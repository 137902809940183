module.exports = {
  enjoy_your_free_trial: '最初のアップロードは無料です!',
  dialog_title: 'より多くの時間を取得',
  subscription_title: 'サブスクリプションを取得',
  pay: '確認',
  error_unauthenticate: 'お支払い方法を確認できませんでした。クレジット カードを変更してください',
  error_payment_creation: '支払いを完了できないか、カスタマー サービス スタッフに連絡できません',
  error_general: 'サーバーへの接続を完了できません。更新してからもう一度お試しください',
  error_timeout: '接続がタイムアウトしました。更新してからもう一度お試しください',
  expired_card: 'クレジット カードの有効期限が切れています。別のカードを試して、もう一度お試しください',
  do_not_honor: 'クレジット カードが承認されませんでした。銀行にお問い合わせいただくか、別のカードをお試しください',
  insufficient_funds: 'このカードの残高は、この金額を支払うには不十分です。残りの金額を確認するか、別のカードを試してください',
  invalid_info: 'クレジット カード情報が正しく入力されていません。確認してからもう一度お支払いください',
  success: '成功',
  success_description: 'あなたのアカウントはチャージされました<br/> <b class ="number-of-minutes"> {0}分</b>',
  success_description_bonus: '余分な <b class ="extra-minutes">{1}分</b> <br/>アカウントにチャージされました<br/> <b class="number-of-minutes">{0}分</b>',
  success_subscription_description: 'あなたは私たちのプレミアム購読者になりました',
  success_corp_subscription_description: '大きいサブスクリプションになりました',
  success_subscription_bonus: '余分な <b class ="extra-minutes">{0}分</b>',
  cancel_subscription_confirmation: 'サブスクリプションをキャンセルしてもよろしいですか? ',
  cancel_subscription_desc:' サブスクリプションの時間は <br><span style="color:rgb(246, 102, 101)">{1}分</span> タグが利用できなくなる直前に期限切れになります',
  cancel_subscription_confirm: 'サブスクリプションをキャンセルし、すべてのサブスクリプション時間を放棄することを確認しました',
  reactivate_subscription_confirmation: 'サブスクリプションを再開してもよろしいですか? ',
  cacnel_subscription_description: '期間が終了するまで、あなたは引き続きプレミアム ユーザーです',
  cacnel_subscription: 'あなたのプレミアム サブスクリプションはキャンセルされました',
  cancel_corp_subscription: '大きいサブスクリプションはキャンセルされました',
  react_subscription: 'プレミアムサブスクリプションが再開されました!',
  active_until: '有効期限',
  success_translation_description: '翻訳が完了しました! ',
   yes:'確認',
    no: '番号',
    continue: '続行',
    save: '保存',
    cancel: 'キャンセル',
    processing_payment: '支払い処理中',
    error_edit: 'カードの更新を完了できません。サポートに連絡してください',
    delete_ok: '削除',
    delete_cancel: 'キャンセル',
    billing_address: 'クレジット カード登録アドレス',
    how_many_hours: '何時間? ',
    credit_card: 'クレジットカード',
    summary: '合計金額 (USD)',
    save_payment: 'このカードを保存',
    processing_payment_description: 'これには数分かかる場合があります...更新しないでください',
   // credit_card_manage: 'アカウント ページでクレジット カードを管理できます。 '、
    credit_card_manage: 'クレジット カード関連の処理には Stripe を使用しており、お客様の個人情報は最高レベルで保証されます',
    credit_card_secured: '支払いが安全であることを確認してください。この取引プロセスは暗号化されています',
    understand_billing: 'この支払いを許可します',
    processing_saving: 'クレジットカードを更新中',
    full_name: '名前',
    name: '名前',
    address: 'アドレス',
    city: '都市',
    state: '都道府県',
    zip: '郵便番号',
    max_length_field: '{0}の長さは最大{1}文字です',
    required_field: '{0}は必須です',
    delete_title: 'このクレジット カードを削除しますか',
    title_basic: 'スタンダード',
    title_pay_as_you_go: '動画の長さによる支払い',
    title_premium: 'プレミアム サブスクリプション',
    title_custom: 'カスタマイズ',
    title_corp: '大きいサブスクリプション',
    title_subscription: '購読システム',
    basic_feature5: 'リチャージ <span style="color: #00d1b2;">60分</span>',
    basic_feature4_tooltip: '',
    premium_feature1: '無料{0}<span style="color: #00d1b2;">毎月分</span><br><span class="price-notes"> (月間で累積可能、サブスクリプションのキャンセルまで有効)まで)</span>',
    premium_feature2: 'さらに時間が必要な場合は、割引価格で購入できます',
    premium_feature3_tooltip: 'テキスト翻訳あたり$2',
    buy: '購入分',
    activate: '有効にする',
    subscribe: 'サブスクライブ',
    contact_us: 'お問い合わせ',
    custom_feature1: '事業協力',
    custom_feature2: 'グループ サブスクリプション',
    custom_feature3: 'フィードバック',
    custom_feature4: 'エラーリターン',
    month: '月',
    per_hour: '毎時',
    per_month: '毎月',
    per_user_per_month: '{0}ユーザーごと/月',
    how_get_free: '無料の時間を取得するには?',
    payment_questions: '支払いに関するその他の質問?',
    translate_remaining: '現在の動画の残り時間: {0}',
    translate_deduct: 'このビデオを翻訳するために差し引かれる分数: {0}',
    transcribe_deduct: 'このビデオがテキストに変換されるときに差し引かれる分数: {0}',
    transcribe_warning: 'この動画の合計の長さが現在の動画の残り時間よりも短いことを確認してください。そうしないと、スムーズに転送できません。 ',
    mp4_deduct: '字幕がビデオに埋め込まれている場合に差し引かれる分数: {0}',
    cvc: 'CVC とは?',
    confirm_translate: '翻訳することを確認しました',
    recurring_description: '＊月額自動引き落とし',
    recurring_description2:'* 「マイ アカウント」に移動して、いつでも購読を解除できます。 ',
    coupon_header:'クーポンコード',
    coupon_title:'クーポンコードを入力してください:',
    coupon_submit:'確認',
    processing_coupon: 'クーポンコードを有効にする...',
    coupon_empty: 'クーポンコードを入力してください',
    coupon_expire: 'クーポン コードの有効期限が切れています',
    coupon_notexist: 'クーポンコードが存在しません',
    coupon_firsttimeonly: 'クーポンコードは新規ユーザー専用です',
    member_slot_desc: '他の Taption ユーザーに参加して、議事録を共有したり、このサブスクリプション アカウントのビデオを編集、アップロード、閲覧、エクスポートしたりできます<br>。 <br>また、ユーザーごとに異なる権限を付与できます。 ',
    credit_edit_title:'クレジットカード情報の変更',
    payment_confirm_title:'金額確認',
    payment_confirm_desc:'支払い額{0}は <span style="color:#2fc59f;font-weight:bold;">米ドル</span> で計算されます。 ',
    upgrade_contact_us:'ビジネス協力、アフィリエイト マーケティング、カスタマイズされたソリューション、またはその他の質問については、<span style="color:#2fc59f">help@taption.com</span>までご連絡ください。'
  }
  