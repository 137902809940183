module.exports = {
    convert_to_text_title:"Convierte audio a vídeo",
    convert_to_text_desc:"Toma tu archivo de audio y una imagen para convertirlo en un vídeo MP4 con subtítulos",
    start_your_trial:"¡Comienza tu prueba gratuita!",
    hero_img:"./05_English.webp",

    step_one:"Convierte audio a texto",
    step_one_img:"./customized_step1_en.webp",
    step_one_desc:"Sube tu audio y elige segmentar cada sección para \"Subtítulos/Captions\"",
    
    step_two:"Sube una imagen personalizada",
    step_two_img:"./customized_step2_en.webp",
    step_two_desc:"En la plataforma de edición, sube tu imagen en Exportar → Archivo de vídeo MP4",

    step_three:"Exporta el vídeo",
    step_three_img:"./customized_step3_en.webp",
    step_three_desc:"¡Exporta tu vídeo personalizado!",

    feature_tutorial_title:'Cómo convertir tu audio en vídeo',
    feature_tutorial_desc:'Entonces, tienes un archivo de audio y estás pensando en convertirlo a vídeo. Esto puede parecer solo una tarea sencilla, ¡pero es mucho más que eso! Es un movimiento inteligente que podría ampliar considerablemente la audiencia de tu contenido. Nuestra plataforma de edición fácil de usar hace que todo el proceso sea lo más simple y cómodo posible. <br><br>¿Adivina qué? Tenemos un tutorial detallado listo para guiarte, así que no dudes en echarle un vistazo para comprender mejor cómo empezar.',
    feature_tutorial_poster_url:'./audio_to_video_tutorial_poster_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/efDzKWNMgxw?autoplay=1',

    feature_extra_title:"¿Por qué deberías convertir tu archivo de audio a un vídeo MP4 con texto en pantalla?",
    feature_extra_desc:"¿Alguna vez has pensado en llevar tus archivos de audio al siguiente nivel convirtiéndolos en vídeos MP4 con subtítulos o captions? Aquí tienes algunas razones por las que esto es un movimiento inteligente: <ul><li><strong>Reutilización de contenido:</strong> Una gran ventaja es la oportunidad de reutilizar tu contenido en varias plataformas de redes sociales. Esto amplía tu alcance y aprovecha al máximo lo que ya has creado.</li><li><strong>Interacción única:</strong> La adición de subtítulos o captions es una forma nueva e inclusiva de conectar con la audiencia de tu contenido. </li><li><strong>Alcance global:</strong> Cuando conviertes audio a vídeo, no solo cambias formatos, sino que haces que tu contenido esté disponible para una audiencia global. Con transcripciones y subtítulos, tu mensaje no tiene fronteras. </li></ul>Al aprovechar estos beneficios, estás mejorando tu contenido en general. ¿No crees que son buenas razones para convertir tu audio a vídeo?"
}
