module.exports = {
    title_0: 'Como transcrever o meu vídeo?',
    desc_0:'Veja o vídeo tutorial',
    video_0:"https://www.youtube.com/embed/FZOHfAFIqog",
    
    title_1: 'Como adicionar legendas ao meu vídeo?',
    desc_1:'Veja o vídeo tutorial',
    video_1:"https://www.youtube.com/embed/WEFq3Mcl8dk",
    
    title_2: 'Como traduzir o meu vídeo?',
    desc_2:'Veja o vídeo tutorial',
    video_2:"https://www.youtube.com/embed/mU4uadAK_w0",
    
    title_3: 'Como aumentar a precisão usando o Dicionário de IA?',
    desc_3:'Veja o vídeo tutorial',
    video_3:"https://www.youtube.com/embed/7gEBb_moIuI",

    title_4: 'Como editar meu vídeo editando o texto?',
    desc_4:'Veja o vídeo tutorial',
    video_4:"https://www.youtube.com/embed/W2yJEU1vMrg",
    
    title_5: 'Onde posso ver os meus recibos e faturas?',
    desc_5:'Após assinar, poderá visualizá-los e fazer o download em <span style="color:rgb(0, 209, 178);">Conta → Faturas → Ver todas</span>',
    video_5:"",

    title_6: 'Assinei um plano de subscrição. O que posso fazer se precisar de mais minutos do que os fornecidos mensalmente?',
    desc_6:'Ao assinar o <span style="color:rgb(0, 209, 178);">Plano Premium</span>, poderá adquirir minutos adicionais a um preço com desconto de <span style="color:rgb(0, 209, 178);">6$/hora</span>. Ao assinar o <span style="color:rgb(0, 209, 178);">Plano Bulk</span>, o preço com desconto para minutos adicionais é de <span style="color:rgb(0, 209, 178);">3$/hora</span>',
    video_6:"",

    title_7: 'A transcrição não está traduzindo. Como posso resolver isso?',
    desc_7:'Certifique-se de ter selecionado o idioma correto ao fazer o upload do vídeo. Deve escolher o idioma correspondente ao idioma falado no vídeo.',
    video_7:"",

    title_8: 'Fiz o upload de um arquivo com menos de 5GB. Por que recebo a mensagem <span style="color:rgb(230, 118, 117);">Tamanho do vídeo muito grande</span>?',
    desc_8:'O limite de tamanho de 5GB é baseado no formato MP4. Ao fazer o upload de um vídeo em outro formato, a conversão para MP4 pode resultar em um tamanho superior a 5GB. Faça o upload de um vídeo no formato MP4 com menos de 5GB para evitar esta mensagem.',
    video_8:"",

    title_9: 'O que significam as mensagens de erro e o que posso fazer para resolvê-las?',
    desc_9:'<span style="color:rgb(230, 118, 117);">Excedeu o uso do teste</span>: Compre minutos ou assine um plano para continuar a usar o serviço.<br><span style="color:rgb(230, 118, 117);">Minutos insuficientes</span>: Compre mais minutos ou assine e tente novamente.<br><span style="color:rgb(230, 118, 117);">Falha no link do YouTube, por favor, tente novamente</span>: O motivo mais comum é que o vídeo foi carregado recentemente. Aguarde pelo menos 24 horas antes de tentar transcrever novamente.',
    video_9:"",

    title_10: 'Como integrar o Taption com o Zoom?',
    desc_10:'Veja o <a href="https://www.taption.com/blog/en/zoom-tutorial-en" rel="noreferrer" target="_blank">tutorial passo a passo</a>',
    video_10:"",

    title_11: 'Tenho outras perguntas. O que devo fazer?',
    desc_11:'Por favor, entre em contato conosco em <span style="color:rgb(0, 209, 178);">help@taption.com</span>',
    video_11:"",

    zoom_title:'Integração Taption + Zoom passo a passo',
    zoom_install_desc:'Agora pode conectar a sua conta Zoom com a sua conta Taption. Depois deste tutorial, poderá transcrever gravações do Zoom diretamente do Taption.',
    zoom_step_1:'Clique em "Carregar" após entrar na sua conta',
    zoom_step_2:'Escolha "Zoom" na janela pop-up',
    zoom_step_3:'Faça login com sua conta Zoom e autorize',
    zoom_step_4:'Escolha a gravação na tabela',
    zoom_step_5:'Configure suas definições e estará pronto para carregar e transcrever',
    zoom_uninstall_title:'Desinstalar integração Taption + Zoom',
    zoom_uninstall_desc:'Para desinstalar o Zoom do Taption, vá a <strong>Conta</strong> → <strong>Aplicações</strong> → <strong>Desinstalar</strong><br>Se tiver outras dúvidas, por favor, envie um e-mail para <span style="color:rgb(0, 209, 178);">help@taption.com</span>',

    webex_title:'Integração Taption + Webex passo a passo',
    webex_install_desc:'Agora pode conectar a sua conta Webex com a sua conta Taption. Depois deste tutorial, poderá transcrever gravações do Webex diretamente do Taption.',
    webex_step_1:'Clique em "Carregar" após entrar na sua conta',
    webex_step_2:'Escolha "Webex" na janela pop-up',
    webex_step_3:'Faça login com sua conta Webex e autorize',
    webex_step_4:'Escolha a gravação na tabela',
    webex_step_5:'Configure suas definições e estará pronto para carregar e transcrever',
    webex_uninstall_title:'Desinstalar integração Taption + Webex',
    webex_uninstall_desc:'Para desinstalar o Webex do Taption, vá a <strong>Conta</strong> → <strong>Aplicações</strong> → <strong>Desinstalar</strong><br>Se tiver outras dúvidas, por favor, envie um e-mail para <span style="color:rgb(0, 209, 178);">help@taption.com</span>',
}
