module.exports = {
    title_0: 'Comment transcrire ma vidéo ?',
    desc_0:'Voir la vidéo tutorielle',
    video_0:"https://www.youtube.com/embed/FZOHfAFIqog",
    
    title_1: 'Comment ajouter des sous-titres à ma vidéo ?',
    desc_1:'Voir la vidéo tutorielle',
    video_1:"https://www.youtube.com/embed/WEFq3Mcl8dk",
    
    title_2: 'Comment traduire ma vidéo ?',
    desc_2:'Voir la vidéo tutorielle',
    video_2:"https://www.youtube.com/embed/mU4uadAK_w0",
    
    title_3: 'Comment augmenter la précision avec le Dictionnaire IA ?',
    desc_3:'Voir la vidéo tutorielle',
    video_3:"https://www.youtube.com/embed/7gEBb_moIuI",

    title_4: 'Comment éditer ma vidéo en modifiant le texte ?',
    desc_4:'Voir la vidéo tutorielle',
    video_4:"https://www.youtube.com/embed/W2yJEU1vMrg",
    
    title_5: 'Où puis-je consulter mes reçus et factures ?',
    desc_5:'Une fois abonné, vous pourrez les consulter et les télécharger sous <span style="color:rgb(0, 209, 178);">Compte → Factures → Tout voir</span>',
    video_5:"",

    title_6: 'Je me suis abonné à un forfait, que puis-je faire si j’ai besoin de plus de minutes que celles incluses mensuellement ?',
    desc_6:'Avec la <span style="color:rgb(0, 209, 178);">formule Premium</span>, vous pouvez acheter des minutes supplémentaires à un tarif réduit de <span style="color:rgb(0, 209, 178);">6$/heure</span>. Avec la <span style="color:rgb(0, 209, 178);">formule Bulk</span>, le tarif des minutes supplémentaires est de <span style="color:rgb(0, 209, 178);">3$/heure</span>',
    video_6:"",

    title_7: 'La transcription ne se traduit pas, comment résoudre ce problème ?',
    desc_7:'Veuillez vous assurer que vous avez sélectionné la langue correcte lors du téléchargement de la vidéo. Vous devez choisir la langue correspondant à la langue parlée dans la vidéo.',
    video_7:"",

    title_8: 'J’ai téléchargé un fichier de moins de 5 Go, pourquoi ai-je le message <span style="color:rgb(230, 118, 117);">Taille de la vidéo trop grande</span> ?',
    desc_8:'La limite de taille de 5 Go est basée sur le format MP4. Lors de l’importation d’une vidéo dans un format autre que MP4, la conversion en MP4 peut augmenter la taille à plus de 5 Go. Veuillez télécharger une vidéo au format MP4 de moins de 5 Go pour éviter ce message.',
    video_8:"",

    title_9: 'Que signifient les messages d’erreur et comment puis-je les résoudre ?',
    desc_9:'<span style="color:rgb(230, 118, 117);">Limite d’essai dépassée</span> : Veuillez acheter des minutes ou souscrire un abonnement pour continuer à utiliser le service.<br><span style="color:rgb(230, 118, 117);">Pas assez de minutes</span> : Achetez plus de minutes ou souscrivez un abonnement puis réessayez.<br><span style="color:rgb(230, 118, 117);">Le lien YouTube a échoué, veuillez réessayer</span> : La cause la plus fréquente est que la vidéo YouTube fournie a été téléchargée récemment, veuillez attendre au moins 24 heures avant de tenter de la transcrire à nouveau.',
    video_9:"",

    title_10: 'Comment intégrer Taption avec Zoom ?',
    desc_10:'Veuillez consulter le <a href="https://www.taption.com/blog/en/zoom-tutorial-en" rel="noreferrer" target="_blank">tutoriel pas à pas</a>',
    video_10:"",

    title_11: 'J’ai d’autres questions, que dois-je faire ?',
    desc_11:'Veuillez nous contacter à <span style="color:rgb(0, 209, 178);">help@taption.com</span>',
    video_11:"",

    zoom_title:'Tutoriel pas à pas pour intégrer Taption + Zoom',
    zoom_install_desc:'Vous pouvez maintenant connecter votre compte Zoom à votre compte Taption. Après ce tutoriel, vous pourrez transcrire vos enregistrements Zoom directement depuis Taption.',
    zoom_step_1:'Cliquez sur "Télécharger" après vous être connecté à votre compte',
    zoom_step_2:'Choisissez "Zoom" dans la fenêtre contextuelle',
    zoom_step_3:'Connectez-vous avec votre compte Zoom et autorisez',
    zoom_step_4:'Choisissez votre enregistrement dans la liste',
    zoom_step_5:'Configurez vos paramètres, puis vous êtes prêt à télécharger et transcrire',
    zoom_uninstall_title:'Désinstaller l’intégration Taption + Zoom',
    zoom_uninstall_desc:'Pour désinstaller Zoom de Taption, allez dans <strong>Compte</strong> → <strong>Applications</strong> → <strong>Désinstaller</strong><br>Si vous avez d’autres questions, veuillez nous envoyer un courriel à <span style="color:rgb(0, 209, 178);">help@taption.com</span>',

    webex_title:'Tutoriel pas à pas pour intégrer Taption + Webex',
    webex_install_desc:'Vous pouvez maintenant connecter votre compte Webex à votre compte Taption. Après ce tutoriel, vous pourrez transcrire vos enregistrements Webex directement depuis Taption.',
    webex_step_1:'Cliquez sur "Télécharger" après vous être connecté à votre compte',
    webex_step_2:'Choisissez "Webex" dans la fenêtre contextuelle',
    webex_step_3:'Connectez-vous avec votre compte Webex et autorisez',
    webex_step_4:'Choisissez votre enregistrement dans la liste',
    webex_step_5:'Configurez vos paramètres, puis vous êtes prêt à télécharger et transcrire',
    webex_uninstall_title:'Désinstaller l’intégration Taption + Webex',
    webex_uninstall_desc:'Pour désinstaller Webex de Taption, allez dans <strong>Compte</strong> → <strong>Applications</strong> → <strong>Désinstaller</strong><br>Si vous avez d’autres questions, veuillez nous envoyer un courriel à <span style="color:rgb(0, 209, 178);">help@taption.com</span>',
}
