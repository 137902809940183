module.exports = {
    convert_to_text_title_meta: "Automatische TXT zu SRT-Untertitelkonvertierung mit Zeitstempeln",
    convert_to_text_desc_meta: "Laden Sie Ihr vorhandenes Transkript hoch und verwenden Sie unseren Online-Untertitelgenerator, um automatisch Zeitstempel hinzuzufügen. Konvertieren Sie TXT schnell in SRT. Testen Sie es kostenlos – jetzt registrieren!",
    convert_to_text_title: "Automatische TXT-zu-SRT-Untertitelkonvertierung",
    convert_to_text_desc: "Laden Sie Ihr vorhandenes Transkript hoch und nutzen Sie unseren Untertitelgenerator, um automatisch Zeitstempel hinzuzufügen und es zu formatieren. Konvertieren Sie TXT schnell in SRT.",
    start_your_trial: "Jetzt ausprobieren!",
    hero_img:"./09_English.webp",

    step_one: "Wählen Sie die Audio-/Videodatei für Ihr Transkript",
    step_one_img: "./select_source_en.webp",
    step_one_desc: "Wählen Sie zunächst die Audio-/Videodatei aus, die Ihrem Transkript entspricht. Das Transkript und die Videosprachen müssen identisch sein.",

    step_two: "Importieren Sie Ihr Transkript",
    step_two_img: "./transcripts_subtitles_step02_en.webp",
    step_two_desc: "Wählen Sie im Fenster <strong>Transkriptionsmethode</strong> <span style=\"color:#3ccebd\">Textdatei importieren</span>. Wählen Sie für <strong>Ausgabeformat wählen</strong> <span style=\"color:#3ccebd\">Untertitel</span>.",

    step_three: "Bearbeiten und exportieren",
    step_three_img: "./export_srt_step03_en.webp",
    step_three_desc: "Betreten Sie die Bearbeitungsplattform, um Untertitel zu bearbeiten und zu exportieren.",

    feature_tutorial_title: 'Konvertieren Sie Ihren Text in Untertitel',
    feature_tutorial_desc: 'Ob Sie ein Transkript, einen Artikel oder andere Textinhalte haben, laden Sie es einfach auf die Taption-Plattform hoch, um schnell unseren Untertitelgenerator zu nutzen. Er konvertiert Ihren Text automatisch in videokompatible Untertitel und führt die TXT-zu-SRT-Konvertierung durch. Mit unserem Untertitelgenerator vermeiden Sie manuelle Untertitel-Eingaben und zeitraubende Zeitachsenanpassungen. Unser Tool bietet hohe Genauigkeit und Konsistenz. Außerdem können Sie Untertitel problemlos in über 40 Sprachen übersetzen und so den unterschiedlichen Bedürfnissen eines globalen Publikums gerecht werden.',
    feature_tutorial_poster_url: './transcripts_to_subtitles_thumbnail_en.webp',
    feature_tutorial_youtube: 'https://www.youtube.com/embed/cMc71uumga4?si=Asfp-N2yjNahp_M5&autoplay=1',

    feature_extra_title: "Integrierte Anwendungen der Transkript-zu-Untertitel-Konvertierung",
    feature_extra_desc: "<ul><li><strong>Video-Untertitelerstellung:</strong> Wenn Sie über Textinhalte für Ihr Video verfügen, wie Skripte oder Dialoge, können Sie diese Texte schnell in Videountertitel konvertieren.</li><li><strong>Bildungs- und Schulungsmaterialien:</strong> Für Lehrkräfte oder Trainer können Sie Lehrinhalte in Untertitel umwandeln, um das Verständnis und die Notizen der Schüler zu erleichtern. Dies eignet sich besonders für Online-Kurse oder Schulungsprogramme.</li><li><strong>Verbessern Sie SEO:</strong> Durch die Konvertierung von Videoinhalten in Textuntertitel können Suchmaschinen Ihre Inhalte besser indexieren und so die Sichtbarkeit Ihrer Website oder Videos erhöhen.</li></ul>",

    feature_what_title: "Häufig gestellte Fragen",
    feature_what_desc: "<ul><li><h2>Kann die hochgeladene TXT-Datei automatisch formatiert und mit Zeitstempeln versehen werden, um verschiedene Anforderungen zu erfüllen?</h2><br>Ja, das kann sie. Wir bieten drei Formatierungsmethoden an:<ul><li>Automatische Untertitelsegmentierung: Verwendet die Segmentierungsfunktion des Untertitelgenerators, um die besten Segmentierungspunkte basierend auf dem Videoinhalt zu bestimmen.</li><li>Segmentierung durch Sprecherunterscheidung: Geeignet für Sitzungsprotokolle oder Podcasts, die auf verschiedene Sprecher segmentiert werden.</li><li>Segmentierung basierend auf dem ursprünglichen TXT-Dateiformat: Segmentiert gemäß dem vorhandenen Format in der bereitgestellten Textdatei.</li></ul><br><br></li>   <li><h2>Ich habe keine TXT-Datei. Kann das System automatisch ein Transkript oder Untertitel für mich erstellen?</h2><br>Ja, das kann es. Wählen Sie beim Hochladen Ihrer Videodatei die Option <strong>Automatische Transkription</strong> unter <strong>Transkriptionsmethode</strong>. Unser KI-Untertitelgenerator konvertiert Ihren Videoinhalt automatisch in Untertitel. Weitere Informationen finden Sie unter <a href=\"https://www.taption.com/add-subtitles\">KI-Untertitelgenerator</a> oder <a href=\"https://www.taption.com/convert-to-text\">Video in Text umwandeln</a>.<br><br></li>  <li><h2>Kann die TXT-Datei nach dem Hinzufügen von Zeitstempeln in andere Sprachen übersetzt werden?</h2><br>Ja, das kann sie. Nach dem Hinzufügen von Zeitstempeln zur bereitgestellten TXT-Datei können Sie aus über 40 Sprachen oben rechts auf der Bearbeitungsplattform auswählen, in die Sie übersetzen möchten. Dazu gehören Englisch-Japanisch, Englisch-Vietnamesisch, Englisch-Chinesisch und mehr. Weitere Informationen zur Übersetzung finden Sie unter <a href=\"https://www.taption.com/translate\">Automatische Videoübersetzung</a>.</li></ul>"
}
