module.exports = {
    convert_mp3_text:'Konvertieren Sie Ihr MP3 in Text',
    convert_mp3_desc:'Wir sind der beste Online-Konverter für Sprache-zu-Text-Audiodateien. Unsere fortschrittliche KI-Technologie liefert Ihnen schnelle und präzise Transkriptionen zu geringen Kosten.',
    convert_mp3_now:'Konvertieren Sie Ihr MP3 jetzt in Text',
    common_questions_title: 'Häufig gestellte Fragen',
    convert_button_desc1:'15 Minuten kostenlos',
    convert_button_desc2:'Keine Kreditkarte erforderlich',
    question1:'Wie kann ich mein Video transkribieren?',
    question2:'Wie kann ich Untertitel zu meinem Video hinzufügen?',
    question2_desc:'Eingebettete, fest codierte Untertitel',
    question3:'Wie kann ich mein Video übersetzen?',
    question4:'Wie kann ich die Genauigkeit mit dem KI-Wörterbuch erhöhen?',
    question5:'Wie bearbeite ich mein Video, indem ich den Text bearbeite?',
    home:'Startseite',
    transcribe_now:'Jetzt transkribieren',
    taption:'Taption - Automatisch Transkriptionen, Übersetzungen und Untertitel erstellen',
    title:'Automatisch Transkriptionen, Übersetzungen und Untertitel erstellen | Taption',
    content_title:'Automatisch Transkriptionen, Übersetzungen und Untertitel erstellen | Taption',
    content_description:'Umfangreiche Plattform, die Ihnen hochwertige Transkriptionen und Untertitel für Videos bietet',
    cover_image_url:'https://www.taption.com/taption-cover-en.jpg',
    site_url:'https://www.taption.com',
    site_name:'Taption'
}
