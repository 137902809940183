module.exports = {
    convert_to_text_title:"Ubah audio menjadi video",
    convert_to_text_desc:"Gunakan file audio Anda dan gambar untuk diubah menjadi video MP4 dengan teks",
    start_your_trial:"Mulai uji coba gratis Anda!",
    hero_img:"./05_English.webp",

    step_one:"Ubah audio menjadi teks",
    step_one_img:"./customized_step1_en.webp",
    step_one_desc:"Unggah audio Anda dan pilih segmentasi setiap bagian untuk \"Teks/Subtitel\"",
    
    step_two:"Unggah gambar kustom",
    step_two_img:"./customized_step2_en.webp",
    step_two_desc:"Di platform pengeditan, unggah gambar Anda di bawah Ekspor → File Video MP4",

    step_three:"Ekspor video",
    step_three_img:"./customized_step3_en.webp",
    step_three_desc:"Ekspor video kustom Anda!",

    feature_tutorial_title:'Cara mengubah audio Anda menjadi video',
    feature_tutorial_desc:'Jadi, Anda memiliki file audio dan ingin mengonversinya menjadi video. Ini mungkin tampak seperti tugas sederhana – tapi itu lebih dari itu! Ini adalah langkah cerdas yang bisa memperluas audiens konten Anda secara signifikan. Platform pengeditan kami yang mudah dinavigasi membuat seluruh proses sesederhana dan senyaman mungkin. <br><br>Tebak apa? Kami memiliki tutorial terperinci yang siap memandu Anda, jadi jangan ragu untuk memeriksanya agar lebih memahami cara memulai.',
    feature_tutorial_poster_url:'./audio_to_video_tutorial_poster_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/efDzKWNMgxw?autoplay=1',

    feature_extra_title:"Mengapa Anda harus mengubah file audio Anda menjadi video MP4 dengan teks di layar?",
    feature_extra_desc:"Pernahkah Anda berpikir untuk membawa file audio Anda ke level berikutnya dengan mengonversinya menjadi video MP4 lengkap dengan teks atau subtitle? Inilah alasannya mengapa ini adalah langkah yang cerdas: <ul><li><strong>Pemanfaatan Konten:</strong> Salah satu keuntungannya adalah kesempatan untuk menggunakan kembali konten Anda di berbagai platform media sosial. Ini memperluas jangkauan Anda dan memaksimalkan penggunaan dari apa yang sudah Anda buat.</li><li><strong>Interaksi Unik:</strong> Penambahan teks atau subtitle adalah cara segar dan inklusif untuk terhubung dengan audiens konten Anda.</li><li><strong>Jangkau Dunia:</strong> Saat Anda mengonversi audio menjadi video, Anda tidak hanya mengubah format - Anda membuat konten Anda tersedia untuk audiens di seluruh dunia. Dengan transkripsi dan subtitle dalam campuran, pesan Anda tidak mengenal batas.</li></ul>Dengan memanfaatkan manfaat ini, Anda meningkatkan kualitas keseluruhan konten Anda. Ini adalah alasan yang cukup bagus untuk mengonversi audio Anda menjadi video, bukan begitu?"
}
