module.exports = {
    convert_mp3_text:'Ubah MP3 Anda menjadi Teks',
    convert_mp3_desc:'Kami adalah konverter audio ke teks online terbaik, teknologi AI kami yang canggih menyediakan transkripsi Anda dengan cepat dan akurat dengan biaya yang terjangkau.',
    convert_mp3_now:'Ubah MP3 Anda menjadi Teks sekarang',
    common_questions_title: 'Pertanyaan yang Sering Diajukan',
    convert_button_desc1:'15 menit gratis',
    convert_button_desc2:'Tanpa memerlukan kartu kredit',
    question1:'Bagaimana cara mentranskripsikan video saya?',
    question2:'Bagaimana cara menambahkan subtitle ke video saya?',
    question2_desc:'Subtitle yang dibakar, hard-coded',
    question3:'Bagaimana cara menerjemahkan video saya?',
    question4:'Bagaimana meningkatkan akurasi menggunakan Kamus AI?',
    question5:'Bagaimana cara mengedit video saya dengan mengedit teks?',
    home:'Beranda',
    transcribe_now:'Transkripsikan sekarang',
    taption:'Taption - Buat transkripsi, terjemahan, dan subtitle otomatis',
    title:'Buat transkripsi, terjemahan, dan subtitle otomatis | Taption',
    content_title:'Buat transkripsi, terjemahan, dan subtitle otomatis | Taption',
    content_description:'Platform lengkap yang memberi Anda transkripsi dan subtitle berkualitas tinggi untuk video',
    cover_image_url:'https://www.taption.com/taption-cover-en.jpg',
    site_url:'https://www.taption.com',
    site_name:'Taption'
}
