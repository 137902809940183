module.exports = {
  'en-US': 'Englisch (USA)',
  'en-GB': 'Englisch (Vereinigtes Königreich)',
  'en-AU': 'Englisch (Australien)',
  'zh-TW': 'Chinesisch (Taiwan)',
  'zh': 'Chinesisch (China)',
  'yue-Hant-HK-TW-zh': 'Kantonesisch (Traditionelles Chinesisch)',
  'yue-Hant-HK-zh': 'Kantonesisch (Vereinfachtes Chinesisch)',
  'yue-Hant-HK-TW': 'Kantonesisch (Traditionelles Kantonesisch)',
  'yue-Hant-HK': 'Kantonesisch (Vereinfachtes Kantonesisch)',
  'es-ES': 'Spanisch (Spanien)',
  'fr-FR': 'Französisch (Frankreich)',
  'de-DE': 'Deutsch (Deutschland)',
  'it-IT': 'Italienisch (Italien)',
  'pt-PT_pt-BR': 'Portugiesisch (Portugal, Brasilien)',
  'nl-BE': 'Niederländisch (Belgien)',
  'hi-IN': 'Hindi (Indien)',
  'ja-JP': 'Japanisch (Japan)',
  'ko-KR': 'Koreanisch (Südkorea)',
  'vi-VN': 'Vietnamesisch (Vietnam)',
  'ar-EG': 'Arabisch (Ägypten)',
  'hy-AM': 'Armenisch (Armenien)',
  'bs-BA': 'Bosnisch (Bosnien und Herzegowina)',
  'bg-BG': 'Bulgarisch (Bulgarien)',
  'my-MM': 'Birmanisch (Myanmar)',
  'ca-ES': 'Katalanisch (Spanien)',
  'hr-HR': 'Kroatisch (Kroatien)',
  'cs-CZ': 'Tschechisch (Tschechien)',
  'da-DK': 'Dänisch (Dänemark)',
  'tl-PH': 'Filipino (Philippinen)',
  'fi-FI': 'Finnisch (Finnland)',
  'gl-ES': 'Galizisch (Spanien)',
  'el-GR': 'Griechisch (Griechenland)',
  'gu-IN': 'Gujarati (Indien)',
  'iw-IL': 'Hebräisch (Israel)',
  'hu-HU': 'Ungarisch (Ungarn)',
  'id-ID': 'Indonesisch (Indonesien)',
  'lv-LV': 'Lettisch (Lettland)',
  'lt-LT': 'Litauisch (Litauen)',
  'mk-MK': 'Mazedonisch (Nordmazedonien)',
  'ms-MY': 'Malaiisch (Malaysia)',
  'no-NO': 'Norwegisch (Norwegen)',
  'pl-PL': 'Polnisch (Polen)',
  'ro-RO': 'Rumänisch (Rumänien)',
  'ru-RU': 'Russisch (Russland)',
  'sk-SK': 'Slowakisch (Slowakei)',
  'sl-SI': 'Slowenisch (Slowenien)',
  'sv-SE': 'Schwedisch (Schweden)',
  'ta-IN': 'Tamilisch (Indien)',
  'te-IN': 'Telugu (Indien)',
  'th-TH': 'Thailändisch (Thailand)',
  'tr-TR': 'Türkisch (Türkei)',
  'uk-UA': 'Ukrainisch (Ukraine)',
  'uz-UZ': 'Usbekisch (Usbekistan)'
}
