import Vue from 'vue'
import VueRouter from 'vue-router'
const NavBar = () => import('@/components/NavBar')

Vue.use(VueRouter)
const allowedLanguages =  process.env.VUE_APP_BROWSER_SUPPORTED_LANGUAGE.split(',');
const routes = [
  { 
    path: '/', 
    name: 'taption',
    components: {
      default: () => import(/* webpackChunkName: "detail" */ '../views/LandingPageEnglish.vue'),
      navbar: NavBar
    }
  },
  {
    path: '*/en-US',
    name: 'taptionen',
    redirect: () => {
      return '/';
    }
  },
  {
    path: '/zh',
    name: 'taptiontw',
    components: {
      default: () => import(/* webpackChunkName: "detail" */ '../views/LandingPageZh.vue'),
      navbar: NavBar
    }
  },
  {
    path: '/zh-CN',
    name: 'taptioncn',
    components: {
      default: () => import(/* webpackChunkName: "detail" */ '../views/LandingPageZhCn.vue'),
    //  default: () => import(/* webpackChunkName: "detail" */ './views/LandingPageEnglish.vue'),
      navbar: NavBar
    }
  },
  {
    path: '/jp',
    name: 'taptionjp',
    components: {
      default: () => import(/* webpackChunkName: "detail" */ '../views/LandingPageJp.vue'),
    //  default: () => import(/* webpackChunkName: "detail" */ './views/LandingPageEnglish.vue'),
      navbar: NavBar
    }
  },
  {
    path: '/kr',
    name: 'taptionkr',
    components: {
      default: () => import(/* webpackChunkName: "detail" */ '../views/LandingPageKr.vue'),
    //  default: () => import(/* webpackChunkName: "detail" */ './views/LandingPageEnglish.vue'),
      navbar: NavBar
    }
  },

  {
    path: '/es',
    name: 'taptiones',
    components: {
      default: () => import(/* webpackChunkName: "detail" */ '../views/LandingPageEs.vue'),
    //  default: () => import(/* webpackChunkName: "detail" */ './views/LandingPageEnglish.vue'),
      navbar: NavBar
    }
  },
  {
    path: '/de',
    name: 'taptionde',
    components: {
      default: () => import(/* webpackChunkName: "detail" */ '../views/LandingPageDe.vue'),
    //  default: () => import(/* webpackChunkName: "detail" */ './views/LandingPageEnglish.vue'),
      navbar: NavBar
    }
  },
  {
    path: '/fr',
    name: 'taptionfr',
    components: {
      default: () => import(/* webpackChunkName: "detail" */ '../views/LandingPageFr.vue'),
    //  default: () => import(/* webpackChunkName: "detail" */ './views/LandingPageEnglish.vue'),
      navbar: NavBar
    }
  },

  {
    path: '/id',
    name: 'taptionid',
    components: {
      default: () => import(/* webpackChunkName: "detail" */ '../views/LandingPageId.vue'),
    //  default: () => import(/* webpackChunkName: "detail" */ './views/LandingPageEnglish.vue'),
      navbar: NavBar
    }
  },
  {
    path: '/pt',
    name: 'taptionpt',
    components: {
      default: () => import(/* webpackChunkName: "detail" */ '../views/LandingPagePt.vue'),
    //  default: () => import(/* webpackChunkName: "detail" */ './views/LandingPageEnglish.vue'),
      navbar: NavBar
    }
  },
  {
    path: '/pricing',
    name: 'pricing',
    components: {
      default: () => import(/* webpackChunkName: "detail" */ '../views/Pricing.vue'),
      navbar: NavBar
    }
  },
  
  {
    path: '/pricing/:language',
    name: 'pricinglang',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/Pricing.vue'),
      navbar: NavBar
    },
    beforeEnter: (to, from, next) => {
      // Check if the language parameter is valid
      const { language } = to.params;
      if (language && allowedLanguages.includes(language.toLowerCase())) {
        next(); // If valid, proceed to the route
      } else {
        next({ name: 'error' }); // If invalid, redirect to the error page
      }
    }
  },
  {
    path: '/faq',
    name: 'faq',
    components: {
      default: () => import(/* webpackChunkName: "detail" */ '../views/FAQ.vue'),
    //  default: () => import(/* webpackChunkName: "detail" */ './views/LandingPageEnglish.vue'),
      navbar: NavBar
    }
  },
  {
    path: '/faq/:language',
    name: 'faqlang',
    components: {
      default: () => import(/* webpackChunkName: "detail" */ '../views/FAQ.vue'),
    //  default: () => import(/* webpackChunkName: "detail" */ './views/LandingPageEnglish.vue'),
      navbar: NavBar
    },
    beforeEnter: (to, from, next) => {
      // Check if the language parameter is valid
      const { language } = to.params;
      if (language && allowedLanguages.includes(language.toLowerCase())) {
        next(); // If valid, proceed to the route
      } else {
        next({ name: 'error' }); // If invalid, redirect to the error page
      }
    }
  },

  {
    path: '/privacy',
    name: 'privacy',
    
    component: () => import(/* webpackChunkName: "about" */ '../views/Privacy.vue')
   // beforeEnter() {window.location.href =  'https://app.termly.io/document/privacy-policy/2275745e-4237-48ce-83a9-917dfd6e7b3a'}
  },
  {
    path: '/terms',
    name: 'terms',
    
     component: () => import(/* webpackChunkName: "about" */ '../views/Terms.vue')
   // beforeEnter() {window.location.href =  'https://app.termly.io/document/terms-of-use-for-saas/64d082f9-4de8-42f5-8dee-84328191e63f'}
  },

  // {
  //   path: '/zoomtutorial',
  //   name: 'zoomtutorial',
  //   components: {
  //     default: () => import(/* webpackChunkName: "about" */ '../views/ZoomTutorial.vue'),
  //     navbar: NavBar
  //   }
  // },
  // {
  //   path: '/zoomtutorial/:language',
  //   name: 'zoomtutoriallang',
  //   components: {
  //     default: () => import(/* webpackChunkName: "about" */ '../views/ZoomTutorial.vue'),
  //     navbar: NavBar
  //   }
  // },
  // {
  //   path: '/webextutorial',
  //   name: 'webextutorial',
  //   components: {
  //     default: () => import(/* webpackChunkName: "about" */ '../views/WebexTutorial.vue'),
  //     navbar: NavBar
  //   }
  // },
  // {
  //   path: '/webextutorial/:language',
  //   name: 'webextutoriallang',
  //   components: {
  //     default: () => import(/* webpackChunkName: "about" */ '../views/WebexTutorial.vue'),
  //     navbar: NavBar
  //   }
  // },
  {
    path: '/blog/',
    name: 'blog',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/Blog.vue'),
      navbar: NavBar
    }
  },
  {
    path: '/blog/:language',
    name: 'blogpostlang',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/Blog.vue'),
      navbar: NavBar
    },
    beforeEnter: (to, from, next) => {
      // Check if the language parameter is valid
      const { language } = to.params;
      if (language && allowedLanguages.includes(language.toLowerCase())) {
        next(); // If valid, proceed to the route
      } else {
        next({ name: 'error' }); // If invalid, redirect to the error page
      }
    }
  },
  {
    path: '/blog/:language/:post',
    name: 'blogpost',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/BlogPost.vue'),
      navbar: NavBar
    },
    beforeEnter: (to, from, next) => {
      // Check if the language parameter is valid
      const { language } = to.params;
      allowedLanguages.push('en');
      if (language && allowedLanguages.includes(language.toLowerCase())) {
        next(); // If valid, proceed to the route
      } else {
        next({ name: 'error' }); // If invalid, redirect to the error page
      }
    }
  },
  {
    path: '/convert-to-text',
    name: 'converttext',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/ConvertToText.vue'),
      navbar: NavBar
    }
  },
  {
    path: '/convert-to-text/:language',
    name: 'converttextlang',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/ConvertToText.vue'),
      navbar: NavBar
    },
    beforeEnter: (to, from, next) => {
      // Check if the language parameter is valid
      const { language } = to.params;
      if (language && allowedLanguages.includes(language.toLowerCase())) {
        next(); // If valid, proceed to the route
      } else {
        next({ name: 'error' }); // If invalid, redirect to the error page
      }
    }
  },
  {
    path: '/add-subtitles',
    name: 'addsubtitles',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/AddSubtitles.vue'),
      navbar: NavBar
    }
  }, {
    path: '/add-subtitles/:language',
    name: 'addsubtitleslang',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/AddSubtitles.vue'),
      navbar: NavBar
    },
    beforeEnter: (to, from, next) => {
      // Check if the language parameter is valid
      const { language } = to.params;
      if (language && allowedLanguages.includes(language.toLowerCase())) {
        next(); // If valid, proceed to the route
      } else {
        next({ name: 'error' }); // If invalid, redirect to the error page
      }
    }
  },
  {
    path: '/ai-analysis',
    name: 'aianalysis',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/AIAnalysis.vue'),
      navbar: NavBar
    }
  },
  {
    path: '/ai-analysis/:language',
    name: 'aianalysislang',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/AIAnalysis.vue'),
      navbar: NavBar
    },
    beforeEnter: (to, from, next) => {
      // Check if the language parameter is valid
      const { language } = to.params;
      if (language && allowedLanguages.includes(language.toLowerCase())) {
        next(); // If valid, proceed to the route
      } else {
        next({ name: 'error' }); // If invalid, redirect to the error page
      }
    }
  },
  {
    path: '/translate',
    name: 'translate',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/Translate.vue'),
      navbar: NavBar
    }
  },
  {
    path: '/translate/:language',
    name: 'translatelang',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/Translate.vue'),
      navbar: NavBar
    },
    beforeEnter: (to, from, next) => {
      // Check if the language parameter is valid
      const { language } = to.params;
      if (language && allowedLanguages.includes(language.toLowerCase())) {
        next(); // If valid, proceed to the route
      } else {
        next({ name: 'error' }); // If invalid, redirect to the error page
      }
    }
  },
  {
    path: '/speaker',
    name: 'speaker',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/Speaker.vue'),
      navbar: NavBar
    }
  },
  {
    path: '/speaker/:language',
    name: 'speakerlang',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/Speaker.vue'),
      navbar: NavBar
    },
    beforeEnter: (to, from, next) => {
      // Check if the language parameter is valid
      const { language } = to.params;
      if (language && allowedLanguages.includes(language.toLowerCase())) {
        next(); // If valid, proceed to the route
      } else {
        next({ name: 'error' }); // If invalid, redirect to the error page
      }
    }
  },
  {
    path: '/audio-to-video',
    name: 'audiotovideo',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/AudioToVideo.vue'),
      navbar: NavBar
    }
  },
  {
    path: '/audio-to-video/:language',
    name: 'audiotovideolang',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/AudioToVideo.vue'),
      navbar: NavBar
    },
    beforeEnter: (to, from, next) => {
      // Check if the language parameter is valid
      const { language } = to.params;
      if (language && allowedLanguages.includes(language.toLowerCase())) {
        next(); // If valid, proceed to the route
      } else {
        next({ name: 'error' }); // If invalid, redirect to the error page
      }
    }
  },
  {
    path: '/collaborate',
    name: 'collaborate',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/Collaborate.vue'),
      navbar: NavBar
    }
  },
  {
    path: '/collaborate/:language',
    name: 'collaboratelang',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/Collaborate.vue'),
      navbar: NavBar
    },
    beforeEnter: (to, from, next) => {
      // Check if the language parameter is valid
      const { language } = to.params;
      if (language && allowedLanguages.includes(language.toLowerCase())) {
        next(); // If valid, proceed to the route
      } else {
        next({ name: 'error' }); // If invalid, redirect to the error page
      }
    }
  },
  {
    path: '/transcripts-to-subtitles',
    name: 'transcriptstosubtitles',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/TranscriptsToSubtitles.vue'),
      navbar: NavBar
    }
  },
  {
    path: '/transcripts-to-subtitles/:language',
    name: 'transcriptstosubtitleslang',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/TranscriptsToSubtitles.vue'),
      navbar: NavBar
    },
    beforeEnter: (to, from, next) => {
      // Check if the language parameter is valid
      const { language } = to.params;
      if (language && allowedLanguages.includes(language.toLowerCase())) {
        next(); // If valid, proceed to the route
      } else {
        next({ name: 'error' }); // If invalid, redirect to the error page
      }
    }
  },
  {
    path: '/transcripts-to-speakers',
    name: 'transcriptstospeakers',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/TranscriptsToSpeakers.vue'),
      navbar: NavBar
    }
  },
  {
    path: '/transcripts-to-speakers/:language',
    name: 'transcriptstospeakerslang',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/TranscriptsToSpeakers.vue'),
      navbar: NavBar
    },
    beforeEnter: (to, from, next) => {
      // Check if the language parameter is valid
      const { language } = to.params;
      if (language && allowedLanguages.includes(language.toLowerCase())) {
        next(); // If valid, proceed to the route
      } else {
        next({ name: 'error' }); // If invalid, redirect to the error page
      }
    }
  },
  { 
    path: '/error', 
    name: 'error',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/ErrorPage.vue'),
      navbar: NavBar
    }
  },
  { 
    path: '*', 
    name: 'errorpage',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/ErrorPage.vue'),
      navbar: NavBar
    }
  },
  // {
  //   path: '/',
  //   name: 'landing',
  //   component: LandingPageEnglish
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]
// const router = new VueRouter({
//   mode: 'history',
//   // scrollBehavior (to, from, savedPosition) {
//   //   return { x: 0, y: 0 }
//   // },
//   routes: [
//   {
//     path: '/',
//     name: 'landing',
//     component: {
//       default: LandingPageEnglish,
//       // navbar: NavBar
//     }
//   },
//   {
//     path: '/about',
//     name: 'about',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
//   }
// ]})

const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes
})

export default router
// export {router}; 
