module.exports = {
    convert_to_text_title:"Sprecherbeschriftung für Videos",
    convert_to_text_desc:"Automatische Beschriftung für Ihre Videos und Audiodateien.",
    start_your_trial:"Starten Sie Ihre kostenlose Testversion!",
    hero_img:"./04_English.webp",

    step_one:"Laden Sie Ihre Datei hoch",
    step_one_img:"./select_source_en.webp",
    step_one_desc:"Laden Sie Ihre Datei aus einer der folgenden Quellen hoch: Gerät, YouTube, Google Drive oder Zoom.",
    
    step_two:"Konfigurieren für Sprecherbeschriftung",
    step_two_img:"./speaker_step2_en.webp",
    step_two_desc:"Stellen Sie sicher, dass Sie die Sprache wählen, die dem Video entspricht, und wählen Sie „Sprecher“ für die Segmentierung der einzelnen Abschnitte.",

    step_three:"Fertigstellen",
    step_three_img:"./speaker_step3_en.webp",
    step_three_desc:"Nach Abschluss der Transkription können Sie die Sprecher auf der Bearbeitungsplattform anpassen.",

    feature_tutorial_title:'Einfache Sprecherbeschriftung für Ihre Videos hinzufügen',
    feature_tutorial_desc:'Sind Sie bereit, Klarheit in Ihre Audio- oder Videodateien zu bringen? Unsere automatische Sprecherbeschriftungsfunktion erleichtert es Ihren Zuschauern, verschiedene Sprecher zu erkennen und zu markieren. Dadurch bleibt Ihr Inhalt immer übersichtlich und leicht verständlich.<br><br>Möchten Sie sehen, wie es gemacht wird? Wir haben ein praktisches Video-Tutorial, das Sie durch jeden Schritt führt.',
    feature_tutorial_poster_url:'./speaker_labeling_tutorial_poster_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/l5xkhJZy5ww?autoplay=1',

    feature_extra_title:"Warum macht die Transkription von Besprechungen und Webkonferenzen Sinn?",
    feature_extra_desc:"Sie denken vielleicht, dass die Transkription von Besprechungen nur zur Dokumentation dient. Aber denken Sie nochmal nach. Schauen wir uns die verschiedenen Möglichkeiten an, wie Transkriptionen Ihre Inhalte aufwerten können: <ul><li><strong>Informationsspeicherung:</strong> Verpassen Sie keinen Takt. Erfassen Sie alle Details Ihrer Besprechung, um sicherzustellen, dass nichts durch die Lücken geht. </li><li><strong>SEO-Optimierung:</strong> Integrieren Sie relevante Keywords in Ihre Transkriptionen und verbessern Sie Ihre SEO-Rankings.</li><li><strong>Inhaltszugänglichkeit:</strong> Mit der automatischen Transkription protokollieren Sie nicht nur Wörter, sondern machen sie auch einem breiteren Publikum zugänglich - einschließlich Hörgeschädigter.</li><li><strong>ROI:</strong> Steigern Sie Ihren Return on Investment, indem Sie den Teilnehmern eine alternative Möglichkeit bieten, die besprochenen Ideen oder Produkte zu überdenken, was möglicherweise zu Konversionen führt.</li></ul>Was gewinnen Sie also? Sie steigern die Zuschauerbindung und machen Ihren Inhalt integrativer. Nutzen Sie diese Vorteile und machen Sie Ihre Videos und Besprechungen so professionell und nützlich wie möglich."
}
