module.exports = {
    enjoy_your_free_trial: '您的第一个上传免费!',
    dialog_title: '取得更多分钟',
    subscription_title: '获取订阅',
    pay: '确认',
    error_unauthenticate: '我们无法验证您的付款方式，请换一张信用卡',
    error_payment_creation: '无法完成付款，或与我们客服人员联系',
    error_general: '无法完成与服务器的连接，请刷新并重试',
    error_timeout: '连接超时，请刷新并重试',

    expired_card:'此卡片已过期，请换一张卡后再尝试',
    do_not_honor:'信用卡被拒绝，请联络您的银行或换一​​张卡后再尝试',
    insufficient_funds:'此卡片余额不足以支付此款项，请确认剩余金额或换一张卡后再尝试',
    invalid_info: '信用卡资料输入有误，请核对后再尝试付款',

    success: '成功',
    success_description: '您的帐户已充值<br/> <b class ="number-of-minutes"> {0}分钟</ b>',
    success_description_bonus: '额外赠送<b class ="extra-minutes"> {1}分钟</b> <br/>您的帐户已充值<br/> <b class ="number-of-minutes"> {0}分钟</b>',
    success_subscription_description: '您已成为我们高级订阅用户',
    success_corp_subscription_description: '您已成为我们大量订阅用户',
    success_subscription_bonus: '额外赠送 <b class ="extra-minutes"> {0}分钟</b>',

    cancel_subscription_confirmation: '确定要取消订阅吗？ ',
    cancel_subscription_desc:' 订阅分钟数将会立即失效<br>前 <span style="color:rgb(246, 102, 101)">1</span> 个以外的标签将无法使用',
    cancel_subscription_confirm:'我确认要取消订阅并放弃所有订阅分钟',
    reactivate_subscription_confirmation: '确定要重启订阅吗？',
    cacnel_subscription_description: '在该期间结束之前，您仍然为高级用户',
    cacnel_subscription: '您的高级订阅已被取消',
    cancel_corp_subscription: '您的大量订阅已被取消',
    reactive_subscription: '您的高级订阅已被重启!',
    active_until:'有效至',
    success_translation_description: '您的翻译已经完成！ ',
    yes: '是',
    no: '不',
    continue: '继续',
    save: '保存',
    cancel: '取消',
    processing_payment: '处理付款中',
    error_edit: '无法完成卡更新，请联系支持人员',
    delete_ok: '删除',
    delete_cancel: '取消',
    billing_address: '信用卡注册地址',
    how_many_hours: '多少小时？ ',
    credit_card: '信用卡',
    summary: '总共金额(美金)',
    save_payment: '保存此卡片',
    processing_payment_description: '这可能需要几分钟...不要刷新',
    //credit_card_manage:'您可以在帐户页面中管理您的信用卡。 ',
    credit_card_manage:'我们使用Stripe来处理信用卡相关作业, 您的个资将得到最高保障',
    credit_card_secured:'确保您的付款安全,此交易过程加密',
    understand_billing:'我允许此付款',
    processing_saving:'更新信用卡中',
    // field
    full_name: '姓名',
    name: '名称',
    address: '地址',
    city: '城市',
    state: '州/省',
    zip: '邮编',
    // validation
    max_length_field: '{0}的长度最多为{1}个字符',
    required_field: '{0}是必需的',
    delete_title: '要移除此信用卡吗',
  
    title_basic: '影片长度计价',
    title_premium: '高级订阅',
    title_custom: '客制化',
    title_corp: '大量订阅',

    basic_feature5: '充值<span style="color: #00d1b2;">60分钟</span>',
   // basic_feature1: '自动将影音档转成文字',
  //  basic_feature2: '支援39种语言',
   // basic_feature3: '精准时间对照编辑器',
    //basic_feature4: '50多種語言翻译',
    basic_feature4_tooltip: '',
    // basic_feature7: '个人AI辞典',
    // basic_feature6: '可供商业相关之用途',
  
    premium_feature1: '每个月赠送{0}<span style="color: #00d1b2;">分钟</span><br><span class="price-notes">(可跨月累积，有效至订阅取消为止)</span>',
    // premium_feature1_tooltip: '此分钟没用完可以持续累积，有效到取消订阅为止。<br>相较于另外购买的分钟数，会优先使用此分钟。<br>另购买分钟不会因取消订阅而失效。',
   // premium_feature1_tooltip: '帐户不足100分钟每个月将自动补到100分钟。如超过100分钟，分钟数将维持不变。 ',
  // premium_feature1_tooltip: '第一次订阅将赠送你100分钟,限时优惠!',
    premium_feature2: '如需更多分钟, 可以优惠价格购买',
    // premium_feature3: '字幕崁入影片不扣分钟，无限制导出',
    premium_feature3_tooltip: '$2每个文本翻译',
    // premium_feature4: '分享给其他用户编辑',
    // premium_feature5: '夜间模式',
    // premium_feature6: '用字幕剪辑影片片段',
  
    buy: '购买分钟',
    activate: '启用',
    subscribe: '订阅',
    contact_us: '与我们联系',
  
    custom_feature1: '商业合作',
    custom_feature2: '群体订阅',
    custom_feature3: '意见回馈',
    custom_feature4: '错误回报',
  
    month: '月',
    per_hour: '每小时',
    per_month: '每个月',
    per_user_per_month:'{0}每位用户/月',

    how_get_free:'如何获得免费分钟?',
    payment_questions:'其他付费相关问题?',
    translate_remaining:'您目前剩余的影片分钟: {0}',
    translate_deduct:'翻译此影片将会扣除的分钟数: {0}',
    transcribe_deduct:'此影片转成文字将会扣除的分钟数: {0}',
    transcribe_warning:'请确认此影片总长度小于您目前的剩余的影片分钟，否则将无法顺利转档',
    mp4_deduct:'字幕崁入影片将会扣除的分钟数: {0}',
    cvc:'CVC是什么?',
    confirm_translate:'我确认要翻译',
    recurring_description:'＊ 每月自动扣款',
    recurring_description2:'＊ 可随时到『我的帐号』取消订阅',
    coupon_header:'优惠码',
    coupon_title:'请输入您的优惠码:',
    coupon_submit:'确认',
    processing_coupon: '激活優惠碼中...',
    coupon_empty: '请输入优惠码',
    coupon_expire: '优惠码已过期',
    coupon_notexist: '优惠码不存在',
    coupon_firsttimeonly: '优惠码仅限于新用户',
    member_slot_desc:'可加入其他Taption用户对于此订阅帐号的影片<br>来共享分钟，编辑，上传，阅读，导出。<br>并且可针对每个用户给予不同的权限。',
    credit_edit_title:'更改信用卡资料',
    payment_confirm_title:'金额确认',
    payment_confirm_desc:'款项金额{0}是以<span style="color:#2fc59f;font-weight:bold;">美金</span>计算',
    upgrade_contact_us:'商业合作，联盟行销，客制化方案或其他问题，请寄信到： <span style="color:#2fc59f">help@taption.com</span>'

  }