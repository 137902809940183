module.exports = {
    convert_to_text_title:"Transcrições para Oradores",
    convert_to_text_desc:"Adicione automaticamente rótulos de oradores às suas transcrições existentes",
    start_your_trial:"Experimente agora!",
    hero_img:"./10_English.webp",

    step_one:"Carregue o seu ficheiro de mídia",
    step_one_img:"./select_source_en.webp",
    step_one_desc:"Selecione o ficheiro de áudio ou vídeo que corresponde à transcrição que possui.",
    
    step_two:"Carregue o seu ficheiro de transcrição",
    step_two_img:"./transcripts_subtitles_step02_en.webp",
    step_two_desc:"Selecione <span style=\"color:#3ccebd\"><strong>Importar ficheiro de texto</strong></span> para <strong>Método de Transcrição</strong> e <span style=\"color:#3ccebd\"><strong>Rotulagem de orador</strong></span> para <strong>Selecione o formato de saída</strong>",

    step_three:"Edite e exporte",
    step_three_img:"./export_srt_step03_en.webp",
    step_three_desc:"Depois de completo, entre na plataforma de edição para atualizar os nomes dos oradores ou traduzir. Exporte quando estiver pronto! ",

    feature_tutorial_title:'Rotulagem automática de oradores para maior clareza',
    feature_tutorial_desc:'Acabaram-se os dias de confusão sobre quem disse o quê no seu conteúdo de áudio. Com o Taption, identificar vozes em podcasts, entrevistas ou discussões em grupo não poderia ser mais fácil – tudo graças ao nosso software de reconhecimento de oradores. Basta carregar a sua transcrição e deixar que a nossa tecnologia de deteção de oradores por IA faça o trabalho pesado.<br><br>O que exatamente a nossa tecnologia faz? Ela identifica e rotula as contribuições de cada orador com facilidade. Usando a nossa identificação de oradores automatizada, garante uma perfeita clareza em todos os seus vídeos, de forma que o seu público nunca fique confuso sobre quem está a falar ou tenha dificuldade em seguir a conversa.',
    feature_tutorial_poster_url:'./transcripts_to_subtitles_thumbnail_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/3DNDiW8jiDU?si=ZWqZnGBTdjUmVU0_&autoplay=1',

    feature_extra_title:"As vantagens da rotulagem clara de oradores",
    feature_extra_desc:"Em discussões com várias vozes, saber quem está a falar é essencial – molda a experiência do ouvinte. Com o Taption, obtém:<br><ul><li><strong>Diálogo Transparente:</strong> Os ouvintes podem facilmente seguir a conversa, pois cada voz é identificada claramente.</li><li><strong>Transcrições Elevadas:</strong> O seu conteúdo deixa uma impressão mais profissional, melhorando a imagem da sua empresa.</li><li><strong>Tempo Economizado:</strong> Imagine as horas que pode recuperar com uma ferramenta de rotulagem de áudio que acerta na identificação de oradores na primeira tentativa.</li></ul><br>Experimente os benefícios da identificação profissional de oradores com as ferramentas avançadas do Taption. Graças à nossa solução de transcrição de multi-oradores e atribuição de voz, pode ter a certeza de que os seus serviços de transcrição de reuniões e rotulagem de transcrições de entrevistas atingem os mais altos padrões de precisão e detalhe."
}
