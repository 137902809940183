module.exports = {
    convert_to_text_title:"Converter áudio em vídeo",
    convert_to_text_desc:"Pegue seu arquivo de áudio e uma imagem para converter em um vídeo MP4 com legendas",
    start_your_trial:"Comece o seu teste grátis!",
    hero_img:"./05_English.webp",

    step_one:"Converter áudio em texto",
    step_one_img:"./customized_step1_en.webp",
    step_one_desc:"Carregue seu áudio e escolha segmentar cada seção para \"Legendas\"",
    
    step_two:"Carregar imagem personalizada",
    step_two_img:"./customized_step2_en.webp",
    step_two_desc:"Na plataforma de edição, carregue sua imagem em Exportar → Arquivo de Vídeo MP4",

    step_three:"Exportar vídeo",
    step_three_img:"./customized_step3_en.webp",
    step_three_desc:"Exporte o seu vídeo personalizado!",

    feature_tutorial_title:'Como transformar o seu áudio em vídeo',
    feature_tutorial_desc:"Tem um arquivo de áudio e pensa em convertê-lo em vídeo. Isso pode parecer uma tarefa simples, mas é muito mais que isso! É uma estratégia inteligente que pode ampliar consideravelmente o alcance do seu conteúdo. Nossa plataforma de edição fácil de usar torna todo o processo simples e conveniente.<br><br>Quer uma ajuda? Temos um tutorial detalhado pronto para guiá-lo, então sinta-se à vontade para assistir e começar.",
    feature_tutorial_poster_url:'./audio_to_video_tutorial_poster_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/efDzKWNMgxw?autoplay=1',

    feature_extra_title:"Por que converter seu arquivo de áudio em um vídeo MP4 com texto na tela?",
    feature_extra_desc:"Já pensou em levar seus arquivos de áudio para o próximo nível convertendo-os em vídeos MP4 com legendas? Aqui está o motivo pelo qual essa é uma ideia inteligente: <ul><li><strong>Reutilização de Conteúdo:</strong> Um dos principais benefícios é a chance de reutilizar seu conteúdo em várias plataformas de redes sociais. Isso amplia seu alcance e maximiza o uso do que já foi criado.</li><li><strong>Interação Única:</strong> A adição de legendas é uma maneira inovadora e inclusiva de se conectar com a sua audiência de conteúdo. </li><li><strong>Alcance Global:</strong> Quando você converte áudio em vídeo, não está apenas mudando de formato – está tornando o conteúdo acessível para um público global. Com transcrições e legendas, sua mensagem não conhece fronteiras.</li></ul>Ao aproveitar esses benefícios, você aprimora seu conteúdo em geral. Que tal transformar seu áudio em vídeo?"
}
