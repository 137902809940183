module.exports = {
    convert_to_text_title:"Étiquetage des intervenants pour les vidéos",
    convert_to_text_desc:"Étiquetage automatique pour vos vidéos et audios.",
    start_your_trial:"Commencez votre essai gratuit!",
    hero_img:"./04_English.webp",

    step_one:"Téléchargez votre fichier",
    step_one_img:"./select_source_en.webp",
    step_one_desc:"Téléchargez votre fichier à partir de l'une des sources suivantes : Appareil, YouTube, Google Drive ou Zoom.",
    
    step_two:"Configurer pour l’étiquetage des intervenants",
    step_two_img:"./speaker_step2_en.webp",
    step_two_desc:"Assurez-vous de choisir la langue correspondant à la vidéo lors du téléchargement et sélectionnez « segmenter chaque section » pour les « intervenants »",

    step_three:"Compléter",
    step_three_img:"./speaker_step3_en.webp",
    step_three_desc:"Après la transcription, vous pourrez ajuster les intervenants dans la plateforme d’édition.",

    feature_tutorial_title:'Ajoutez facilement des étiquettes d’intervenants à vos vidéos',
    feature_tutorial_desc:'Prêt à apporter de la clarté à vos fichiers audio ou vidéo ? Notre fonctionnalité d’étiquetage automatique des intervenants permet à vos spectateurs d’identifier et de taguer facilement les différents intervenants. Cela aide à garder votre contenu clair et facile à suivre à tout moment.<br><br>Vous voulez voir comment ça fonctionne ? Nous avons un tutoriel vidéo qui peut vous guider à chaque étape.',
    feature_tutorial_poster_url:'./speaker_labeling_tutorial_poster_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/l5xkhJZy5ww?autoplay=1',

    feature_extra_title:"Pourquoi transcrire les réunions et webconférences est judicieux",
    feature_extra_desc:"Vous pourriez penser que la transcription des réunions sert seulement à garder un enregistrement. Détrompez-vous. Explorons les multiples façons dont les transcriptions peuvent améliorer votre contenu : <ul><li><strong>Rétention de l’information :</strong> Ne manquez rien. Capturez tous les détails de votre réunion pour être sûr de ne rien laisser passer. </li><li><strong>Optimisation SEO :</strong> Intégrez des mots-clés pertinents dans vos transcriptions et observez l’amélioration de votre référencement. </li><li><strong>Accessibilité du contenu :</strong> Avec la transcription automatique, vous ne consignez pas seulement des mots; vous les rendez accessibles à un public plus large, y compris les personnes malentendantes.</li><li><strong>ROI :</strong> Améliorez votre Retour sur Investissement en offrant aux participants un moyen alternatif de revoir les idées ou les produits discutés, ce qui peut entraîner des conversions.</li></ul>Alors, qu’y gagnez-vous ? Vous augmentez l’engagement de votre audience et rendez votre contenu plus inclusif. Il est temps de profiter de ces avantages pour rendre vos vidéos et réunions aussi professionnelles et utiles que possible."
}
