module.exports = {
    convert_to_text_title:"Etiquetado de oradores para vídeos",
    convert_to_text_desc:"Etiquetado automático para tus vídeos y audios.",
    start_your_trial:"¡Comienza tu prueba gratuita!",
    hero_img:"./04_English.webp",

    step_one:"Sube tu archivo",
    step_one_img:"./select_source_en.webp",
    step_one_desc:"Sube tu archivo desde una de las siguientes fuentes: dispositivo, YouTube, Google Drive o Zoom.",
    
    step_two:"Configura para etiquetado de oradores",
    step_two_img:"./speaker_step2_en.webp",
    step_two_desc:"Asegúrate de elegir el idioma que coincide con el vídeo al subirlo y selecciona segmentar cada sección para \"orador\"",

    step_three:"Completar",
    step_three_img:"./speaker_step3_en.webp",
    step_three_desc:"Después de que se complete la transcripción, podrás hacer ajustes a los oradores en la plataforma de edición.",

    feature_tutorial_title:'Añade etiquetado de oradores a tus vídeos fácilmente',
    feature_tutorial_desc:'¿Listo para aportar claridad a tus archivos de audio o vídeo? Nuestra función de etiquetado automático de oradores facilita que tus espectadores identifiquen y etiqueten a los diferentes oradores. Esto ayuda a mantener tu contenido ordenado y fácil de seguir en todo momento.<br><br>¿Quieres ver cómo se hace? Tenemos un tutorial en vídeo que puede ayudarte a guiarte en cada etapa.',
    feature_tutorial_poster_url:'./speaker_labeling_tutorial_poster_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/l5xkhJZy5ww?autoplay=1',

    feature_extra_title:"Por qué transcribir reuniones y conferencias web tiene sentido",
    feature_extra_desc:"Puedes pensar que la transcripción de reuniones es solo para mantener un registro. Piénsalo de nuevo. Echemos un vistazo a las múltiples formas en que las transcripciones pueden mejorar tu contenido: <ul><li><strong>Retención de información:</strong> No te pierdas ni un detalle. Captura todos los detalles de tu reunión para asegurarte de que no se te pase nada.</li><li><strong>Optimización SEO:</strong> Incorpora palabras clave pertinentes en tus transcripciones y observa cómo mejora tu posicionamiento SEO.</li><li><strong>Accesibilidad del contenido:</strong> Con la transcripción automática, no solo estás registrando palabras; estás haciéndolas disponibles para una audiencia más amplia, incluidas personas con discapacidades auditivas.</li><li><strong>Retorno de inversión (ROI):</strong> Mejora tu retorno de inversión proporcionando a los asistentes una forma alternativa de revisar las ideas o productos discutidos, lo que puede llevar a conversiones.</li></ul>Entonces, ¿qué ganas? Estás aumentando el compromiso de la audiencia y haciendo que tu contenido sea más inclusivo. Es hora de aprovechar estos beneficios y hacer que tus vídeos y reuniones sean lo más profesionales y útiles posible."
}
