module.exports = {
    monthly_free_minutes:'Minutos mensais gratuitos acumulados (Válido apenas com subscrição)',
    monthly_free_minutes_tooltip: 'Se os minutos mensais não forem utilizados, serão acumulados para o mês seguinte e serão válidos até ao cancelamento da subscrição.<br>Estes minutos mensais são usados antes dos minutos adicionais comprados separadamente.',
    additional_seat:'Lugares adicionais',
    additional_seat_tooltip:'Permite o compartilhamento de conta, convidando utilizadores e concedendo-lhes acesso a minutos, vídeos e funcionalidades com permissões personalizáveis.',
    monthly_free_ai_credits:'Créditos de IA mensais <a href="https://www.taption.com/ai-analysis" target="__blank" style="color:#2FC59F;padding:0px 5px;font-size:12px;">saiba mais</a>',
    monthly_free_ai_credits_tooltip: 'Os créditos de IA recarregam até {0} mensalmente para a Subscrição Premium.<br>Os créditos de IA recarregam até {1} mensalmente para a Subscrição Bulk.<br>Créditos de IA adicionais podem ser adquiridos a qualquer momento.',
    monthly_free_ai_credits_desc:'Até {0} créditos mensalmente',
    minutes_price:'Preço para minutos adicionais',
    minutes_price_tooltip: 'Os minutos adicionais comprados separadamente não expiram após o cancelamento da subscrição.',
    max_file_length:'Duração máxima do vídeo',
    max_file_size:'Tamanho máximo do ficheiro carregado',
    max_label_number:'Número máximo de etiquetas',
    automated_transcription: 'Transcrição automática',
    editing_platform_unlimited: 'Sem limite de tempo para uso da plataforma de edição',
    language_supported: '40+ idiomas suportados',
    highlight_text: 'Editor de texto destacado',
    translate: 'Traduzir para 50+ idiomas',
    translate_basic_tooltip: 'A tradução irá deduzir minutos adicionais',
    translate_premium_tooltip: 'A tradução irá deduzir minutos adicionais',
    translate_corp_tooltip: 'A tradução irá deduzir minutos adicionais',
    personal_dictionary: 'Dicionário de IA personalizado',
    allow_commercial: 'Permitir uso comercial',

    mp4_multi_language_export_tooltip:'Suporta legendas em 2 idiomas das 50+ traduções disponíveis. Pode adicionar mais traduções no menu suspenso de tradução no canto superior direito ao entrar na página de edição de transcrição.',
    mp4_multi_language_export: 'Suporta legendas multilíngues incorporadas',
    mp4_multi_language_export_basic_tooltip:'Exportar vídeo irá deduzir minutos adicionais para utilizadores sem subscrição.',

    mp4_audio_export: 'Converter áudio em vídeo com legendas e fundo personalizado',
    mp4_audio_export_tooltip: 'Pode converter e exportar o seu ficheiro de áudio para um vídeo com fundo personalizado e legendas incorporadas.',
    mp4_audio_export_basic_tooltip: 'Os utilizadores do plano Standard só podem exportar vídeos com fundo preto.',

    api_integration:'Integração de API personalizada',
    api_integration_tooltip: 'Por favor, contacte-nos em help@taption.com para mais informações.',
    api_integration_basic_tooltip: 'Por favor, contacte-nos em help@taption.com para mais informações.',
    api_integration_premium_tooltip: 'Por favor, contacte-nos em help@taption.com para mais informações.',
    api_integration_corp_tooltip: 'Por favor, contacte-nos em help@taption.com para mais informações.',

    custom_payments: 'Opções de faturação personalizadas',
    custom_payments_tooltip: 'Opções de pagamento flexíveis, incluindo pagamento por transferência bancária/cheque. Por favor, contacte-nos em help@taption.com para mais informações.',

    advanced_training: 'Formação avançada para utilizadores',
    advanced_training_tooltip: 'Precisa de ajuda para todos se familiarizarem com o sistema? Nós podemos ajudar.',

    high_definition:'Vídeo com qualidade original',
    high_definition_tooltip: 'Edite e partilhe o seu vídeo com qualidade original',
    
    mp4_export: 'Exportações de legendas incorporadas ilimitadas',
    mp4_export_basic_tooltip: 'Exportar vídeo irá deduzir minutos adicionais para utilizadores sem subscrição.',
    editable_transcript_sharing: 'Partilha de transcrições editáveis',
    dark_mode: 'Modo escuro',
    video_editing: 'Editar vídeo editando texto',
    video_cutting:'Corte de vídeo',
    multi_upload:'Transcrever vários ficheiros ao mesmo tempo',
    invoice_company:'Fatura com título personalizado',
    invoice_taxid:'Fatura com número de identificação fiscal',
    share_minutes:'Acesso multi-utilizador',
    share_minutes_tooltip:'Adicione utilizadores à conta para partilhar minutos e transcrições.',
    permission_users:'Gestão de permissões de utilizadores <a href="https://www.taption.com/collaborate" target="__blank" style="color:#2FC59F;padding:0px 5px;font-size:12px;">saiba mais</a>',
    permission_users_tooltip:'Restrinja contas adicionadas para traduzir, carregar, eliminar, exportar e editar transcrições.',
    max_char:'Número máximo de caracteres para legendas',
    max_char_tooltip:'Número máximo de caracteres para que as legendas sejam mostradas de uma vez.<br> A transcrição será dividida em seções de acordo com este limite.',
    direct_link:'Aceitar links como fontes ao transcrever',
    direct_link_tooltip:'Suporta o seguinte:<br>• Link de vídeo do Facebook<br>• Link de vídeo do Instagram<br>• Link de vídeo do X (anteriormente Twitter)<br>• Link de vídeo do TikTok<br>• Link de ficheiro de mídia<br>• Link de partilha do Dropbox<br>• Link de partilha do One Drive<br>• Link de partilha do Google Drive<br><br>',
    auto_extent_subtitle:'Aumentar automaticamente a duração de cada legenda',
    auto_extent_subtitle_tooltip:'Aumente a duração de todas as legendas por um certo período de tempo.<br/>Desta forma, pode evitar legendas "piscando" entre pausas curtas, suavizando a transição.',
    clipped_video:'Transcrever dentro de intervalo de tempo',
    clipped_video_tooltip:'Extraia e transcreva dentro do intervalo de tempo selecionado do ficheiro de mídia.<br>Suporta media do tipo Youtube, mp3 e mp4.',
    custom_format:'Formato de download personalizado',
    custom_format_tooltip:'Ao exportar a sua transcrição como txt ou pdf, o nosso construtor de templates flexível permitirá que faça o download no formato desejado.',
    editing_font_size:'Personalize o tamanho da fonte na plataforma de edição',

    low_cost:'Preços acessíveis.',
    title_basic:'Pagar conforme usar',
    basic_cost_usd:'8$',
    basic_cost_ntd:'(240 NTD)',
    hour:'hora',
    basic_feature5:'Crédito <span style="color: #00d1b2">60 minutos</span>',
    basic_feature1:'Transcrição automática',
    basic_feature2:'40+ idiomas suportados',
    basic_feature3:'Editor de texto destacado',
    basic_feature4:'Traduzir para 50+ idiomas',
    basic_feature7:'Dicionário de IA personalizado',
    basic_feature6:'Permitir uso comercial',
    basic_feature8:'Suporta legendas multilíngues incorporadas',
    title_premium:'Subscrição Premium',
    premium_cost_usd:'12$',
    premium_cost_ntd:'(360 NTD)',
    premium_basic_cost_usd:'6$',
    month:'mês',
    premium_before:'<span class="landing-arrow-left">&#10229;</span>Todas as funcionalidades em「Pagar conforme usar」',
    premium_feature1:'<span style="color: #00d1b2">120 minutos</span> de crédito na sua conta todos os meses.<br><span style="color:#797676;font-size:14px;font-weight: 100">(Minutos acumuláveis, válidos até ao cancelamento da subscrição)</span>',
    premium_feature1_tooltip:'Se os minutos mensais não forem utilizados, serão acumulados para o mês seguinte e serão válidos até ao cancelamento da subscrição.<br>Estes minutos mensais são usados antes dos minutos adicionais comprados separadamente.<br>Os minutos adicionais comprados separadamente não expiram após o cancelamento da subscrição.',
    premium_feature2:'25% de desconto em compras por hora',
    premium_feature3:'Exportações de legendas incorporadas ilimitadas grátis',
    premium_feature4:'Partilha de transcrições editáveis',
    premium_feature5:'Exporte as suas gravações de áudio para vídeos com legendas',
    premium_feature6:'Corte de vídeo (A transcrição/legendas exportadas serão automaticamente ajustadas)',
    title_corp:'Subscrição Bulk',
    bulk_cost_usd:'69$',
    bulk_cost_ntd:'(2070 NTD)',
    bulk_basic_cost_usd:'4$',
    bulk_before:'<span class="landing-arrow-left">&#10229;</span>Todas as funcionalidades em「Pagar conforme usar」e「Subscrição Premium」',
    bulk_feature2:'63% de desconto em compras por hora',
    premium_feature1_bulk:'<span style="color: #00d1b2">1000 minutos</span> de crédito na sua conta todos os meses.<br><span style="color:#797676;font-size:14px;font-weight: 100">(Minutos acumuláveis, válidos até ao cancelamento da subscrição)</span>',
    bulk_feature1:'Disponibiliza download de recibos com o nome da sua instituição e número de identificação fiscal',
    bulk_feature3:'Partilhe minutos com utilizadores',
    bulk_feature4:'6 diferentes permissões configuráveis',
    go_to_price:'Mais detalhes',

    choose_plan:'Escolher um plano',
    annually:'Anual',
    monthly:'Mensal',
    annually_save:'Poupe {0}% com faturação anual',
    annually_deduct: '${0} faturado anualmente'
}
