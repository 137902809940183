module.exports = {
  enjoy_your_free_trial: 'Votre première transcription est offerte!',
  dialog_title: 'Ajouter des crédits',
  subscription_title: 'Souscrire à un abonnement',
  pay: 'Payer',
  error_unauthenticate: 'Nous n’avons pas pu autoriser votre mode de paiement, veuillez essayer avec une autre carte.',
  error_payment_creation: 'Impossible de finaliser votre paiement, veuillez contacter le support.',
  error_general: 'Connexion au serveur impossible, veuillez actualiser et réessayer.',
  error_timeout: 'Délai de connexion dépassé, veuillez actualiser et réessayer.',

  expired_card:'La carte a expiré, veuillez utiliser une autre carte de crédit.',
  do_not_honor:'La carte a été refusée, veuillez contacter votre émetteur pour plus d’informations.',
  insufficient_funds:'La carte ne dispose pas de fonds suffisants pour finaliser cette transaction.',
  invalid_info: 'Les informations fournies sont invalides, veuillez vérifier et réessayer.',

  success: 'Succès',
  success_description: 'Votre compte a été crédité de <br/> <b class="number-of-minutes">{0} minutes</b>',
  success_description_bonus: 'Minutes bonus: <b class ="extra-minutes"> {1} minutes</b> <br/>Votre compte a été crédité de <br/> <b class="number-of-minutes">{0} minutes</b>',
  success_subscription_description: 'Votre abonnement premium a été activé',
  success_corp_subscription_description: 'Votre abonnement bulk a été activé',
  cancel_subscription_confirmation: 'Êtes-vous sûr de vouloir annuler l’abonnement?',
  success_subscription_bonus: 'Minutes bonus: <b class ="extra-minutes"> {0} minutes</b>',

  cancel_subscription_desc:' minutes expireront immédiatement après l’annulation.<br>Seuls les premiers <span style="color:rgb(246, 102, 101)!important">1</span> libellés peuvent être utilisés après l’annulation.',
  cancel_subscription_confirm:'Je confirme par la présente renoncer aux minutes indiquées ci-dessus.',
  reactivate_subscription_confirmation: 'Êtes-vous sûr de vouloir réactiver l’abonnement?',
  cacnel_subscription_description: 'Vous aurez toujours accès à Premium jusqu’à la fin de la période.',
  cacnel_subscription: 'Votre abonnement Premium a été annulé',
  cancel_corp_subscription: 'Votre abonnement Bulk a été annulé',
  reactive_subscription: 'Votre abonnement a été réactivé!',
  active_until:'Actif jusqu’au',
  success_translation_description: 'Votre traduction est prête!',
  yes: 'Oui',
  no: 'Non',
  continue: 'Continuer',
  save: 'Enregistrer',
  cancel: 'Annuler',
  processing_payment: 'Traitement du paiement en cours',
  error_edit: 'Impossible de finaliser la mise à jour de la carte, veuillez contacter le support',
  delete_ok: 'Supprimer',
  delete_cancel: 'Annuler',
  billing_address: 'Adresse de facturation',
  how_many_hours: 'Combien d’heures?',
  credit_card: 'Carte de crédit',
  summary: 'Résumé (USD)',
  save_payment: 'Enregistrer ma carte pour de futurs achats',
  processing_payment_description: 'Cela peut prendre quelques minutes...',
  credit_card_manage:'Votre carte de crédit est traitée en toute sécurité par Stripe, Inc',
  credit_card_secured:'Les transactions sont sécurisées et cryptées.',
  understand_billing:'J’autorise ce paiement',
  processing_saving:'Mise à jour de la carte de crédit',
  
  full_name: 'Nom complet',
  name: 'Nom',
  address: 'Adresse',
  city: 'Ville',
  state: 'État',
  zip: 'Code postal',
  
  max_length_field: '{0} doit comporter au maximum {1} caractères',
  required_field: '{0} est requis',
  delete_title: 'Supprimer cette carte?',

  title_basic: 'Standard',
  title_pay_as_you_go: 'À l’utilisation',
  title_premium: 'Premium',
  title_custom: 'Personnalisé',
  title_corp: 'Bulk',
  title_subscription:'Abonnement',

  basic_feature5: 'Crédit <span style="color: #00d1b2;">60 minutes</span>',

  premium_feature1: '{0} <span style="color: #00d1b2;">minutes</span> tous les mois.<br><span class="price-notes">(Minutes cumulables, valides jusqu’à l’annulation de l’abonnement)</span>',
  premium_feature2: '25% de réduction sur les achats à l’heure',
  premium_feature3_tooltip: '2 $ par document',
  
  buy: 'Acheter des minutes',
  activate: 'Activer',
  subscribe: 'Souscrire',
  contact_us: 'Contactez-nous',

  custom_feature1: 'Partenariat commercial',
  custom_feature2: 'Abonnements de groupe',
  custom_feature3: 'Facturation centralisée',
  custom_feature4: 'Signalement des retours ou des bugs',

  month: 'mois',
  per_hour: 'par heure',
  per_month: 'par mois',
  per_user_per_month:'{0} par utilisateur/mois',

  how_get_free:'Comment puis-je obtenir des minutes gratuites?',
  payment_questions:'Questions de facturation?',

  translate_remaining:'Il vous reste {0}.',
  translate_deduct:'Nous déduirons {0} si vous traduisez cette transcription.',
  transcribe_deduct:'Nous déduirons {0} si vous transcrivez cette transcription.',
  transcribe_warning:'Assurez-vous d’avoir suffisamment de minutes pour la transcription, sinon le processus échouera.',
  mp4_deduct:'Nous déduirons {0} si vous exportez cette transcription au format MP4.',
  cvc:'Qu’est-ce que le CVC?',
  confirm_translate:'Je souhaite poursuivre la traduction',
  recurring_description:'• Paiement mensuel récurrent',
  recurring_description2:'• Annulez à tout moment dans Compte',
  coupon_header:'Code promotionnel',
  coupon_title:'Entrez votre code promotionnel :',
  coupon_submit:'Appliquer',
  processing_coupon: 'Application du code promotionnel',
  coupon_empty: 'Veuillez saisir votre code promotionnel',
  coupon_expire: 'Le code promotionnel a expiré',
  coupon_notexist: 'Le code promotionnel n’est pas valide',
  coupon_firsttimeonly: 'Le code promotionnel est uniquement valable pour les nouveaux utilisateurs',
  member_slot_desc:'Nombre total de places membres pour cet abonnement pour inviter des membres avec différentes autorisations pour <br>ajouter, télécharger, éditer ou exporter des transcriptions depuis ce compte.',
  credit_edit_title:'Informations sur la carte de crédit',

  payment_confirm_title:'Confirmation de paiement',
  payment_confirm_desc:'Ce paiement est facturé en <span style="color:#2fc59f;font-weight:bold;">dollars américains</span> pour un montant de {0}.',
  upgrade_contact_us:'Pour des solutions d’entreprise, des programmes d’affiliation ou des demandes commerciales, veuillez nous contacter à <span style="color:#2fc59f">help@taption.com</span>'
}
