module.exports = {
  'en': 'Anglais',
  'zh-TW': 'Chinois (Traditionnel)',
  'zh': 'Chinois (Simplifié)',
  'yue-Hant-HK': 'Chinois (Cantonais)',
  'yue-Hant-HK-TW': 'Chinois (Cantonais)',
  'ja': 'Japonais',
  'ko': 'Coréen',
  'es': 'Espagnol',
  'de': 'Allemand',
  'fr': 'Français',
  'af': 'Afrikaans',
  'sq': 'Albanais',
  'am': 'Amharique',
  'ar': 'Arabe',
  'hy': 'Arménien',
  'az': 'Azerbaïdjanais',
  'eu': 'Basque',
  'be': 'Biélorusse',
  'bn': 'Bengali',
  'bs': 'Bosniaque',
  'bg': 'Bulgare',
  'ca': 'Catalan',
  'co': 'Corse',
  'hr': 'Croate',
  'cs': 'Tchèque',
  'da': 'Danois',
  'nl': 'Néerlandais',
  'fi': 'Finlandais',
  'el': 'Grec',
  'gu': 'Gujarati',
  'he': 'Hébreu',
  'hi': 'Hindi',
  'hu': 'Hongrois',
  'id': 'Indonésien',
  'ga': 'Irlandais',
  'it': 'Italien',
  'la': 'Latin',
  'lv': 'Letton',
  'lt': 'Lituanien',
  'ms': 'Malais',
  'no': 'Norvégien',
  'pl': 'Polonais',
  'pt': 'Portugais (Portugal, Brésil)',
  'ro': 'Roumain',
  'ru': 'Russe',
  'sr': 'Serbe',
  'sk': 'Slovaque',
  'sl': 'Slovène',
  'sv': 'Suédois',
  'te': 'Télougou',
  'th': 'Thaï',
  'tr': 'Turc',
  'uk': 'Ukrainien',
  'uz': 'Ouzbek',
  'vi': 'Vietnamien',
  'yi': 'Yiddish',
  'zu': 'Zoulou'
}
