
export default {
    getAppLoginUrl(state){
      let language = window.localStorage.getItem('PrefferedLanguage') || this.getDisplayLanguage();
      let advertiserId = window.localStorage.getItem('advertiserId');
      let platform = window.localStorage.getItem('platform') || 'other';
      if(advertiserId && state){
        return this.getAppSiteUrl()+'/login'+'/'+language+`?advertiserId=${advertiserId}&platform=${platform}&state=${state}`
      }
      else if(advertiserId){
        return this.getAppSiteUrl()+'/login'+'/'+language+`?advertiserId=${advertiserId}&platform=${platform}`
      }
      else if(state){
        return this.getAppSiteUrl()+'/login'+'/'+language+`?state=${state}`
      }
      else{
        return this.getAppSiteUrl()+'/login'+'/'+language
      }
    },
    getAppLoginRelativeUrl(state){
      let language = window.localStorage.getItem('PrefferedLanguage') || this.getDisplayLanguage();
      let advertiserId = window.localStorage.getItem('advertiserId');
      let platform = window.localStorage.getItem('platform') || 'other';
      if(advertiserId && state){
        return '/login'+'/'+language+`?advertiserId=${advertiserId}&platform=${platform}&state=${state}`
      }
      else if(advertiserId){
        return '/login'+'/'+language+`?advertiserId=${advertiserId}&platform=${platform}`
      }
      else if(state){
        return '/login'+'/'+language+`?state=${state}`
      }
      else{
        return '/login'+'/'+language
      }
    },
    getPricingUrl(state){
      let language = window.localStorage.getItem('PrefferedLanguage') || this.getDisplayLanguage();
      let advertiserId = window.localStorage.getItem('advertiserId');
      let platform = window.localStorage.getItem('platform') || 'other';

      if(language.toLowerCase() === 'en-us'){
        language = '';
      }
      if(language){
        if(advertiserId && state){
          return this.getSiteUrl()+'/pricing'+'/'+language+`?advertiserId=${advertiserId}&platform=${platform}&state=${state}`
        }
        else if(advertiserId){
          return this.getSiteUrl()+'/pricing'+'/'+language+`?advertiserId=${advertiserId}&platform=${platform}`
        }
        else if(state){
          return this.getSiteUrl()+'/pricing'+'/'+language+`?state=${state}`
        }
        else{
          return this.getSiteUrl()+'/pricing'+'/'+language
        }
      }
      else{

      if(advertiserId && state){
        return this.getSiteUrl()+'/pricing'+`?advertiserId=${advertiserId}&platform=${platform}&state=${state}`
      }
      else if(advertiserId){
        return this.getSiteUrl()+'/pricing'+`?advertiserId=${advertiserId}&platform=${platform}`
      }
      else if(state){
        return this.getSiteUrl()+'/pricing'+`?state=${state}`
      }
      else{
        return this.getSiteUrl()+'/pricing'
      }
      }
    },
    

    getDisplayLanguage(){
        const urlChunks = window.location.href.replace(window.location.origin+'/','').split('/');
        let language = urlChunks[urlChunks.length-1].split('&')[0].split('?')[0];
       // const supportedLanguages = ['zh','zh-cn','en-us','jp','kr'];
        const supportedLanguages = process.env.VUE_APP_BROWSER_SUPPORTED_LANGUAGE.split(',');
        if(supportedLanguages.indexOf(language.toLowerCase()) === -1){
          language = '';
        }
        return language;
    },
    distinctLanguages(languageObj){
      const distinctLang = {};
      const results = [];
      
      Object.keys(languageObj).forEach(function(code) {
        const langDisplay = languageObj[code].split(" ")[0];
        if(distinctLang[langDisplay] !== true){
          results.push(langDisplay);
          distinctLang[langDisplay] = true;
        }
      });
  
      return results;
    },

    getAppSiteUrl(){
      if(process.env.VUE_APP_MODE === 'production'){
        return 'https://app.taption.com';
      }
      else{
        return 'https://app.taptiontest.com';
      }
    },
    getSiteUrl(){
      if(process.env.VUE_APP_MODE === 'production'){
        return 'https://www.taption.com';
      }
      else{
        return 'https://www.taptiontest.com';
      }
    }
}