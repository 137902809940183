module.exports = {
  enjoy_your_free_trial: 'Transkripsi pertama Anda gratis!',
  dialog_title: 'Tambahkan Kredit',
  subscription_title: 'Dapatkan Langganan',
  pay: 'Bayar',
  error_unauthenticate: 'Kami tidak dapat mengotorisasi metode pembayaran Anda, silakan coba dengan kartu yang berbeda.',
  error_payment_creation: 'Tidak dapat menyelesaikan pembayaran Anda, silakan hubungi dukungan',
  error_general: 'Tidak dapat terhubung dengan server, silakan segarkan dan coba lagi',
  error_timeout: 'Koneksi timeout, silakan segarkan dan coba lagi',

  expired_card:'Kartu telah kedaluwarsa, silakan gunakan kartu kredit yang berbeda.',
  do_not_honor:'Kartu telah ditolak, silakan hubungi penerbit kartu Anda untuk informasi lebih lanjut.',
  insufficient_funds:'Kartu tidak memiliki cukup dana untuk menyelesaikan transaksi ini.',
  invalid_info: 'Informasi kartu yang diberikan tidak valid, silakan periksa dan coba lagi.',

  success: 'Sukses',
  success_description: 'Akun Anda telah dikreditkan dengan <br/> <b class="number-of-minutes">{0} menit</b>',
  success_description_bonus: 'Menit bonus: <b class ="extra-minutes"> {1} menit</b> <br/>Akun Anda telah dikreditkan dengan <br/> <b class="number-of-minutes">{0} menit</b>',
  success_subscription_description: 'Langganan premium Anda telah diaktifkan',
  success_corp_subscription_description: 'Langganan bulk Anda telah diaktifkan',
  cancel_subscription_confirmation: 'Apakah Anda yakin ingin membatalkan langganan?',
  success_subscription_bonus: 'Menit bonus: <b class ="extra-minutes"> {0} menit</b>',

  cancel_subscription_desc:' menit akan kedaluwarsa segera setelah pembatalan<br>Hanya <span style="color:rgb(246, 102, 101)!important">1</span> label pertama yang dapat digunakan setelah pembatalan',
  cancel_subscription_confirm:'Saya dengan ini mengonfirmasi untuk melepaskan menit yang dinyatakan di atas',
  reactivate_subscription_confirmation: 'Apakah Anda yakin ingin mengaktifkan kembali langganan?',
  cacnel_subscription_description: 'Anda akan tetap memiliki akses Premium hingga akhir periode.',
  cacnel_subscription: 'Langganan Premium Anda telah dibatalkan',
  cancel_corp_subscription: 'Langganan Bulk Anda telah dibatalkan',
  reactive_subscription: 'Langganan Anda telah diaktifkan kembali!',
  active_until:'Aktif hingga',
  success_translation_description: 'Terjemahan Anda siap!',
  yes: 'Ya',
  no: 'Tidak',
  continue: 'Lanjutkan',
  save: 'Simpan',
  cancel: 'Batal',
  processing_payment: 'Memproses pembayaran',
  error_edit: 'Tidak dapat menyelesaikan pembaruan kartu, silakan hubungi dukungan',
  delete_ok: 'Hapus',
  delete_cancel: 'Batal',
  billing_address: 'Alamat Penagihan',
  how_many_hours: 'Berapa jam?',
  credit_card: 'Kartu kredit',
  summary: 'Ringkasan (USD)',
  save_payment: 'Simpan kartu saya untuk pembelian di masa mendatang',
  processing_payment_description: 'Ini mungkin memerlukan beberapa menit...',
  credit_card_manage:'Kartu kredit Anda diproses dengan aman oleh Stripe, Inc',
  credit_card_secured:'Transaksi aman dan terenkripsi.',
  understand_billing:'Saya mengotorisasi pembayaran ini',
  processing_saving:'Memperbarui kartu kredit',

  full_name: 'Nama lengkap',
  name: 'Nama',
  address: 'Alamat',
  city: 'Kota',
  state: 'Negara Bagian',
  zip: 'Kode Pos',

  max_length_field: '{0} harus tidak lebih dari {1} karakter',
  required_field: '{0} wajib diisi',
  delete_title: 'Hapus kartu ini?',

  title_basic: 'Standar',
  title_pay_as_you_go: 'Bayar sesuai pemakaian',
  title_premium: 'Premium',
  title_custom: 'Kustom',
  title_corp: 'Bulk',
  title_subscription:'Langganan',

  basic_feature5: 'Kredit <span style="color: #00d1b2;">60 menit</span>',

  premium_feature1: '{0} <span style="color: #00d1b2;">menit</span> setiap bulan.<br><span class="price-notes">(Menit rollover, berlaku hingga langganan dibatalkan)</span>',
  premium_feature2: 'Diskon 25% untuk pembelian per jam',
  premium_feature3_tooltip: '$2 per dokumen',

  buy: 'Beli menit',
  activate: 'Aktifkan',
  subscribe: 'Langganan',
  contact_us: 'Hubungi kami',

  custom_feature1: 'Kemitraan bisnis',
  custom_feature2: 'Langganan grup',
  custom_feature3: 'Penagihan terpusat',
  custom_feature4: 'Pelaporan umpan balik atau bug',

  month: 'bulan',
  per_hour: 'per jam',
  per_month: 'per bulan',
  per_user_per_month:'{0} per pengguna/bulan',

  how_get_free:'Bagaimana cara mendapatkan menit gratis?',
  payment_questions:'Punya pertanyaan penagihan?',

  translate_remaining:'Anda memiliki {0} sisa.',
  translate_deduct:'Kami akan mengurangi {0} jika Anda menerjemahkan transkrip ini.',
  transcribe_deduct:'Kami akan mengurangi {0} jika Anda mentranskripsi transkrip ini.',
  transcribe_warning:'Pastikan Anda memiliki cukup menit untuk mentranskripsi, jika tidak, proses akan gagal.',
  mp4_deduct:'Kami akan mengurangi {0} jika Anda mengekspor transkrip ini sebagai MP4.',
  cvc:'Apa itu CVC?',
  confirm_translate:'Saya ingin melanjutkan terjemahan',
  recurring_description:'• Pembayaran berulang bulanan',
  recurring_description2:'• Batalkan kapan saja di Akun',
  coupon_header:'Kode Promo',
  coupon_title:'Masukkan kode promo Anda:',
  coupon_submit:'Terapkan',
  processing_coupon: 'Menerapkan kode promo',
  coupon_empty: 'Harap masukkan kode promo Anda',
  coupon_expire: 'Kupon telah kedaluwarsa',
  coupon_notexist: 'Kupon tidak valid',
  coupon_firsttimeonly: 'Kupon hanya berlaku untuk pengguna baru',
  member_slot_desc:'Total kursi anggota untuk langganan ini untuk mengundang anggota dengan izin berbeda untuk <br>menambah, mengunggah, mengedit, dan atau mengekspor transkripsi dari akun ini.',
  credit_edit_title:'Informasi kartu kredit',

  payment_confirm_title:'Konfirmasi pembayaran',
  payment_confirm_desc:'Pembayaran ini dikenakan dalam <span style="color:#2fc59f;font-weight:bold;">Dolar Amerika Serikat</span> dengan jumlah {0}.',
  upgrade_contact_us:'Untuk solusi perusahaan, program afiliasi, atau pertanyaan bisnis, silakan hubungi kami di <span style="color:#2fc59f">help@taption.com</span>'
}
