<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <v-app class="taption">
        <!-- <div ref="loader" class="loader-wrapper">
          <div class="loader"></div>
        </div> -->

        <router-view name="navbar"></router-view>
        
           <!-- <v-sheet
      id="scrolling-techniques-7"
      class="scroll-sheet overflow-y-auto"
      max-height="100vh"
    > -->
        <router-view />
    <!-- </v-sheet>   onclick="displayPreferenceModal()"-->

          <v-footer app class="taption-footer"><a class="footer-text" :href="getTaptionUrl">Taption.com</a><a class="footer-text" :href="getPricingUrl">{{$lang.common.pricing}}</a><a class="footer-text" @click="onPrivacy">{{$lang.common.privacy}}</a><a class="footer-text" @click="onTerms">{{$lang.common.terms}}</a>
          <a class="footer-text" :href="getDisplayLanguage() ? `/blog/${getDisplayLanguage()}` : `/blog`">{{$lang.common.blog}}</a>
          <a class="footer-text" target="__blank" :href="'https://www.youtube.com/channel/UCIC-yZnlHPJTKTO64qNVmRg'">{{$lang.common.video}}</a>
          
          <a
  class="termly-cookie-preference-button footer-text"

  @click="onCookieClicked"
>
    {{$lang.common.cookieSettings}}
</a>
  <v-flex/>
  <a  v-show="!isEnglish" aria-label="Instagram" class="instagram-add" rel="noreferrer" href="https://www.instagram.com/taptionllc" target="_blank"><v-icon class="mdi-instagram" left>{{mdiInstagram}}</v-icon></a>
          <a  v-show="!isEnglish" aria-label="Facebook" class="facebook-add" rel="noreferrer" href="https://www.facebook.com/taption.llc" target="_blank"><v-icon class="mdi-facebook" left>{{mdiFacebook}}</v-icon></a>
           <a v-show="isEnglish" aria-label="Twitter" class="twitter-add" rel="noreferrer" href="https://twitter.com/taptionllc" target="_blank"><v-icon class="mdi-twitter" left>{{mdiTwitter}}</v-icon></a>
            <a v-show="isEnglish" aria-label="Linkedin" class="linkedin-add" rel="noreferrer" href="https://www.linkedin.com/company/taption" target="_blank"><v-icon class="mdi-linkedin" left>{{mdiLinkedin}}</v-icon></a>

          <span class="copyright">© 2024 Taption LLC.</span>
          </v-footer>
      
      </v-app>
  </div>
</template>

<script>
import { mdiFacebook, mdiTwitter, mdiLinkedin, mdiInstagram } from '@mdi/js'
import textManager from './utils/textManager';
const getParameter = require('get-parameter');
export default {
  name: 'App',
  components: {
  },
  data () {
    return {
      mdiFacebook: mdiFacebook,
      mdiTwitter:mdiTwitter,
      mdiLinkedin:mdiLinkedin,
      mdiInstagram:mdiInstagram
    };
  },
  metaInfo() {
  const me = this;
   const language = me.getDisplayLanguage().toLowerCase();
   const supportedLanguages = process.env.VUE_APP_BROWSER_SUPPORTED_LANGUAGE.split(',');
   let htmlLang = 'en-US';
   let localeLang = 'en_US';
   let map = {
    'en-US':'',
    'zh-TW':'/zh',
    'zh-CN':'/zh-CN',
    'ja-JP':'/jp',
    'ko-KR':'/kr',
    'de':'/de',
    'fr':'/fr',
    'id':'/id',
    'pt':'/pt',
    'es':'/es'
  }

    
  if(language === 'zh'){
    htmlLang = 'zh-TW';
    localeLang = 'zh_TW';
  }
  else if(language === 'zh-cn'){
    htmlLang = 'zh-CN';
    localeLang = 'zh_CN';
  }
  else if(language === 'jp'){
    htmlLang = 'ja-JP';
    localeLang = 'ja_JP';
  }
  else if(language === 'kr'){
    htmlLang = 'ko-KR';
    localeLang = 'ko_KR';
  }
  else if(language === 'es'){
    htmlLang = 'es';
    localeLang = 'es';
  }
  else if(language === 'de'){
    htmlLang = 'de';
    localeLang = 'de';
  }
  else if(language === 'fr'){
    htmlLang = 'fr';
    localeLang = 'fr';
  }
  else if(language === 'id'){
    htmlLang = 'id';
    localeLang = 'id';
  }
  else if(language === 'pt'){
    htmlLang = 'pt';
    localeLang = 'pt';
  }

  let links = [];
  let currentUrl = '';

  //const chunks = me.$route.path && me.$route.path.split('/');
  const chunks = window.location.pathname.split('/');
  if(chunks.length === 2 && !supportedLanguages.includes(chunks[1].toLowerCase()) && window.location.pathname !== '/'){
    links = [];
    links.push({
      vmid:htmlLang+'_canonical',
      rel:'canonical',
      href: 'https://www.taption.com/'+chunks[1]
    });

    links.push({
      vmid:'x-default',
      rel:'alternate',
      href: 'https://www.taption.com/'+chunks[1],
      hrefLang:'x-default'
    });

    Object.keys(map).forEach(function(mapLang){
     // if(mapLang!== htmlLang){
        links.push({
            vmid:mapLang,
            rel:'alternate',
            href: 'https://www.taption.com/'+chunks[1]+map[mapLang],
            hrefLang:mapLang
          })
      //}
    });
    currentUrl = 'https://www.taption.com/'+chunks[1]+map[htmlLang];
  }
  else if(chunks.length === 3){
    links = [];
    links.push({
      vmid:htmlLang+'_canonical',
      rel:'canonical',
      href: 'https://www.taption.com/'+chunks[1]+ map[htmlLang]
    });

    links.push({
      vmid:'x-default',
      rel:'alternate',
      href: 'https://www.taption.com/'+chunks[1],
      hrefLang:'x-default'
    });

    Object.keys(map).forEach(function(mapLang){
     // if(mapLang!== htmlLang){
        links.push({
            vmid:mapLang,
            rel:'alternate',
            href: 'https://www.taption.com/'+chunks[1]+ map[mapLang],
            hrefLang:mapLang
          })
      //}
    });
    currentUrl = 'https://www.taption.com/'+chunks[1]+ map[htmlLang];
  }
  else if(chunks.length === 4){
    links = [];
    links.push({
      vmid:htmlLang,
      rel:'canonical',
      href: 'https://www.taption.com/'+chunks[1]+'/'+chunks[2]+'/'+chunks[3]
    });

    // Object.keys(map).forEach(function(mapLang){
    //   if(mapLang!== htmlLang){
    //     links.push({
    //         vmid:mapLang,
    //         rel:'alternate',
    //         href: 'https://www.taption.com/'+chunks[1]+'/'+map[mapLang],
    //         hrefLang:mapLang
    //       })
    //   }
    // });
    currentUrl = 'https://www.taption.com/'+chunks[1]+'/'+chunks[2]+'/'+chunks[3]
  }
  else{
    map['en-US'] = '';

    links = [];
    links.push({
      vmid:htmlLang,
      rel:'canonical',
      href: 'https://www.taption.com'+map[htmlLang]
    });

    links.push({
      vmid:'x-default',
      rel:'alternate',
      href: 'https://www.taption.com',
      hrefLang:'x-default'
    });

    Object.keys(map).forEach(function(mapLang){
    //  if(mapLang!== htmlLang){
        links.push({
            vmid:mapLang,
            rel:'alternate',
            href: 'https://www.taption.com'+map[mapLang],
            hrefLang:mapLang
          })
     // }
    });
    currentUrl = 'https://www.taption.com'+map[htmlLang];
  }

  // rel: 'canonical',
  //         href: me.$lang.about.site_url,
  //         hrefLang:htmlLang

    return{
      title: me.$lang.about.taption,
      // titleTemplate: '%s - Yay!',
      htmlAttrs: {
        lang: htmlLang || 'en'
      },

      meta: [
        { vmid: 'name', content: me.$lang.about.content_title, itemprop: 'name' },
        { vmid: 'description', content: me.$lang.about.content_description, itemprop: 'description' },
        { vmid: 'image',content: me.$lang.about.cover_image_url, itemprop: 'image' },

        // { content: 'article', property: 'og:type' },
        { vmid: 'article_desc', content: me.$lang.about.content_description, name: 'description' },
        { vmid: 'og_title', content: me.$lang.about.content_title, property: 'og:title' },
        { vmid: 'og_desc', content: me.$lang.about.content_description, property: 'og:description' },
        { vmid: 'og_locale', content: localeLang, property: 'og:locale' },
        { vmid: 'og_site_name', content: me.$lang.about.site_name, property: 'og:site_name' },
        { vmid: 'og_url',content: currentUrl, property: 'og:url' },
        { vmid: 'og_image',content: me.$lang.about.cover_image_url, property: 'og:image' },
        { vmid: 'og_type',content: 'website', property: 'og:type' },
        { vmid: 'og_secure_url',content: me.$lang.about.cover_image_url, property: 'og:image:secure_url' },

        { content: 'summary', name: 'twitter:card' },
        { content: '@taptionllc', name: 'twitter:site' },
        { vmid:'twitter_title', content: me.$lang.about.content_title, name: 'twitter:title' },
        { vmid:'twitter_desc',content: me.$lang.about.content_description, name: 'twitter:description' },
        { content: '@taptionllc', name: 'twitter:creator' },
        { content: me.$lang.about.cover_image_url, name: 'twitter:image' },
        { content: me.$lang.about.cover_image_url, name: 'twitter:image:src' },

        
      ],
      link: links
    }
    },
 computed:{
    isEnglish(){
      const urlChunks = window.location.href.replace(window.location.origin+'/','').split('/');
      let language = urlChunks[urlChunks.length-1].split('&')[0].split('?')[0];
     // const supportedLanguages = ['zh','zh-cn','en-us'];
      const supportedLanguages = process.env.VUE_APP_BROWSER_SUPPORTED_LANGUAGE.split(',');
      

      if(supportedLanguages.indexOf(language.toLowerCase()) === -1){
        language = '';
      }

      return !window.localStorage.getItem('PrefferedLanguage') || window.localStorage.getItem('PrefferedLanguage') === 'en-US' || language === 'en-US' || !language
    },
    getTaptionUrl(){
      return textManager.getSiteUrl();
    },
    getPricingUrl(){
      return textManager.getPricingUrl();
     // return { name: 'pricing', params: { language: textHelper.getDisplayLanguage() } }
    }
 },
  methods: {
    onTaption(){
      const me = this;
     
      me.$router.push({ name: 'taption' })
    },
    onPricing(){
      const me = this;
     
      me.$router.push({ name: 'pricing' })
    },
    onPrivacy(){
      const me = this;
      const routeData = me.$router.resolve({name: 'privacy'});
      window.open(routeData.href, '_blank');
      
    },
    onTerms(){
      const me = this;
      const routeData = me.$router.resolve({name: 'terms'});
      window.open(routeData.href, '_blank');
    },
    onCookieClicked(){
      if(window.displayPreferenceModal){
        window.displayPreferenceModal();
      }
    },
    // onBlog(){

    //   const me = this;
    //   const routeData = me.$router.resolve({name: 'blog'});
    //   window.open(routeData.href, '_blank');
    // },
    getDisplayLanguage(){
       const urlChunks = window.location.href.replace(window.location.origin+'/','').split('/');
      const supportedLanguages = process.env.VUE_APP_BROWSER_SUPPORTED_LANGUAGE.split(',');

      let index = urlChunks.length-1;
       let language = urlChunks[index].split('&')[0].split('?')[0];

       while(index >= 0 && supportedLanguages.indexOf(language.toLowerCase()) === -1){
        language = urlChunks[index].split('&')[0].split('?')[0];
        index--;
      } 

      if(supportedLanguages.indexOf(language.toLowerCase()) === -1){
       // language = window.localStorage.getItem('PrefferedLanguage');
        if(!language || supportedLanguages.indexOf(language.toLowerCase()) === -1){
       //   language = 'en-US';
       language = '';
        }
      }

        if(language === 'en'){
        //  language = 'en-US';
          language = '';
        }

      return language;
    },
    async loadTermly(){
      let cookieConsent = document.createElement('script')
      cookieConsent.setAttribute('type', 'text/javascript')
      cookieConsent.setAttribute('src', 'https://app.termly.io/embed.min.js')
      cookieConsent.setAttribute('data-auto-block', 'off')
      cookieConsent.setAttribute('data-website-uuid', '44636af0-628a-4950-9aee-ae0cb632afbc')
      cookieConsent.setAttribute('defer','');
      document.head.appendChild(cookieConsent)
    }
    // loadGoogleAnalysis(){
    //   let cookieConsent = document.createElement('script')
    //   cookieConsent.setAttribute('defer', '')
    //   cookieConsent.setAttribute('src', 'https://app.termly.io/embed.min.js')
    //   document.head.appendChild(cookieConsent)
    //   this.$nextTick(function(){
    //     window.dataLayer = window.dataLayer || [];
    //     function gtag(){dataLayer.push(arguments);}
    //       gtag('js', new Date());
    //       gtag('config', 'UA-169342841-1');
    //     })
    // }
  },
  // mounted(){
  //   this.loadTermly();
  // },
  created(){
    const me = this;
     //  const auth = me.$userConnection.getAuth();
   // const urlChunks = window.location.href.replace(window.location.origin+'/','').split('/');
    const urlLanguage = me.getDisplayLanguage();
    //console.log(urlLanguage);
    
    // utm_source has a default advertising id
    const platform = getParameter('platform') || getParameter('utm_source');
    const advertiserId = getParameter('advertiserId') ? getParameter('advertiserId') : (getParameter('utm_source') ? 'f0e7jXwyreY9XZONHdeQtZd4mko1' : '');

    if(advertiserId){
        window.localStorage.setItem('advertiserId', advertiserId);
        window.localStorage.setItem('platform', platform || 'other');
    }

    // if(!window.localStorage.getItem('PrefferedLanguage') || urlLanguage){
    //   window.localStorage.setItem('PrefferedLanguage', urlLanguage || 'en-US');
    // }

    window.localStorage.setItem('PrefferedLanguage', urlLanguage || 'en-US');

     me.$lang.setLang(window.localStorage.getItem('PrefferedLanguage'));

     //console.log( window.location.href.replace(window.location.origin+'/','').split('/'));
     const chunks = window.location.href.replace(window.location.origin+'/','').split('/');

     // only re reroute to saved language in local if this is in the root domain 
     if(chunks && chunks.indexOf(urlLanguage) < 0){
      if(urlLanguage === 'zh'){
        me.$router.push({ name: "taptiontw" });
      }
      else if(urlLanguage === 'zh-CN'){
        me.$router.push({ name: "taptioncn" });
      }
      else if(urlLanguage === 'jp'){
        me.$router.push({ name: "taptionjp" });
      }
      else if(urlLanguage === 'kr'){
        me.$router.push({ name: "taptionkr" });
      }
      else if(urlLanguage === 'es'){
        me.$router.push({ name: "taptiones" });
      }
      else if(urlLanguage === 'de'){
        me.$router.push({ name: "taptionde" });
      }
      else if(urlLanguage === 'fr'){
        me.$router.push({ name: "taptionfr" });
      }
      else if(urlLanguage === 'id'){
        me.$router.push({ name: "taptionid" });
      }
      else if(urlLanguage === 'pt'){
        me.$router.push({ name: "taptionpt" });
      }
     }
    
     
  }
}
</script>

<style>
body {
  font-family: Lato, sans-serif;
  background: whitesmoke;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow-x:hidden;
}
.taption{
  overflow: hidden;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif!important;
}
.v-application a {
    text-decoration: none!important;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}


h2 {
    font-size: 30px;
    margin-bottom: 32px;
    letter-spacing: 4px;
    line-height: 28px;
    text-transform: uppercase;
    font-weight: 300;
    color: #444;
}
h3 {
    line-height: 28px;
    font-size: 16px;
    margin-top: 30px;
    text-transform: capitalize;
    letter-spacing: 1px;
    font-weight: 400;
    color: grey;
    text-align: center;
}
h4{
    font-size: 15px;
    line-height: 28px;
    font-weight: 300;
    color:#797676;
}
.taption section{
  margin-top:100px!important;
}
ul{
  margin:10px;
}
li::marker{
  color:#3ccebd;
}
/* h5{
  letter-spacing: 0.08em!important;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif!important;
}

h4 {
  font-weight: bold;
  font-size: 12px;
  user-select: none;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif!important;
} */
.taption-footer{
  color:#797676!important;
  font-size:12px;
}

.taption-footer a{
  color:#797676!important;
  padding:0px 2px;
}

.footer-text{
  padding:0px 10px!important;
}


.container{
      max-width: 1785px!important;
}



.line-add,
.mdi-facebook,
.mdi-linkedin,
.mdi-twitter{
   margin:0px!important;
   filter: lightgray; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
  opacity: 0.6;
  cursor: pointer;
}

.seperator {
    border-bottom: 1px solid rgba(96,101,123,.24);
}

.t-declineAllButton,
.t-allowAllButton,
#termly-code-snippet-support button{
  background:#3CCEBD!important;
  border:0px!important;
  font-size:14px!important;
  font-weight: normal!important;
}
#termly-code-snippet-support .t-preference-button{
  border:1px solid #3CCEBD!important;
  background:transparent!important;
  color:#3CCEBD!important;
}


/*
0 1px 0px -1px rgb(0 0 0 / 12%), 0 1px 4px 0 rgb(0 0 0 / 0%), 0 1px 10px 0 rgb(0 0 0 / 10%)
*/


/* Track */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    -webkit-border-radius: 10px;
    border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #797676; 
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}
::-webkit-scrollbar-thumb:window-inactive {
	background: rgba(0,0,0,0.2); 
}

</style>


