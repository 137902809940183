module.exports = {
  'en': 'Inglês',
  'zh-TW': 'Chinês (Tradicional)',
  'zh': 'Chinês (Simplificado)',
  'yue-Hant-HK': 'Chinês (Cantonês)',
  'yue-Hant-HK-TW': 'Chinês (Cantonês)',
  'ja': 'Japonês',
  'ko': 'Coreano',
  'es': 'Espanhol',
  'de': 'Alemão',
  'fr': 'Francês',
  'af': 'Africâner',
  'sq': 'Albanês',
  'am': 'Amárico',
  'ar': 'Árabe',
  'hy': 'Arménio',
  'az': 'Azerbaijano',
  'eu': 'Basco',
  'be': 'Bielorrusso',
  'bn': 'Bengali',
  'bs': 'Bósnio',
  'bg': 'Búlgaro',
  'ca': 'Catalão',
  'co': 'Corso',
  'hr': 'Croata',
  'cs': 'Checo',
  'da': 'Dinamarquês',
  'nl': 'Holandês',
  'fi': 'Finlandês',
  'el': 'Grego',
  'gu': 'Gujarati',
  'he': 'Hebraico',
  'hi': 'Hindi',
  'hu': 'Húngaro',
  'id': 'Indonésio',
  'ga': 'Irlandês',
  'it': 'Italiano',
  'la': 'Latim',
  'lv': 'Letão',
  'lt': 'Lituano',
  'ms': 'Malaio',
  'no': 'Norueguês',
  'pl': 'Polaco',
  'pt': 'Português (Portugal, Brasil)',
  'ro': 'Romeno',
  'ru': 'Russo',
  'sr': 'Sérvio',
  'sk': 'Eslovaco',
  'sl': 'Esloveno',
  'sv': 'Sueco',
  'te': 'Telugu',
  'th': 'Tailandês',
  'tr': 'Turco',
  'uk': 'Ucraniano',
  'uz': 'Uzbeque',
  'vi': 'Vietnamita',
  'yi': 'Iídiche',
  'zu': 'Zulu'
}
