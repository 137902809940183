module.exports = {
    convert_to_text_title_meta:"Traductor de vídeo en línea - Traducción de subtítulos y vídeos en varios idiomas",
    convert_to_text_desc_meta:"Soporta más de 40 idiomas, incluyendo inglés a japonés, ruso a inglés y portugués a inglés para crear y exportar subtítulos bilingües en línea. Nuestro servicio de transcripción y traducción con IA satisface completamente las necesidades de traducción de vídeos multilingües. ¡Comienza con una prueba gratuita hoy!",
    convert_to_text_title:"Traducir vídeo",
    convert_to_text_desc:"Traduce automáticamente tu vídeo a más de 40 idiomas, como de inglés a portugués, inglés a español e inglés a italiano, o viceversa.",
    start_your_trial:"¡Comienza tu prueba gratuita!",
    hero_img:"./03_English.webp",

    step_one:"Convierte tu audio a texto",
    step_one_img:"./translate_step1_en.webp",
    step_one_desc:"Convierte tu vídeo a texto usando nuestro motor de reconocimiento de voz con IA. Asegúrate de elegir el idioma que coincide con el vídeo al subirlo.",
    
    step_two:"Selecciona el idioma de traducción",
    step_two_img:"./translate_step2_en.webp",
    step_two_desc:"Editar tu transcripción original antes de traducirla puede mejorar la precisión. Nuestro sistema soporta varios pares de idiomas como inglés a vietnamita, alemán a inglés y árabe a inglés. Haz clic en el botón en la parte superior derecha para traducir a otro idioma.",

    step_three:"Edita, refina y exporta",
    step_three_img:"./translate_step3_en.webp",
    step_three_desc:"Después de la traducción del vídeo, se verá una vista de comparación lado a lado para ayudarte a hacer ajustes. ¡Una vez completado, estarás listo para exportar tu traducción!",

    feature_tutorial_title:'Resuelve desafíos de traducción de vídeo y audio',
    feature_tutorial_desc:"<p>El traductor de vídeos y el convertidor de audio a texto de Taption están diseñados para abordar problemas comunes en los procesos tradicionales de traducción de subtítulos. Nuestra tecnología impulsada por IA supera estos desafíos importantes:</p><ul><li><strong>Sincronización de tiempo:</strong> Ajusta con precisión para garantizar que los subtítulos estén perfectamente sincronizados con el tiempo del vídeo y audio.</li><li><strong>Flujo de contenido:</strong> Mejora la coherencia de los subtítulos para ofrecer una experiencia de visualización fluida.</li><li><strong>Precisión:</strong> Previene discrepancias que las traducciones literales podrían causar, garantizando la entrega precisa del mensaje en todos los idiomas.</li></ul><p>Soportando más de 40 idiomas, incluidos inglés a mandarín, inglés a japonés, inglés a coreano o inglés a tailandés, Taption te permite crear y exportar subtítulos bilingües con facilidad. Nuestra plataforma en línea elimina la necesidad de descargar software, facilitando el uso de esta función. Ya seas un creador de contenido en YouTube, un educador transcribiendo conferencias guardadas en Google Drive, o estés organizando conferencias internacionales en Zoom o Webex, el software de traducción de vídeos de Taption se integra perfectamente. Soporta la integración directa con plataformas como YouTube, Google Drive, Zoom y Webex, facilitando la traducción y gestión de tu contenido de vídeo en varios idiomas y formatos.</p><p>Para subtítulos bilingües, consulta este tutorial <a class='link' href='https://www.taption.com/add-subtitles'>sobre subtítulos bilingües</a>. Mira el siguiente tutorial sobre nuestro servicio de transcripción y traducción automatizada:</p>",
   
    feature_tutorial_poster_url:'./translate_tutorial_poster.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/GyPnMIYkvAc?autoplay=1',

    feature_extra_title:"Preguntas frecuentes sobre traducción de vídeos",
    feature_extra_desc:"<ul><li><strong>¿Qué tipos de formatos de vídeo y audio se pueden traducir?</strong><p>Taption soporta una variedad de formatos de vídeo y audio, incluyendo MOV, AVI, WMV, FLV, MPEG, OGG, MP4, WAV, MP3 y M4A. Esta versatilidad permite a los usuarios manejar una amplia gama de tipos de archivos comunes para satisfacer diferentes necesidades de transcripción y traducción.</p></li><li><strong>¿Cuánto tiempo toma la traducción de vídeo o audio?</strong><p>Nuestro traductor de vídeos impulsado por IA y software de reconocimiento de voz a texto trabajan rápidamente, completando la transcripción y traducción típicamente en 1 minuto. El tiempo específico puede variar según la longitud y complejidad del archivo de vídeo o audio.</p></li><li><strong>¿Cuál es el estándar de precios para la traducción de vídeos y audio?</strong><p>Para los servicios de traducción de vídeo y audio de Taption, incluyendo conversiones de YouTube a texto, usamos un método de facturación único. Deducimos la mitad del número de minutos de la duración total del archivo de vídeo o audio. Por ejemplo, si un vídeo dura 20 minutos, se deducirán 10 minutos de la cuenta del usuario. Este método de precios está diseñado para ofrecer servicios de transcripción y traducción rentables a los usuarios.</p></li></ul>"
}
