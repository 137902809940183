module.exports = {
    title_0: 'Bagaimana cara mentranskripsikan video saya?',
    desc_0:'Lihat video tutorial',
    video_0:"https://www.youtube.com/embed/FZOHfAFIqog",
    
    title_1: 'Bagaimana cara menambahkan subtitle ke video saya?',
    desc_1:'Lihat video tutorial',
    video_1:"https://www.youtube.com/embed/WEFq3Mcl8dk",
    
    title_2: 'Bagaimana cara menerjemahkan video saya?',
    desc_2:'Lihat video tutorial',
    video_2:"https://www.youtube.com/embed/mU4uadAK_w0",
    
    title_3: 'Bagaimana cara meningkatkan akurasi dengan menggunakan Kamus AI?',
    desc_3:'Lihat video tutorial',
    video_3:"https://www.youtube.com/embed/7gEBb_moIuI",

    title_4: 'Bagaimana cara mengedit video saya dengan mengedit teks?',
    desc_4:'Lihat video tutorial',
    video_4:"https://www.youtube.com/embed/W2yJEU1vMrg",
    
    title_5: 'Di mana saya dapat melihat tanda terima dan faktur saya?',
    desc_5:'Setelah berlangganan, Anda dapat melihat dan mengunduhnya di <span style="color:rgb(0, 209, 178);">Akun → Faktur → Lihat Semua</span>',
    video_5:"",

    title_6: 'Saya telah berlangganan paket, apa yang harus saya lakukan jika saya membutuhkan lebih dari menit yang diberikan setiap bulan?',
    desc_6:'Saat berlangganan <span style="color:rgb(0, 209, 178);">Langganan Premium</span>, Anda dapat membeli menit tambahan dengan harga diskon sebesar <span style="color:rgb(0, 209, 178);">$6/jam</span>. Saat berlangganan <span style="color:rgb(0, 209, 178);">Langganan Korporat</span>, Anda dapat membeli menit tambahan dengan harga diskon sebesar <span style="color:rgb(0, 209, 178);">$3/jam</span>',
    video_6:"",

    title_7: 'Transkrip tidak diterjemahkan, bagaimana cara mengatasinya?',
    desc_7:'Pastikan Anda telah memilih bahasa yang benar saat mengunggah video. Anda harus memilih bahasa yang sesuai dengan bahasa yang diucapkan dalam video.',
    video_7:"",

    title_8: 'Saya mengunggah file kurang dari 5GB, mengapa saya mendapatkan pesan <span style="color:rgb(230, 118, 117);">Ukuran video terlalu besar</span>?',
    desc_8:'Batas ukuran 5GB didasarkan pada format MP4. Saat mengunggah video dengan format selain MP4, MP4 yang dikonversi dapat menghasilkan ukuran lebih dari 5GB. Harap unggah video dalam format MP4 yang kurang dari 5GB untuk menghindari pesan ini.',
    video_8:"",

    title_9: 'Apa arti pesan kesalahan dan bagaimana cara mengatasinya?',
    desc_9:'<span style="color:rgb(230, 118, 117);">Melebihi penggunaan uji coba</span>: Harap beli menit atau berlangganan untuk melanjutkan penggunaan layanan.<br><span style="color:rgb(230, 118, 117);">Tidak cukup menit</span>: Beli lebih banyak menit atau berlangganan, lalu coba lagi.<br><span style="color:rgb(230, 118, 117);">Tautan YouTube gagal, harap coba lagi</span>: Alasan yang paling umum adalah bahwa YouTube yang disediakan baru saja diunggah, harap tunggu setidaknya 24 jam sebelum mencoba transkripsi lagi.',
    video_9:"",

    title_10: 'Bagaimana cara mengintegrasikan Taption dengan Zoom?',
    desc_10:'Silakan lihat <a href="https://www.taption.com/blog/en/zoom-tutorial-en" rel="noreferrer" target="_blank">tutorial langkah demi langkah</a>',
    video_10:"",

    title_11: 'Saya memiliki pertanyaan lebih lanjut, apa yang harus saya lakukan?',
    desc_11:'Silakan hubungi kami di <span style="color:rgb(0, 209, 178);">help@taption.com</span>',
    video_11:"",

    zoom_title:'Integrasi Taption + Zoom Langkah demi Langkah',
    zoom_install_desc:'Anda sekarang dapat menghubungkan akun Zoom Anda dengan akun Taption Anda. Setelah tutorial ini, Anda akan dapat mentranskripsi rekaman Zoom langsung dari Taption.',
    zoom_step_1:'Klik "Unggah" setelah masuk ke akun Anda',
    zoom_step_2:'Pilih "Zoom" di dialog popup',
    zoom_step_3:'Masuk dengan akun Zoom Anda dan berikan otorisasi',
    zoom_step_4:'Pilih rekaman Anda dari tabel',
    zoom_step_5:'Konfigurasikan pengaturan Anda, lalu Anda siap untuk mengunggah dan mentranskripsi',
    zoom_uninstall_title:'Copot integrasi Taption + Zoom',
    zoom_uninstall_desc:'Untuk mencopot Zoom dari Taption, Anda dapat melakukannya dengan masuk ke <strong>Akun</strong> → <strong>Aplikasi</strong> → <strong>Copot</strong><br>Jika Anda memiliki pertanyaan lebih lanjut, silakan email kami di <span style="color:rgb(0, 209, 178);">help@taption.com</span>',

    webex_title:'Integrasi Taption + Webex Langkah demi Langkah',
    webex_install_desc:'Anda sekarang dapat menghubungkan akun Webex Anda dengan akun Taption Anda. Setelah tutorial ini, Anda akan dapat mentranskripsi rekaman Webex langsung dari Taption.',
    webex_step_1:'Klik "Unggah" setelah masuk ke akun Anda',
    webex_step_2:'Pilih "Webex" di dialog popup',
    webex_step_3:'Masuk dengan akun Webex Anda dan berikan otorisasi',
    webex_step_4:'Pilih rekaman Anda dari tabel',
    webex_step_5:'Konfigurasikan pengaturan Anda, lalu Anda siap untuk mengunggah dan mentranskripsi',
    webex_uninstall_title:'Copot integrasi Taption + Webex',
    webex_uninstall_desc:'Untuk mencopot Webex dari Taption, Anda dapat melakukannya dengan masuk ke <strong>Akun</strong> → <strong>Aplikasi</strong> → <strong>Copot</strong><br>Jika Anda memiliki pertanyaan lebih lanjut, silakan email kami di <span style="color:rgb(0, 209, 178);">help@taption.com</span>',
}
