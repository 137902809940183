module.exports = {
    convert_to_text_title:"Transkrip ke Pembicara",
    convert_to_text_desc:"Secara otomatis tambahkan label pembicara untuk transkrip yang ada",
    start_your_trial:"Coba sekarang!",
    hero_img:"./10_English.webp",

    step_one:"Unggah file media Anda",
    step_one_img:"./select_source_en.webp",
    step_one_desc:"Pilih file audio atau video yang sesuai dengan transkrip yang Anda miliki.",
    
    step_two:"Unggah file transkrip Anda",
    step_two_img:"./transcripts_subtitles_step02_en.webp",
    step_two_desc:"Pilih <span style=\"color:#3ccebd\"><strong>Impor file teks</strong></span> untuk <strong>Metode Transkripsi</strong> dan <span style=\"color:#3ccebd\"><strong>Penandaan pembicara</strong></span> untuk <strong>Pilih format output Anda</strong>",

    step_three:"Edit dan ekspor",
    step_three_img:"./export_srt_step03_en.webp",
    step_three_desc:"Setelah selesai, masuk ke platform pengeditan untuk memperbarui nama pembicara atau menerjemahkan. Ekspor ketika siap!",

    feature_tutorial_title:'Penandaan pembicara otomatis untuk kejelasan yang lebih baik',
    feature_tutorial_desc:'Hari-hari kebingungan tentang siapa yang mengatakan apa dalam konten audio Anda telah berakhir. Dengan Taption, mengidentifikasi suara dalam podcast, wawancara, atau diskusi grup menjadi lebih mudah – semua berkat perangkat lunak pengenalan pembicara kami. Cukup unggah transkrip Anda dan biarkan teknologi deteksi pembicara AI kami melakukan pekerjaannya. <br><br>Apa sebenarnya yang dilakukan teknologi kami? Ini mengidentifikasi dan memberi label kontribusi setiap pembicara dengan mudah. Dengan menggunakan identifikasi pembicara otomatis kami, Anda menjamin kejelasan yang sempurna dalam semua video Anda sehingga audiens Anda tidak pernah bingung tentang siapa yang berbicara atau kesulitan mengikuti percakapan.',
    feature_tutorial_poster_url:'./transcripts_to_subtitles_thumbnail_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/3DNDiW8jiDU?si=ZWqZnGBTdjUmVU0_&autoplay=1',

    feature_extra_title:"Keuntungan dari penandaan pembicara yang jelas",
    feature_extra_desc:"Dalam diskusi dengan banyak suara, mengetahui siapa yang berbicara adalah kunci - itu membentuk pengalaman pendengar. Dengan Taption, Anda mendapatkan:<br><ul><li><strong>Dialog yang Transparan:</strong> Pendengar dapat dengan mudah mengikuti percakapan karena setiap suara diberi tanda yang jelas.</li><li><strong>Transkrip yang Lebih Baik:</strong> Konten Anda memberikan kesan yang lebih profesional yang meningkatkan citra perusahaan Anda.</li><li><strong>Hemat Waktu:</strong> Bayangkan jam-jam yang dapat Anda hemat dengan alat penandaan audio yang dapat mengidentifikasi pembicara dengan benar pada percobaan pertama.</li></ul><br>Rasakan manfaat identifikasi pembicara profesional dengan alat canggih Taption. Berkat penandaan transkrip multi-pembicara dan solusi atribusi suara kami, Anda dapat memastikan bahwa layanan transkripsi rapat dan penandaan transkrip wawancara Anda memenuhi standar akurasi dan detail tertinggi."
}
