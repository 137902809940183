module.exports = {
  'en-US': 'Inglés (Estados Unidos)',
  'en-GB': 'Inglés (Reino Unido)',
  'en-AU': 'Inglés (Australia)',
  'zh-TW': 'Chino (Taiwán)',
  'zh': 'Chino (China)',
  'yue-Hant-HK-TW-zh': 'Cantonés (Salida en chino tradicional)',
  'yue-Hant-HK-zh': 'Cantonés (Salida en chino simplificado)',
  'yue-Hant-HK-TW': 'Cantonés (Salida en cantonés tradicional)',
  'yue-Hant-HK': 'Cantonés (Salida en cantonés simplificado)',
  'es-ES': 'Español (España)',
  'fr-FR': 'Francés (Francia)',
  'de-DE': 'Alemán (Alemania)',
  'it-IT': 'Italiano (Italia)',
  'pt-PT_pt-BR': 'Portugués (Portugal, Brasil)',
  'nl-BE': 'Holandés (Bélgica)',
  'hi-IN': 'Hindi (India)',
  'ja-JP': 'Japonés (Japón)',
  'ko-KR': 'Coreano (Corea del Sur)',
  'vi-VN': 'Vietnamita (Vietnam)',

  'ar-EG': 'Árabe (Egipto)',
  'hy-AM': 'Armenio (Armenia)',
  'bs-BA': 'Bosnio (Bosnia y Herzegovina)',
  'bg-BG': 'Búlgaro (Bulgaria)',
  'my-MM': 'Birmano (Myanmar)',
  'ca-ES': 'Catalán (España)',
  'hr-HR': 'Croata (Croacia)',
  'cs-CZ': 'Checo (República Checa)',
  'da-DK': 'Danés (Dinamarca)',
  'tl-PH': 'Filipino (Filipinas)',
  'fi-FI': 'Finlandés (Finlandia)',
  'gl-ES': 'Gallego (España)',
  'el-GR': 'Griego (Grecia)',
  'gu-IN': 'Guyaratí (India)',
  'iw-IL': 'Hebreo (Israel)',
  'hu-HU': 'Húngaro (Hungría)',
  'id-ID': 'Indonesio (Indonesia)',
  'lv-LV': 'Letón (Letonia)',
  'lt-LT': 'Lituano (Lituania)',
  'mk-MK': 'Macedonio (Macedonia del Norte)',
  'ms-MY': 'Malayo (Malasia)',
  'no-NO': 'Noruego (Noruega)',
  'pl-PL': 'Polaco (Polonia)',
  'ro-RO': 'Rumano (Rumanía)',
  'ru-RU': 'Ruso (Rusia)',
  'sk-SK': 'Eslovaco (Eslovaquia)',
  'sl-SI': 'Esloveno (Eslovenia)',
  'sv-SE': 'Sueco (Suecia)',
  'ta-IN': 'Tamil (India)',
  'te-IN': 'Telugu (India)',
  'th-TH': 'Tailandés (Tailandia)',
  'tr-TR': 'Turco (Turquía)',
  'uk-UA': 'Ucraniano (Ucrania)',
  'uz-UZ': 'Uzbeko (Uzbekistán)'
}
