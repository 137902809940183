module.exports = {
  'en-US': 'Inglês (Estados Unidos)',
  'en-GB': 'Inglês (Reino Unido)',
  'en-AU': 'Inglês (Austrália)',
  'zh-TW': 'Chinês (Taiwan)',
  'zh': 'Chinês (China)',
  'yue-Hant-HK-TW-zh': 'Cantonês (Chinês Tradicional)',
  'yue-Hant-HK-zh': 'Cantonês (Chinês Simplificado)',
  'yue-Hant-HK-TW': 'Cantonês (Cantonês Tradicional)',
  'yue-Hant-HK': 'Cantonês (Cantonês Simplificado)',
  'es-ES': 'Espanhol (Espanha)',
  'fr-FR': 'Francês (França)',
  'de-DE': 'Alemão (Alemanha)',
  'it-IT': 'Italiano (Itália)',
  'pt-PT_pt-BR': 'Português (Portugal, Brasil)',
  'nl-BE': 'Holandês (Bélgica)',
  'hi-IN': 'Hindi (Índia)',
  'ja-JP': 'Japonês (Japão)',
  'ko-KR': 'Coreano (Coreia do Sul)',
  'vi-VN': 'Vietnamita (Vietname)',
  'ar-EG': 'Árabe (Egito)',
  'hy-AM': 'Arménio (Arménia)',
  'bs-BA': 'Bósnio (Bósnia)',
  'bg-BG': 'Búlgaro (Bulgária)',
  'my-MM': 'Birmanês (Mianmar)',
  'ca-ES': 'Catalão (Espanha)',
  'hr-HR': 'Croata (Croácia)',
  'cs-CZ': 'Checo (República Checa)',
  'da-DK': 'Dinamarquês (Dinamarca)',
  'tl-PH': 'Filipino (Filipinas)',
  'fi-FI': 'Finlandês (Finlândia)',
  'gl-ES': 'Galego (Espanha)',
  'el-GR': 'Grego (Grécia)',
  'gu-IN': 'Gujarati (Índia)',
  'iw-IL': 'Hebraico (Israel)',
  'hu-HU': 'Húngaro (Hungria)',
  'id-ID': 'Indonésio (Indonésia)',
  'lv-LV': 'Letão (Letónia)',
  'lt-LT': 'Lituano (Lituânia)',
  'mk-MK': 'Macedónio (Macedónia do Norte)',
  'ms-MY': 'Malaio (Malásia)',
  'no-NO': 'Norueguês (Noruega)',
  'pl-PL': 'Polaco (Polónia)',
  'ro-RO': 'Romeno (Roménia)',
  'ru-RU': 'Russo (Rússia)',
  'sk-SK': 'Eslovaco (Eslováquia)',
  'sl-SI': 'Esloveno (Eslovénia)',
  'sv-SE': 'Sueco (Suécia)',
  'ta-IN': 'Tâmil (Índia)',
  'te-IN': 'Telugu (Índia)',
  'th-TH': 'Tailandês (Tailândia)',
  'tr-TR': 'Turco (Turquia)',
  'uk-UA': 'Ucraniano (Ucrânia)',
  'uz-UZ': 'Uzbeque (Uzbequistão)'
}
