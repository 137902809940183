module.exports = {
  'en': 'Inggris',
  'zh-TW': 'Tionghoa (Tradisional)',
  'zh': 'Tionghoa (Sederhana)',
  'yue-Hant-HK': 'Tionghoa (Kanton)',
  'yue-Hant-HK-TW': 'Tionghoa (Kanton)',
  'ja': 'Jepang',
  'ko': 'Korea',
  'es': 'Spanyol',
  'de': 'Jerman',
  'fr': 'Prancis',
  'af': 'Afrikaans',
  'sq': 'Albania',
  'am': 'Amharik',
  'ar': 'Arab',
  'hy': 'Armenia',
  'az': 'Azerbaijan',
  'eu': 'Basque',
  'be': 'Belarusia',
  'bn': 'Bengali',
  'bs': 'Bosnia',
  'bg': 'Bulgaria',
  'ca': 'Katalan',
  'co': 'Korsika',
  'hr': 'Kroasia',
  'cs': 'Ceko',
  'da': 'Denmark',
  'nl': 'Belanda',
  'fi': 'Finlandia',
  'el': 'Yunani',
  'gu': 'Gujarati',
  'he': 'Ibrani',
  'hi': 'Hindi',
  'hu': 'Hungaria',
  'id': 'Indonesia',
  'ga': 'Irlandia',
  'it': 'Italia',
  'la': 'Latin',
  'lv': 'Latvia',
  'lt': 'Lituania',
  'ms': 'Melayu',
  'no': 'Norwegia',
  'pl': 'Polandia',
  'pt': 'Portugis (Portugal, Brasil)',
  'ro': 'Rumania',
  'ru': 'Rusia',
  'sr': 'Serbia',
  'sk': 'Slovakia',
  'sl': 'Slovenia',
  'sv': 'Swedia',
  'te': 'Telugu',
  'th': 'Thai',
  'tr': 'Turki',
  'uk': 'Ukraina',
  'uz': 'Uzbek',
  'vi': 'Vietnam',
  'yi': 'Yiddish',
  'zu': 'Zulu'
}
